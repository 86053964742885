// @flow
import React from "react"
import { Link } from "react-router-dom"

import styles from "./styles.module.css"
import logo from "assets/Images/logo-full.svg"

const Logo = () => (
  <Link to={"/"}>
    <div className={styles.logoContainer}>
      <img alt="Logo" src={logo} />
    </div>
  </Link>
)

export default Logo

import React from "react"
import ImagesPreview from "components/ImagesUpload/ImagesPreview"
import ImagesUpload from "../ImagesUpload/ImagesUpload"

import Compress from "react-image-file-resizer"
import styles from "./styles.module.css"
import { maxSize1MB, maxImages10 } from "wrappers/FormValidation/validations"

const ImagesUploadField = (field) => {
  let files = field.input.value
  files = files.map ? files : [files]

  const validation = maxSize1MB(files) || maxImages10(files)

  const resizeFile = (file) => {
    if (file.preview) {
      const compressed = new Promise((resolve) => {
        Compress.imageFileResizer(
          file,
          550,
          550,
          "webp",
          90,
          0,
          (uri) => {
            uri.preview = file.preview
            resolve(uri)
          },
          "file",
          200,
          200
        )
      })
      return compressed
    } else {
      return file
    }
  }

  const onImageChange = async (e) => {
    const files = await Promise.all(
      e.map(async (file) => {
        const newFile = await resizeFile(file)
        return newFile
      })
    )
    field.input.onChange(files)
  }

  return (
    <div className={styles.dropzone}>
      <label>Upload images</label>
      <p className="small-note">Upload up to 10 images</p>
      <ImagesUpload name={field.name} files={files} onChange={onImageChange} disabled={!!validation} />
      {validation && <div className={styles.errorText}>{validation}</div>}

      <ImagesPreview files={files} onChange={onImageChange} />
    </div>
  )
}

export default ImagesUploadField

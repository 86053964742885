// @flow
import React from "react"

import styles from "./styles.module.css"

const Button = ({
  children,
  name,
  fluid,
  onClick,
  style,
  disabled,
}: {
  children: any,
  name?: string,
  fluid: Boolean,
  onClick: Function,
  style: any,
  disabled?: Boolean,
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    name={name}
    className={styles.element}
    style={{
      cursor: disabled ? "not-allowed" : "pointer",
      opacity: disabled ? 0.5 : 1,
      width: fluid ? "100%" : undefined,
      ...style,
    }}
  >
    {children}
  </button>
)

export default Button

import React from "react"
import { useSelector, useDispatch } from "react-redux"

import Loading from "components/Loading"
import TableSection from "components/TableSection/"
import rowsCreator from "utils/rowsCreator"
import Filters from "./Filters"
import segmentToRow from "./SegmentToRow"
import { DEAL_MODAL } from "Constants"
import { SUPERADMIN } from "instances/User/constants"

import { openModal } from "data/Modals/actions"
import { updateBooking } from "data/Overview/actions"
import { deleteDealAndReload, selectClub, selectCategory, selectDeal } from "data/Deals/actions"
import { setActiveUpdatingForm, unsetActiveUpdatingForm } from "data/Forms/actions"
import { deleteBookingAndReloadDeals } from "data/BookedDeals/actions"

import styles from "./styles.module.css"

import { Icon } from "semantic-ui-react"

export const headers = (isSuperAdmin = false) => {
  const all = [
    { component: <span>Booking Id</span> },
    { component: <span>Name</span> },
    { component: <span>Date</span> },
    { component: <span>Min. spend</span> },
    { component: <span>Qty</span> },
    { component: <span>Contact</span> },
    { component: <span>Confirmed</span> },
    { component: <span>Transaction status</span> },
    {
      component: <Icon size="large" name="attach" />,
    },
    { component: <span /> },
  ]

  if (!isSuperAdmin) {
    // for clubadmin remove transaction_status - index 7 & contact - index 5
    all.splice(7, 1)
    all.splice(5, 1)
  }

  return all
}

function pairClubsWithDeals(clubs, deals) {
  if (!clubs) return deals
  if (!deals) return null

  const preparedData = []
  Object.keys(clubs).forEach((clubKey) => {
    const club = clubs[clubKey]
    const filteredEvents = []

    Object.keys(deals).forEach((eventKey) => {
      const event = deals[eventKey]
      if (event.club_id === clubKey) filteredEvents.push(event)
    })

    if (filteredEvents.length)
      preparedData.push({
        name: club.name,
        events: filteredEvents,
      })
  })
  return preparedData
}

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByName = groupBy("name")

const BookedDeals = ({ tables }) => {
  const dispatch = useDispatch()

  const filters = useSelector((state) => state.filters)

  const handleSelectDeal = (id) => {
    dispatch(selectDeal(id))
  }
  const handleSelectClub = (id) => {
    dispatch(selectClub(id))
  }
  const handleSelectCategory = (id) => {
    dispatch(selectCategory(id))
  }
  const handleSetActiveUpdatingForm = (id) => {
    dispatch(setActiveUpdatingForm(id))
  }

  const handleOpenModal = () => {
    dispatch(openModal(DEAL_MODAL))
  }

  const deleteDeal = (id, filters) => {
    dispatch(deleteDealAndReload(id, filters))
  }
  const modifyEventsWithOverridenTablePrices = ({ id, value }) => {}

  const readLoading = useSelector((state) => state.bookedDeals.readLoading)
  const bookedDeals = useSelector((state) => state.bookedDeals.segments)

  const user = useSelector((state) => state.user)

  const clubsSegments = useSelector((state) => state.clubs.segments || [])
  const clubsReadSuccess = useSelector((state) => state.clubs.readSuccess)
  const dealsSegments = useSelector((state) => state.deals.segments)

  // const dealsForRender = pairClubsWithDeals(clubsSegments, dealsSegments)

  const { role } = user
  const isSuperAdmin = role === SUPERADMIN

  const filtered = Object.values(bookedDeals).filter((d) => d.deals.booking)

  const groupedBooking = groupByName(filtered)

  const handleUpdateBooking = (params) => {
    dispatch(updateBooking({ params }))
  }
  const deleteBooking = (id) => {
    dispatch(deleteBookingAndReloadDeals(id, filters))
  }

  return (
    <div className={styles.masterContainer}>
      <Filters />
      <Loading isLoading={readLoading}>
        {Object.keys(groupedBooking).map((key, c) => {
          const bookings = groupedBooking[key]
          return (
            <TableSection
              key={`booking-${key}`}
              headingComponent={key}
              headers={headers(isSuperAdmin)}
              rows={rowsCreator(segmentToRow, bookings, {
                updateBooking: handleUpdateBooking,
                deleteBooking,
                role,
              })}
            />
          )
        })}
      </Loading>
    </div>
  )
}

export default BookedDeals

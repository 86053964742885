// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"

import type { BookingType } from "./types"
import type { FilterType } from "data/Filters/types"
import { uploadFiles, deleteFiles } from "utils/FileUpload"

import { deleteBooking } from "../Overview/actions"

type DataType = {
  params: BookingType,
  form: string,
  modalId: string,
  formId: string,
}

export function fetchDealsBookings(params: any) {
  return {
    [CALL_API]: {
      types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
      promise: (httpClient) => httpClient.get("/bookings/deals", params),
    },
  }
}

const isSuccessType = (action) => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const deleteBookingAndReloadDeals = (id: string, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteBooking(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchDealsBookings({ params: filters }))
    }

    return finalCreateType
  }
}

// @flow
import * as React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setUser } from "instances/User/actions"
import type { UserType } from "instances/User/types"
import authService from "instances/AuthService"

type PropTypes = {
  setUser: Function,
  user: UserType,
}
type StateTypes = {
  allowRender: boolean,
}

const withSession = (Component: React.ComponentType<any>) => {
  class Session extends React.Component<PropTypes, StateTypes> {
    state = { allowRender: false }
    async componentDidMount() {
      try {
        if (
          authService.getToken("refresh_token") &&
          !this.props.user.isLoggedIn
        ) {
          await authService.refreshTokens()
          await authService.fetchUser()
          return this.setState({ allowRender: true })
        } else {
          return this.setState({ allowRender: true })
        }
      } catch (e) {
        authService.logout()
        return this.setState({ allowRender: true })
      }
    }

    render() {
      return this.state.allowRender ? <Component {...this.props} /> : null
    }
  }

  const mstp = ({ user }) => ({ user })

  const mdtp = dispatch => ({
    setUser: bindActionCreators(setUser, dispatch),
  })

  return connect(
    mstp,
    mdtp
  )(Session)
}

export default withSession

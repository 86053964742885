import React from "react"
import moment from "moment"

import TableOptions from "components/TableOptions"

export const segmentToRow = (
  { _id, name, birthdate, phone_number, email, createdAt, updatedAt },
  { deleteCustomer }
) => ({
  columns: [
    {
      component: (
        <span>
          <strong>{name}</strong>
        </span>
      ),
    },
    {
      component: (
        <span>
          {(birthdate && moment(birthdate).format("DD MMM, YYYY")) || "-"}
        </span>
      ),
    },
    { component: <span>{phone_number || "-"}</span> },
    { component: <span>{email}</span> },
    { component: <span>{createdAt || "-"}</span> },
    { component: <span>{updatedAt || "-"}</span> },
    {
      component: (
        <TableOptions
          deleteOptionText="Delete customer"
          onDeleteOptionClick={() => {
            deleteCustomer(_id)
          }}
        />
      ),
    },
  ],
})

// @flow
import AuthorizationForm from "components/AuthorizationForm"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { formValueSelector } from "redux-form"

import type { UserType } from "instances/User/types"
import { instance as axios } from "instances/Axios"
import { _oldPushNotification } from "wrappers/Notifications/actions"
import type { FieldObjectType } from "components/AuthorizationForm"
import authService from "instances/AuthService"
import { AUTHORIZATION_FORM } from "Constants"

// import styles from "./styles.module.css"

type PropType = {
  user: UserType,
  setFakeUser: Function,
  register: {
    email: string,
    password: string,
    name: string,
  },
  location: { pathname: string },
  pushNotification: Function,
}

type StateType = {
  fields: Array<FieldObjectType>,
  token: ?string,
  email: ?string,
}

class Register extends React.Component<PropType, StateType> {
  state = {
    token: null,
    email: null,
  }

  async componentDidMount() {
    const split = this.props.location.pathname.split("/")
    const token = split.length === 4 ? split[split.length - 1] : null
    this.setState({ token })
    if (!token)
      return this.props.pushNotification({ content: "No token provided" })
    try {
      const {
        data: { data },
      } = await axios.get("/admins/email", {
        params: { token },
      })
      this.setState({ email: data.email })
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        return this.props.pushNotification({
          content: e.response.data.errors.join(", "),
        })
      }
      throw e
    }
  }

  handleSubmit = (e: SyntheticEvent<*>) => {
    const { name, password } = this.props.register
    const { email, token } = this.state
    this.register(name, password, email, token)
  }

  async register(name, password, email, token) {
    try {
      await axios.post("/admins/register/verify", {
        name,
        email,
        password,
        token,
      })
      this.props.pushNotification({ content: "User created" })
      authService.login({ email, password })
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        return this.props.pushNotification({
          content: e.response.data.errors.join(", "),
        })
      }
      throw e
    }
  }

  render() {
    const initialValues = {
      email: this.state.email,
    }

    return (
      <AuthorizationForm
        title="Sign up below"
        onSubmit={this.handleSubmit}
        submitButtonText="Register"
        initialValues={initialValues}
        disableEmail
        confirmPassword
        showName
        password
      />
    )
  }
}

const mstp = state => {
  return {
    user: state.user,
    register: formValueSelector(AUTHORIZATION_FORM)(
      state,
      "name",
      "email",
      "password"
    ),
  }
}

const mdtp = dispatch => ({
  pushNotification: bindActionCreators(_oldPushNotification, dispatch),
})

export default connect(
  mstp,
  mdtp
)(Register)

// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { formValueSelector } from "redux-form"

import type { UserType } from "instances/User/types"
import AuthorizationForm from "components/AuthorizationForm"
import { _oldPushNotification } from "wrappers/Notifications/actions"
import styles from "./styles.module.css"
import authService from "instances/AuthService"
import { AUTHORIZATION_FORM } from "Constants"

type PropType = {
  user: UserType,
  login: {
    email: string,
    password: string,
  },
  pushNotification: Function,
}
class Login extends React.Component<PropType> {
  handleSubmit = (e: SyntheticEvent<*>) => {
    const { email, password } = this.props.login
    this.login(email, password)
  }

  async login(email, password) {
    try {
      await authService.login({
        email,
        password,
      })
      this.props.pushNotification({ content: "Logged in" })
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        return this.props.pushNotification({
          content: e.response.data.errors.join(", "),
        })
      }
      throw e
    }
  }

  render() {
    return (
      <AuthorizationForm
        password
        title="Sign in below"
        onSubmit={this.handleSubmit}
        submitButtonText="Login"
        underneathComponent={
          <div className={styles.underneathContainer}>
            <p>
              <a className="link" href="/forgot-password">
                Forgot password?
              </a>
            </p>
          </div>
        }
      />
    )
  }
}

const mstp = state => {
  return {
    user: state.user,
    login: formValueSelector(AUTHORIZATION_FORM)(state, "email", "password"),
  }
}

const mdtp = dispatch => ({
  pushNotification: bindActionCreators(_oldPushNotification, dispatch),
})

export default connect(
  mstp,
  mdtp
)(Login)

// @flow
import React from "react"
import { Grid, Statistic, Divider } from "semantic-ui-react"
import { ReservationFormHeaderItem } from "./ReservationFormHeaderItem"

type PropType = { tableName: string, minSpend: string, placement: string }

export const ReservationFormHeader = ({
  tableName,
  minSpend,
  placement,
}: PropType) => (
  <React.Fragment>
    <Grid columns="equal" stackable>
      <Grid.Row>
        <Grid.Column>
          <Statistic.Group size="mini" className="header-item">
            <ReservationFormHeaderItem label="Table" value={tableName} />
            <ReservationFormHeaderItem
              label="Minimum spend"
              value={`$${minSpend}`}
            />
            <ReservationFormHeaderItem label="Location" value={placement} />
          </Statistic.Group>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
    </Grid>
  </React.Fragment>
)

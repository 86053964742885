// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"

import type { BookingType } from "./types"
import type { FilterType } from "data/Filters/types"
import { uploadFiles, deleteFiles } from "utils/FileUpload"

type DataType = {
  params: BookingType,
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export function fetchBookings(
  params: ?{ date_from: ?string, date_to: ?string }
) {
  return {
    [CALL_API]: {
      types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
      promise: httpClient => httpClient.get("/bookings/overview", params),
    },
  }
}

export const createBooking = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
    promise: httpClient => httpClient.post("/bookings", params),
    successMessage: "Successfully created new reservation",
    errorMessage: "Failed to create reservation",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export const createBookingAndReload = (data: DataType, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createBooking(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchBookings({ params: filters }))
    }

    return finalCreateType
  }
}

export const updateBooking = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: httpClient =>
      httpClient.put("/bookings", {
        ...params,
        booking_id: params._id,
      }),
    successMessage: "Reservation is successfully updated",
    errorMessage: "Failed to update reservation",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export const updateBookingAndReload = (data: DataType, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(updateBooking(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchBookings({ params: filters }))
    }

    return finalCreateType
  }
}

export const deleteBooking = (id: string) => {
  return {
    [CALL_API]: {
      types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
      promise: httpClient =>
        httpClient.delete("/bookings", { params: { id: id } }),
      successMessage: "Reservation is successfully deleted",
      errorMessage: "Failed to delete reservation",
    },
  }
}

// Operation
export const deleteBookingAndReload = (id: string, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteBooking(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchBookings({ params: filters }))
    }

    return finalCreateType
  }
}

export const uploadAttachment = (id: string, files: Array) =>
  uploadFiles({ id, files, type: "booking" })

export const removeAttachment = (attachment: { _id: string }) =>
  deleteFiles({ type: "booking", ...attachment })

import { CATEGORIES } from "./constants"

import {
  commonReducerState,
  commonReducerActionHandlers,
} from "utils/commonReducerUtils"

const CategoriesReducer = (
  state = {
    ...commonReducerState,
  },
  { type, payload }
) =>
  commonReducerActionHandlers(
    {
      state,
      type,
      payload,
    },
    CATEGORIES,
    () => {
      return state
    }
  )

export default CategoriesReducer

import { SET, UNSET } from "./constants"

const FormsReducer = (
  state = {
    activeUpdatingForm: null,
  },
  { type, payload }
) => {
  switch (type) {
    case SET:
      return (() => {
        const newState = { ...state }
        newState.activeUpdatingForm = payload
        return newState
      })()
    case UNSET:
      return (() => {
        const newState = { ...state }
        newState.activeUpdatingForm = null
        return newState
      })()
    default:
      return state
  }
}

export default FormsReducer

import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"

import Modal from "components/Modal"
import LocationForm from "views/Locations/LocationForm"
import Form from "components/Form"
import { createLocationAndReload, updateLocationAndReload } from "data/Locations/actions"
import { LOCATION_FORM, LOCATION_MODAL } from "Constants"

class LocationModal extends React.Component {
  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(LOCATION_MODAL, activeModal)
  }

  createLocation = (location) => {
    const { createLocationAndReload, activeModal } = this.props

    const locationData = {
      params: location,
      modalId: LOCATION_MODAL,
      formId: activeModal,
    }

    createLocationAndReload(locationData)
  }

  updateLocation = (location) => {
    const { updateLocationAndReload, activeModal, initialValues } = this.props

    const locationData = {
      params: location,
      modalId: LOCATION_MODAL,
      formId: activeModal,
    }

    updateLocationAndReload(locationData)
  }

  render() {
    const { initialValues } = this.props

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={LOCATION_FORM}
          header={initialValues ? "Update location" : "Create new location"}
          submitButtonText={initialValues ? "Update location" : "Create location"}
          initialValues={initialValues}
          onCancel={this.closeModal}
          onSubmit={initialValues ? this.updateLocation : this.createLocation}
          columns={4}
        >
          <LocationForm />
        </Form>
      </Modal>
    )
  }
}

const mstp = ({ locations, forms }) => {
  const { activeUpdatingForm } = forms

  return {
    activeModal: activeUpdatingForm,
    initialValues: activeUpdatingForm ? locations.segments[activeUpdatingForm] : null,
  }
}

const actions = {
  closeModalAndUnsetActiveUpdatingForm,
  createLocationAndReload,
  updateLocationAndReload,
}

export default connect(mstp, actions)(LocationModal)

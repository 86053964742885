// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import TableSection from "components/TableSection/"
import Loading from "components/Loading"
import type { UserType } from "instances/User/types"
import { openModal, closeModal } from "data/Modals/actions"
import rowsCreator from "utils/rowsCreator"
import { headers } from "./EventTableHeaders"
import segmentToRow from "./SegmentToRow"
import Filters from "./Filters"

import {
  createEvent,
  fetchEvents,
  updateEvent,
  deleteEventAndReload,
  selectClub,
  selectEvent,
  modifyEventsWithOverridenTablePrices,
} from "data/Events/actions"
import {
  setActiveUpdatingForm,
  unsetActiveUpdatingForm,
} from "data/Forms/actions"

import styles from "./styles.module.css"
// import placeholder from "assets/Images/placeholder.jpg"
import { EVENT_MODAL } from "Constants"

type PropTypes = {
  user: UserType,
  openModal: Function,
  closeModal: Function,
  fetchEvents: Function,
}
type StateTypes = {
  overrideTablePrices: boolean,
}

class Events extends React.Component<PropTypes, StateTypes> {
  render() {
    const {
      filters,
      openModal,
      deleteEventAndReload,
      setActiveUpdatingForm,
      modifyEventsWithOverridenTablePrices,
    } = this.props
    return (
      <div className={styles.masterContainer}>
        <Filters />
        <Loading isLoading={this.props.events.readLoading}>
          {this.props.eventsForRender.map((club, c) => (
            <TableSection
              key={`club-${club.name}-${c}`}
              headingComponent={club.name}
              headers={headers}
              rows={rowsCreator(segmentToRow, club.events, {
                openModal: () => openModal(EVENT_MODAL),
                clubs: this.props.clubs.segments,
                clubsLoaded: this.props.clubs.readSuccess,
                setActiveUpdatingForm: id => setActiveUpdatingForm(id),
                deleteEvent: id => deleteEventAndReload(id, filters),
                modifyEventsWithOverridenTablePrices: ({ id, value }) =>
                  modifyEventsWithOverridenTablePrices({
                    id,
                    value,
                  }),
                tables: this.props.tables.segments,
                selectClub: id => this.props.selectClub(id),
                selectEvent: id => this.props.selectEvent(id),
              })}
            />
          ))}
        </Loading>
      </div>
    )
  }
}

function pairClubsWithEvents(clubs, events) {
  if (!clubs) return events
  if (!events) return null

  const preparedData = []
  Object.keys(clubs).forEach(clubKey => {
    const club = clubs[clubKey]
    const filteredEvents = []

    Object.keys(events).forEach(eventKey => {
      const event = events[eventKey]
      if (event.club_id === clubKey) filteredEvents.push(event)
    })

    if (filteredEvents.length)
      preparedData.push({
        name: club.name,
        events: filteredEvents,
      })
  })
  return preparedData
}

const mstp = ({ events, modals, forms, clubs, tables, filters }) => {
  const eventsForRender = pairClubsWithEvents(clubs.segments, events.segments)
  return {
    eventsForRender,
    events,
    modals,
    forms,
    clubs,
    filters,
  }
}

const mdtp = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  createEvent: bindActionCreators(createEvent, dispatch),
  fetchEvents: bindActionCreators(fetchEvents, dispatch),
  deleteEventAndReload: bindActionCreators(deleteEventAndReload, dispatch),
  updateEvent: bindActionCreators(updateEvent, dispatch),
  selectEvent: bindActionCreators(selectEvent, dispatch),
  selectClub: bindActionCreators(selectClub, dispatch),
  modifyEventsWithOverridenTablePrices: bindActionCreators(
    modifyEventsWithOverridenTablePrices,
    dispatch
  ),
  setActiveUpdatingForm: bindActionCreators(setActiveUpdatingForm, dispatch),
  unsetActiveUpdatingForm: bindActionCreators(
    unsetActiveUpdatingForm,
    dispatch
  ),
})

export default connect(
  mstp,
  mdtp
)(Events)

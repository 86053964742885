// @flow
import { CALL_API } from "middleware/api"

import {
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"

type CustomerType = {
  params: {
    name: string,
    password: string,
    email: string,
  },
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const fetchCustomers = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: httpClient =>
      httpClient.get("/users/all?roles=user", {
        params: {
          fields: "name,email,birthdate,phone_number,createdAt,updated_at",
        },
      }),
    payloadModifier: payload => payload.users,
  },
})

export const deleteCustomer = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: httpClient => httpClient.post("/delete", { user_id: id }),
    successMessage: "Customer is successfully deleted",
    errorMessage: "Failed to delete customer",
  },
})

// Operation
export function deleteCustomerAndReload(id: string) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteCustomer(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchCustomers())
    }

    return finalCreateType
  }
}

export const createCustomer = ({
  params,
  form,
  modalId,
  formId,
}: CustomerType) => ({
  [CALL_API]: {
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
    promise: httpClient =>
      httpClient.post("/users/register", params, {
        headers: { Authorization: "" },
      }),
    successMessage: "Successfully created customer",
    errorMessage: "Failed to create customer",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export function createCustomerAndReload(data: CustomerType) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createCustomer(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchCustomers())
    }

    return finalCreateType
  }
}

// @flow
import * as React from "react"
import { connect } from "react-redux"

import superAdminLinks from "./superAdminLinks"
import clubAdminLinks from "./clubAdminLinks"

import type { UserType } from "instances/User/types"

type PropTypes = {
  user: UserType,
  match: {
    path: string,
  },
}

const withHeaderNavigation = (Header: React.ComponentType<*>) => {
  class HeaderNavigation extends React.Component<PropTypes> {
    links =
      this.props.user.role === "superadmin" ? superAdminLinks : clubAdminLinks
    render() {
      const {
        match: { path },
      } = this.props
      return <Header links={this.links} path={path} />
    }
  }

  const mstp = ({ user }) => ({ user })

  return connect(
    mstp,
    null
  )(HeaderNavigation)
}

export default withHeaderNavigation

// @flow
import { SET, UNSET } from "./constants"
import { unsetActiveUpdatingForm } from "data/Forms/actions"

export function openModal(type: string): {} {
  return {
    type: SET,
    payload: {
      type,
    },
  }
}

export function closeModal(type: string): {} {
  return {
    type: UNSET,
    payload: type,
  }
}

export const closeModalAndUnsetActiveUpdatingForm = (
  type: string,
  activeForm: string
) => (dispatch: Function) => {
  dispatch(closeModal(type))
  dispatch(unsetActiveUpdatingForm(activeForm))
}

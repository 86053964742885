/* FORMS */
export const AUTHORIZATION_FORM = "AuthorizationForm"
export const RESERVATION_FORM = "ReservationForm"
export const TABLE_FORM = "TableForm"
export const CLUB_FORM = "ClubForm"
export const LOCATION_FORM = "LocationForm"
export const PROMOTION_FORM = "PromotionForm"
export const EVENT_FORM = "EventForm"
export const USER_FORM = "UserForm"
export const CUSTOMER_FORM = "CustomerForm"
export const EMAIL_CHANGE_FORM = "EmailChangeForm"
export const PASSWORD_CHANGE_FORM = "PasswordChangeForm"
export const DEAL_FORM = "DealForm"

/* MODALS */
export const ATTACHMENT_MODAL = "AttachmentModal"
export const RESERVATION_DETAILS_MODAL = "ReservationDetailsModal"
export const RESERVATION_MODAL = "ReservationModal"
export const TABLE_MODAL = "TableModal"
export const CLUB_MODAL = "ClubModal"
export const EVENT_MODAL = "EventModal"
export const DEAL_MODAL = "DealModal"
export const USER_MODAL = "UserModal"
export const CUSTOMER_MODAL = "CustomerModal"
export const LOCATION_MODAL = "LocationModal"
export const PROMOTION_MODAL = "PromotionModal"

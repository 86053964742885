import React, { useEffect } from "react"
import moment from "moment"
import { connect, useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"

import { openModal } from "data/Modals/actions"
import { updateDealsFilters } from "data/DealsFilters/actions"
import { fetchDeals, modifyDealsWithOverridenTablePrices } from "data/Deals/actions"

import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import AccessControl from "components/AccessControl"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import ClubFilter from "components/ClubFilter"
import CategoryFilter from "components/CategoryFilter"
import { DEAL_MODAL } from "Constants"

import { Grid } from "semantic-ui-react"
import styles from "./styles.module.css"

const Filters = () => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state.dealsFilters)

  useEffect(() => {
    updateDealsFilters(filters)
    dispatch(
      fetchDeals({
        params: filters,
      })
    )

    // Object.keys(events.segments).forEach(key => {
    //   modifyEventsWithOverridenTablePrices({
    //     id: key,
    //     value: Boolean(events.segments[key].override_prices.length),
    //   })
    // })
  }, [])

  const createDeal = modal => {
    dispatch(openModal(DEAL_MODAL))
  }

  const applyFilters = currentFilters => {
    const filters = currentFilters || filters
    dispatch(
      fetchDeals({
        params: filters,
      })
    )
  }

  return (
    <React.Fragment>
      <TopPanel title="Deals">
        <Grid columns={5} stackable>
          <AccessControl
            renderSuperAdmin={
              <FilterContainer>
                <FilterItem>
                  <ClubFilter applyFilters={applyFilters} />
                </FilterItem>
                <FilterItem>
                  <CategoryFilter applyFilters={applyFilters} />
                </FilterItem>
                <FilterItem>
                  <ModalTriggerButton onClick={createDeal} content="Create Deal" />
                </FilterItem>
              </FilterContainer>
            }
            renderAdmin={
              <FilterContainer>
                <FilterItem>
                  <CategoryFilter applyFilters={applyFilters} />
                </FilterItem>
                <FilterItem>
                  <ModalTriggerButton onClick={openModal} content="Create Deal" />
                </FilterItem>
              </FilterContainer>
            }
          />
        </Grid>
      </TopPanel>
    </React.Fragment>
  )
}

export default Filters

// @flow
import * as React from "react"
import { connect } from "react-redux"

import Notification from "./Notification"
import NotificationsContainer from "./NotificationsContainer"
import type { NotificationsObjectType } from "./types"

type Props = {
  notifications: NotificationsObjectType,
}

const Notifications = ({ notifications }: Props) => (
  <div>
    <NotificationsContainer
      component={Notification}
      notifications={notifications}
    />
  </div>
)

const mstp = ({ notifications }) => ({ notifications })

export default connect(
  mstp,
  null
)(Notifications)

import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"
import { ATTACHMENT_MODAL } from "Constants"
import { Grid, Divider } from "semantic-ui-react"
import Modal from "components/Modal"
import FilePlaceholder from "components/FilePlaceholder"
import FileExport from "components/FileExport/FileExport"

class AttachmentModal extends React.Component {
  state = { image: {} }

  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(ATTACHMENT_MODAL, activeModal)
  }

  render() {
    const { activeModal: image } = this.props
    const { path, preview: filePreview, name, _id } = image
    const preview = path || filePreview

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Grid padded columns="equal">
          <Grid.Row>
            <Grid.Column>
              <h1 className="font-weight-400">Attachment preview</h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Divider hidden />
              <FilePlaceholder url={preview} name={name} className="huge" />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <FileExport text="Download" id={_id} />

            <span onClick={this.closeModal} className="link">
              Close
            </span>
          </Grid.Row>
        </Grid>
      </Modal>
    )
  }
}

const mstp = ({ forms }) => ({
  activeModal: forms.activeUpdatingForm,
})

const actions = {
  closeModalAndUnsetActiveUpdatingForm,
}

export default connect(
  mstp,
  actions
)(AttachmentModal)

import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import apiMiddleware from "middleware/api"
import fakeApiMiddleware from "middleware/fakeApi"

import thunkMiddleware from "redux-thunk"
import logger from "redux-logger"

import combinedReducers from "./combinedReducers"

const store = createStore(
  combinedReducers,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware),
    applyMiddleware(logger),
    applyMiddleware(apiMiddleware),
    applyMiddleware(fakeApiMiddleware)
  )
)

export default store

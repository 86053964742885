// @flow
import { CALL_API } from "middleware/api"

import { READ_REQUEST, READ_SUCCESS, READ_FAILURE } from "./constants"

export const fetchCategories = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: httpClient => httpClient.get("/deals/categories"),
    payloadModifier: payload => (Array.isArray(payload) ? payload : [payload]),
  },
})

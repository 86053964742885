// @flow
import uuid from "uuid/v4"

const objectifyArray = (input: any) => {
  if (Array.isArray(input)) {
    const output = {}
    input.forEach(item => {
      if (item.id) return (output[item.id] = item)
      if (item._id) return (output[item._id] = item)
      return (output[uuid()] = item)
    })
    return output
  }
  return input
}

export default objectifyArray

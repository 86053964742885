// @flow

import React from "react"
import { Field } from "redux-form"
import { Grid } from "semantic-ui-react"
import { InputField, TextAreaField, CheckboxField, SelectField } from "react-semantic-redux-form"
import { fetchLocations } from "data/Locations/actions"
import {
  required,
  maxImages10,
  maxSize1MB,
  noWhitespace,
  onlyNumericAndSpaces,
} from "wrappers/FormValidation/validations"
import ImagesUploadField from "components/ImagesUploadField"

type PropType = {
  initialValues: {
    location_id: ?String,
  },
}

type StateType = {
  countryOptions: ?Array,
  selectedCountry: ?String,
  cityOptions: ?Array,
}

class ClubForm extends React.Component<PropType, StateType> {
  state = {
    countryOptions: [],
    cityOptions: [],
  }

  componentDidMount() {
    const countries = [...new Set(this.props.data.locations.map((location) => location.country))]

    if (this.props.initialValues && this.props.initialValues.location_id) {
      const selectedCountry = this.props.data.locations.find(
        (location) => location.value === this.props.initialValues.location_id
      )

      if (selectedCountry) {
        this.props.initialValues.country = selectedCountry.country
      }

      this.setState({
        ...this.state,
        countryOptions: countries.map((country) => {
          return { value: country, text: country }
        }),
        selectedCountry,
        cityOptions: this.props.data.locations.filter((location) => location.country === selectedCountry.country),
      })
    } else {
      this.setState({
        ...this.state,
        countryOptions: countries.map((country) => {
          return { value: country, text: country }
        }),
      })
    }
  }

  handleCountryChange = (param) => {
    this.props.change("location_id", null)
    const cities = this.setState({
      ...this.state,
      selectedCountry: param,
      cityOptions: this.props.data.locations.filter((location) => location.country === param),
    })
  }

  render() {
    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column>
            <label>Name of the venue</label>
            <Field
              name="name"
              component={InputField}
              type="text"
              placeholder="Enter name"
              validate={[required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Url name (ex. venue-name)</label>
            <Field
              name="slug"
              component={InputField}
              type="text"
              placeholder="Enter url name"
              validate={[noWhitespace, required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Address</label>
            <Field
              name="address"
              component={InputField}
              type="text"
              placeholder="Enter address"
              validate={[required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Open</label>
            <Field
              name="open"
              component={InputField}
              type="text"
              // validate={[required]}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <label>Closed</label>
            <Field
              name="closed"
              component={InputField}
              type="text"
              // validate={[required]}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <label>Music</label>
            <Field
              name="music_type"
              component={InputField}
              type="text"
              // validate={[required]}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <label>Dresscode</label>
            <Field
              name="dress_code"
              component={InputField}
              type="text"
              // validate={[required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <label>Description</label>
            <Field name="description" autoHeight component={TextAreaField} type="text" fluid />
          </Grid.Column>
          <Grid.Column>
            <label>Things to note for client</label>
            <Field name="client_note" autoHeight component={TextAreaField} type="text" fluid />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Country</label>
            <Field
              name="country"
              component={SelectField}
              options={this.state.countryOptions}
              placeholder="Select Country"
              onChange={this.handleCountryChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>City</label>
            <Field
              name="location_id"
              component={SelectField}
              options={this.state.cityOptions}
              placeholder="Select City"
              validate={[required]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Allow booking before (Day)</label>
            <Field name="booked_before" component={InputField} type="number" validate={[onlyNumericAndSpaces]} />
          </Grid.Column>
        </Grid.Row>
        <span>(Leave blank or 0 to allow same day booking)</span>
        <Grid.Row>
          <Grid.Column>
            <Field name="hidden" component={CheckboxField} label="Hide venue" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field name="booking_enabled" component={CheckboxField} label="Booking enabled" />
          </Grid.Column>
          <Grid.Column>
            <Field name="payment_enabled" component={CheckboxField} label="Payment enabled" />
          </Grid.Column>
        </Grid.Row>
        <Field name="images" component={ImagesUploadField} validate={[maxImages10, maxSize1MB]} />
      </React.Fragment>
    )
  }
}

export default ClubForm

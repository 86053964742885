import { PROMOTIONS } from "./constants"

import { commonReducerState, commonReducerActionHandlers } from "utils/commonReducerUtils"

const PromotionsReducer = (
  state = {
    ...commonReducerState,
  },
  { type, payload }
) =>
  commonReducerActionHandlers(
    {
      state,
      type,
      payload,
    },
    PROMOTIONS,
    () => {
      return state
    }
  )

export default PromotionsReducer

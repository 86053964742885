import { instance as httpClient } from "instances/Axios"
import objectifyArray from "utils/objectifyArray"

import { showNotification } from "wrappers/Notifications/actions"
import { unsetActiveUpdatingForm } from "data/Forms/actions"
import { closeModal } from "data/Modals/actions"
import { reset } from "redux-form"

export const CALL_API = "Call API"

function validateCallAPI(types, promise) {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.")
  }

  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.")
  }

  return true
}

/*
  CALL_API: {
    types: (required) [REQUEST_TYPE, SUCCESS_TYPE, ERROR_TYPE]
    promise: (required) function returning a promise
    schema: (optional) response data will be mapped to scheme
    notifyOnError: (optional) if false, error wont be forwarded to notifyCenter
    successMessage: (optional)  if set, message will be forwarded to notifyCenter
    errorMessage: (optional)  if set, message will be forwarded to notifyCenter
    unauthorizedMessage: (optional) if set, message will be forwarded to notifyCenter
    formResetName: (optional) if set, redux-form (action) reset will be dispatched
    closeModal: (optional) if set, modal will be closed
    resetActiveFormId: (optional) if set, it will reset activeUpdatingForm
    payloadModifier:function (optional) if set, response data will be modified
  }
  ... all other fields will be forwarded to reducer
 */
export default store => next => async action => {
  const callAPI = action[CALL_API]

  if (typeof callAPI === "undefined") {
    return next(action)
  }

  const {
    types,
    promise,
    // notifyOnError,
    successMessage,
    errorMessage,
    // unauthorizedMessage,
    formResetName,
    closeModalId,
    resetActiveFormId,
    payloadModifier,
  } = callAPI

  validateCallAPI(types)

  const [requestType, successType, failureType] = types
  next({ type: requestType, ...action })

  try {
    const response = await promise(httpClient)
    let data = response.data.data ? response.data.data : response.data
    if (payloadModifier) data = payloadModifier(data)
    data = Array.isArray(data) ? objectifyArray(data) : data

    const nextAction = {
      type: successType,
      payload: data,
      ...action,
    }

    // TODO: replace with toaster or add notification middleware
    if (successMessage) {
      // nextAction.successMessage = successMessage
      showNotification(successMessage, next)
    }

    if ("formResetName" in callAPI) {
      next(reset(formResetName))
    }

    if ("closeModalId" in callAPI) {
      next(closeModal(closeModalId))
    }

    if ("resetActiveFormId" in callAPI) {
      next(unsetActiveUpdatingForm(resetActiveFormId))
    }

    return next(nextAction)
  } catch (err) {
    const nextAction = {
      type: failureType,
      payload: err,
      ...action,
    }

    if (errorMessage) {
      // nextAction.errorMessage = errorMessage
      showNotification(errorMessage, next)
    }

    return next(nextAction)
  }
}

// @flow
import * as React from "react"

import styles from "./styles.module.css"

const withContainer = (Component: React.ComponentType<any>) => (props: {}) => (
  <div className={styles.masterContainer}>
    <Component {...props} />
  </div>
)

export default withContainer

// @flow
import React from "react"

import styles from "./styles.module.css"
import checkmark from "assets/Icons/checkmark.svg"
import star from "assets/Icons/star.svg"

function returnMatchingIcon(name) {
  switch (name) {
    case "checkmark":
      return checkmark
    case "star":
      return star
    default:
      return checkmark
  }
}

const TableRowLabel = ({ icon }: { icon: string }) => (
  <span className={styles.masterContainer}>
    <div>
      <img src={returnMatchingIcon(icon)} alt={"Icon"} />
    </div>
  </span>
)

export default TableRowLabel

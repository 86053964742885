// @flow

import React from "react"
import { Field } from "redux-form"
import { Grid } from "semantic-ui-react"
import { InputField } from "react-semantic-redux-form"
import { required } from "wrappers/FormValidation/validations"

const LocationForm = () => (
  <React.Fragment>
    <Grid.Row>
      <Grid.Column>
        <label>Country</label>
        <Field
          name="country"
          component={InputField}
          type="text"
          placeholder="Enter country name"
          validate={[required]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <label>City</label>
        <Field
          name="city"
          component={InputField}
          type="text"
          placeholder="Enter city name"
          validate={[required]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
  </React.Fragment>
)

export default LocationForm

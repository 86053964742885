// @flow
import React from "react"
import { connect } from "react-redux"
import ReservationDetailsModal from "containers/Modals/ReservationDetailsModal"
import ReservationModal from "containers/Modals/ReservationModal"
import AttachmentModal from "containers/Modals/AttachmentModal"
import TableModal from "containers/Modals/TableModal"
import ClubModal from "containers/Modals/ClubModal"
import LocationModal from "containers/Modals/LocationModal"
import PromotionModal from "containers/Modals/PromotionModal"
import EventModal from "containers/Modals/EventModal"
import UserModal from "containers/Modals/UserModal"
import DealModal from "containers/Modals/DealModal"
import CustomerModal from "containers/Modals/CustomerModal"

const modalComponentLookupTable = {
  AttachmentModal,
  ReservationModal,
  ReservationDetailsModal,
  TableModal,
  LocationModal,
  PromotionModal,
  ClubModal,
  EventModal,
  DealModal,
  UserModal,
  CustomerModal,
}

const ModalManager = ({ currentModal }) => {
  if (currentModal && Object.keys(currentModal).length) {
    const { type, props = {} } = currentModal
    const ModalComponent = modalComponentLookupTable[type]

    return <ModalComponent {...props} />
  }

  return null
}

const mstp = ({ modals }) => ({
  currentModal: modals,
})

export default connect(mstp, null)(ModalManager)

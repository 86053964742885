// @flow
import AuthorizationForm from "components/AuthorizationForm"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { formValueSelector } from "redux-form"

import type { UserType } from "instances/User/types"
// import styles from "./styles.module.css"
import { _oldPushNotification } from "wrappers/Notifications/actions"
import { instance as axios } from "instances/Axios"
import authService from "instances/AuthService"
import { AUTHORIZATION_FORM } from "Constants"

type PropType = {
  user: UserType,
  pushNotification: Function,
  register: {
    name: string,
    password: string,
  },
}

type StateType = {
  email: string,
  password: string,
  fieldValues: any,
}

class Login extends React.Component<PropType, StateType> {
  handleSubmit = (e: SyntheticEvent<*>) => {
    const { name, email, password } = this.props.register
    this.register(name, password, email)
  }

  async register(name: string, email: string, password: string) {
    try {
      const { name, email, password } = this.props.register
      await axios.post("/superadmins/register", {
        name,
        email,
        password,
      })
      this.props.pushNotification({ content: "Registration successful" })
      authService.login({
        email,
        password,
      })
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        // TODO: also check for status codes, otherwise everything will break!
        return this.props.pushNotification({
          content: e.response.data.errors.join(", "),
        })
      }
      throw e
    }
  }

  render() {
    return (
      <AuthorizationForm
        password
        title="Sign up below"
        onSubmit={this.handleSubmit}
        submitButtonText="Register"
        confirmPassword
      />
    )
  }
}

const mstp = state => {
  return {
    user: state.user,
    register: formValueSelector(AUTHORIZATION_FORM)(state, "email", "password"),
  }
}

const mdtp = dispatch => ({
  pushNotification: bindActionCreators(_oldPushNotification, dispatch),
})

export default connect(
  mstp,
  mdtp
)(Login)

// @flow
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { Grid } from "semantic-ui-react"

import TableSection from "components/TableSection/"
import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import Loading from "components/Loading"
import rowsCreator from "utils/rowsCreator"
import { openModal } from "data/Modals/actions"
import { fetchPromotions, updatePromotion, deletePromotionAndReload } from "data/Promotions/actions"
import { setActiveUpdatingForm } from "data/Forms/actions"
import { headers } from "./PromotionTableHeaders"
import segmentToRow from "./SegmentToRow"

import styles from "./styles.module.css"
import { PROMOTION_MODAL } from "Constants"

type PropTypes = {
  fetchPromotions: Function,
  updatePromotion: Function,
  deletePromotionAndReload: Function,
  closeModal: Function,
  openModal: Function,
  promotions: {},
  modals: {
    CREATE_PROMOTION: string,
    UPDATE_PROMOTION: string,
  },
}
type StateTypes = {
  isModalOpen: boolean,
  activeModal: ?string,
}

class Promotions extends React.Component<PropTypes, StateTypes> {
  componentDidMount() {
    this.props.fetchPromotions()
  }

  render() {
    return (
      <div className={styles.masterContainer}>
        <TopPanel title="Promotions">
          <Grid columns={5} stackable>
            <FilterContainer>
              <FilterItem>
                <ModalTriggerButton
                  onClick={() => this.props.openModal(PROMOTION_MODAL)}
                  content="Create promotion"
                />
              </FilterItem>
            </FilterContainer>
          </Grid>
        </TopPanel>
        <Loading isLoading={this.props.promotions.readLoading}>
          <TableSection
            headers={headers}
            rows={rowsCreator(segmentToRow, this.props.promotions.segments, {
              openModal: () => this.props.openModal(PROMOTION_MODAL),
              setActiveUpdatingForm: (id) => this.props.setActiveUpdatingForm(id),
              deletePromotion: (id) => this.props.deletePromotionAndReload(id),
            })}
          />
        </Loading>
      </div>
    )
  }
}

const mstp = ({ promotions, modals, forms }) => ({
  promotions,
  modals,
  forms,
})

const mdtp = (dispatch) => ({
  fetchPromotions: bindActionCreators(fetchPromotions, dispatch),
  updatePromotion: bindActionCreators(updatePromotion, dispatch),
  deletePromotionAndReload: bindActionCreators(deletePromotionAndReload, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  setActiveUpdatingForm: bindActionCreators(setActiveUpdatingForm, dispatch),
})

export default connect(mstp, mdtp)(Promotions)

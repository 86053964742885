// @flow

import React from "react"
import { Field, FieldArray } from "redux-form"
import { Grid } from "semantic-ui-react"
import { InputField, LabelInputField, SelectField, CheckboxField, TextAreaField } from "react-semantic-redux-form"
import {
  required,
  onlyNumericAndSpaces,
  maxImages10,
  maxSize1MB,
  noWhitespace,
} from "wrappers/FormValidation/validations"
import renderDateField from "components/DateField/renderDateField"
import ImagesUploadField from "components/ImagesUploadField"
import { Object } from "core-js"
import AccessControl from "components/AccessControl"

type PropType = {
  showTablePrices: ?boolean,
  initialValues: {
    override_prices: ?Array,
    showOverridenPrices: ?boolean,
  },
}

type StateType = {
  showOverridenPrices: ?boolean,
}

class DealForm extends React.Component<PropType, StateType> {
  state = {
    showOverridenPrices: false,
  }

  componentDidMount() {
    if (this.props.initialValues.showOverridenPrices) {
      this.setState({ showOverridenPrices: true })
    }
  }

  toggleTablePrices = () =>
    this.setState((state) => ({
      showOverridenPrices: !state.showOverridenPrices,
    }))

  handleVenueChange = (param, nextClubId) => {
    const nextClubTables = this.props.clubTablePrices[nextClubId]

    if (!nextClubTables) {
      this.props.change("override_prices", [])
      return
    }

    const overridePrices = Object.values(nextClubTables).map((table) => {
      return {
        _id: table._id,
        name: table.name,
        price: table.minimum_spend,
      }
    })

    this.props.change("override_prices", overridePrices)
  }

  render() {
    const { showOverridenPrices } = this.state
    const {
      data: { clubsOptions, categoriesOptions },
    } = this.props
    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column>
            <label>Name of the deal</label>
            <Field
              name="name"
              component={InputField}
              type="text"
              placeholder="Enter name of the deal"
              validate={[required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Url name (ex. venue-name)</label>
            <Field
              name="slug"
              component={InputField}
              type="text"
              placeholder="Enter url name"
              validate={[noWhitespace, required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Venue</label>
            <Field
              name="club_id"
              component={SelectField}
              options={clubsOptions}
              validate={[required]}
              placeholder="Select Venue"
              onChange={this.handleVenueChange}
            />
          </Grid.Column>
          <Grid.Column>
            <label>Category</label>
            <Field
              name="category"
              component={SelectField}
              options={categoriesOptions}
              validate={[required]}
              placeholder="Select Category"
              onChange={this.handleCategoryChange}
            />
          </Grid.Column>
        </Grid.Row>
        <AccessControl
          renderSuperAdmin={
            <Grid.Row>
              <Grid.Column>
                <Field name="spotlight" component={CheckboxField} label="Spotlight deal" />
              </Grid.Column>
            </Grid.Row>
          }
        />
        <Grid.Row>
          <Grid.Column>
            <label>General deal information</label>
            <Field name="description" autoHeight component={TextAreaField} type="text" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Deal conditions</label>
            <Field name="conditions" autoHeight component={TextAreaField} type="text" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Minimum spend</label>
            <Field
              name="minimum_spend"
              component={LabelInputField}
              type="number"
              validate={[required, onlyNumericAndSpaces]}
              label={{ basic: true, content: "฿" }}
              labelPosition="left"
            />
          </Grid.Column>
        </Grid.Row>

        <Field name="images" component={ImagesUploadField} validate={[maxImages10, maxSize1MB]} />
      </React.Fragment>
    )
  }
}

export default DealForm

// @flow

import React from "react"
import { Grid } from "semantic-ui-react"
import { InputField } from "react-semantic-redux-form"
import { Field, reduxForm } from "redux-form"
import Button from "components/Button"
import { PASSWORD_CHANGE_FORM } from "Constants"
import {
  required,
  minLength8,
  maxLength256,
  matchPasswordField,
  includesNumericAndLetters,
} from "wrappers/FormValidation/validations"

type PropTypes = {
  handleSubmit: Function,
  changePassword: Function,
  pristine: ?boolean,
  submitting: ?boolean,
  valid: ?boolean,
}

const PasswordChange = ({
  changePassword,
  handleSubmit,
  pristine,
  submitting,
  valid,
}: PropTypes) => (
  <form onSubmit={handleSubmit(changePassword)}>
    <Grid columns={4} stackable>
      <Grid.Row>
        <Grid.Column>
          <label>Current Password</label>
          <Field
            name="oldPassword"
            component={InputField}
            type="password"
            placeholder="Enter current password"
            validate={[required, minLength8]}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <label>New password</label>
          <Field
            name="password"
            component={InputField}
            type="password"
            placeholder="Enter new password"
            validate={[
              required,
              minLength8,
              maxLength256,
              includesNumericAndLetters,
            ]}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <label>Confirm Password</label>
          <Field
            name="passwordConfirm"
            component={InputField}
            type="password"
            placeholder="Confirm new password"
            validate={[required, minLength8, matchPasswordField]}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <div className="labelless">
            <Button
              type="submit"
              disabled={pristine || submitting || !valid}
              style={{ padding: "0.75em 2em" }}
            >
              Change
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </form>
)

export default reduxForm({
  form: PASSWORD_CHANGE_FORM,
})(PasswordChange)

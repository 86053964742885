import React from "react"

export const headers = [
  { component: <span>Name</span> },
  { component: <span>Location</span> },
  { component: <span>Capacity</span> },
  { component: <span>Minimum spend</span> },
  { component: <span>Arrival time</span> },
  { component: <span>Contact</span> },
  { component: <span /> },
]

// @flow
import React from "react"
import TableOptions from "components/TableOptions"
import placeholder from "assets/Images/placeholder.jpg"
import HoverableDescription from "components/HoverableDescription"
import ColumnImage from "components/ColumnImage"
import { deletePromotion } from "../../data/Promotions/actions"
import moment from "moment"

export default function segmentToRow(
  { _id, name, promotion_type, amount, expiration_date, active, clubs, include_all_clubs },
  { openModal, setActiveUpdatingForm, deletePromotion }
) {
  return {
    columns: [
      {
        component: (
          <HoverableDescription>
            <strong>{name}</strong>
          </HoverableDescription>
        ),
      },
      { component: <HoverableDescription>{promotion_type}</HoverableDescription> },
      { component: <HoverableDescription>{amount}</HoverableDescription> },
      {
        component: (
          <span>{expiration_date ? moment(expiration_date).format("D MMMM YYYY") : "No expiration"}</span>
        ),
      },
      {
        component: (
          <span>
            {include_all_clubs ? "All Clubs" : clubs && clubs.length > 0 ? `${clubs.length} Clubs` : "-"}
          </span>
        ),
      },
      { component: <span style={{ color: active ? "green" : "gray" }}>{active ? "Active" : "Inactive"}</span> },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              setActiveUpdatingForm(_id)
              openModal()
            }}
            onDeleteOptionClick={() => {
              deletePromotion(_id)
            }}
            editOptionText="Edit promotion"
            deleteOptionText="Delete promotion"
          />
        ),
      },
    ],
  }
}

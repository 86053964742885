// @flow

import React from "react"
import { Field } from "redux-form"
import { Grid } from "semantic-ui-react"
import { CheckboxField, InputField, LabelInputField, SelectField, DropdownField } from "react-semantic-redux-form"
import renderDateField from "components/DateField/renderDateField"
import { required, onlyNumericAndSpaces } from "wrappers/FormValidation/validations"

const promotionTypes = [
  {
    value: "flat_discount",
    text: "Flat Discount (฿)",
  },
  {
    value: "percentage_discount",
    text: "Percentage Discount (%)",
  },
]
class PromotionForm extends React.Component {
  componentDidMount() {
    if (!this.props.initialValues) {
      this.props.change("active", true)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column>
            <label>Name</label>
            <Field
              name="name"
              component={InputField}
              type="text"
              placeholder="Enter Promotion Name"
              validate={[required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Promotion Type</label>
            <Field
              name="promotion_type"
              component={SelectField}
              options={promotionTypes}
              validate={[required]}
              placeholder="Select Promotion Type"
            />
          </Grid.Column>
          <Grid.Column>
            <label>Amount</label>
            <Field
              name="amount"
              component={InputField}
              type="number"
              validate={[required, onlyNumericAndSpaces]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Participating Venues</label>
            <Field
              name="clubs"
              component={DropdownField}
              options={this.props.data.clubsOptions}
              style={{ width: "100%" }}
              multiple
              selection
              placeholder="Select Venues"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              name="include_all_clubs"
              component={CheckboxField}
              label="Include all clubs in this promotion"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ alignItems: "end" }}>
          <Grid.Column>
            <label>Expiration date (Optional)</label>
            <Field name="expiration_date" component={renderDateField} fluid />
          </Grid.Column>
          <Grid.Column>
            <Field name="active" component={CheckboxField} label="Active" />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    )
  }
}

export default PromotionForm

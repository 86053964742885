import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"

import Modal from "components/Modal"
import ClubForm from "views/Clubs/ClubForm"
import Form from "components/Form"
import {
  createClubAndReload,
  createClubWithImagesAndReload,
  updateClubAndReload,
  updateClubWithImagesAndReload,
} from "data/Clubs/actions"
import { CLUB_FORM, CLUB_MODAL } from "Constants"
import dropdownize from "utils/dropdownize"

class ClubModal extends React.Component {
  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(CLUB_MODAL, activeModal)
  }

  createClub = (club) => {
    const { createClubWithImagesAndReload, activeModal } = this.props

    const clubData = {
      params: club,
      modalId: CLUB_MODAL,
      formId: activeModal,
    }

    createClubWithImagesAndReload(clubData)
  }

  updateClub = (club) => {
    const { updateClubWithImagesAndReload, activeModal, initialValues } = this.props

    const clubData = {
      params: { storedImages: initialValues.images, ...club },
      modalId: CLUB_MODAL,
      formId: activeModal,
    }

    updateClubWithImagesAndReload(clubData)
  }

  render() {
    const { locations, initialValues } = this.props

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={CLUB_FORM}
          header={initialValues ? "Update venue" : "Create new venue"}
          submitButtonText={initialValues ? "Update venue" : "Create venue"}
          initialValues={initialValues}
          onCancel={this.closeModal}
          onSubmit={initialValues ? this.updateClub : this.createClub}
          columns={4}
        >
          <ClubForm
            data={{
              locations: dropdownize(locations.segments, "country"),
            }}
          />
        </Form>
      </Modal>
    )
  }
}

const mstp = ({ locations, clubs, forms }) => {
  const { activeUpdatingForm } = forms

  return {
    locations,
    activeModal: activeUpdatingForm,
    initialValues: activeUpdatingForm ? clubs.segments[activeUpdatingForm] : null,
  }
}

const actions = {
  closeModalAndUnsetActiveUpdatingForm,
  createClubAndReload,
  createClubWithImagesAndReload,
  updateClubAndReload,
  updateClubWithImagesAndReload,
}

export default connect(mstp, actions)(ClubModal)

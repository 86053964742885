// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Icon } from "semantic-ui-react"
import Dropzone from "react-dropzone"
import PopupMenu from "components/PopupMenu"
import { uploadAttachment, removeAttachment } from "data/Overview/actions"
import { setActiveUpdatingForm } from "data/Forms/actions"
import { openModal } from "data/Modals/actions"
import { ATTACHMENT_MODAL } from "Constants"
import styles from "./styles.module.css"
import FilePlaceholder from "components/FilePlaceholder"
import Loading from "components/Loading"

type PropTypes = {}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}
class AttachmentButton extends React.Component<PropTypes> {
  state = {
    uploading: false,
    attachment: this.props.attachment || {},
  }

  fileTypes =
    "text/plain, text/csv, image/jpeg, image/png, application/pdf, application/rtf, " +
    "application/msword, application/epub+zip, application/vnd.ms-excel, " +
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " +
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document "

  uploadAttachment = async files => {
    this.setState({ uploading: true })
    const { id, uploadAttachment } = this.props
    const upload = await uploadAttachment(id, files)
    const { name, preview } = files[0]

    if (isSuccessType(upload)) {
      const attachment = Object.values(upload.payload)[0]
      this.setState({
        attachment: {
          name,
          preview,
          ...attachment,
          _id: attachment.id,
        },
      })
    }

    this.setState({ uploading: false })
  }

  deleteAttachment = async () => {
    const remove = await this.props.removeAttachment(this.state.attachment)

    if (isSuccessType(remove)) this.setState({ attachment: {} })
  }

  imagePreview = () => {
    const { setActiveUpdatingForm, openModal } = this.props
    setActiveUpdatingForm(this.state.attachment)
    openModal(ATTACHMENT_MODAL)
  }

  render() {
    const { attachment, uploading } = this.state
    const { path, preview: filePreview, name } = attachment
    const preview = filePreview || path

    return (
      <PopupMenu
        trigger={onClick => (
          <button
            onClick={onClick}
            className={preview ? styles.preview : styles.triggerButton}
          >
            <Loading isLoading={uploading}>
              {preview ? (
                <FilePlaceholder url={preview} name={name} className="small" />
              ) : (
                <Icon name="plus" className={styles.icon} />
              )}
            </Loading>
          </button>
        )}
        options={[
          {
            component: (
              <Dropzone
                className={styles.attachmentUpload}
                accept={this.fileTypes}
                onDrop={this.uploadAttachment}
              >
                Add attachment
              </Dropzone>
            ),
          },
          {
            text: attachment._id && "Preview attachment",
            onClick: this.imagePreview,
          },
          {
            text: attachment._id && "Delete attachment",
            onClick: this.deleteAttachment,
          },
        ]}
      />
    )
  }
}

export default connect(
  null,
  dispatch => ({
    uploadAttachment: bindActionCreators(uploadAttachment, dispatch),
    removeAttachment: bindActionCreators(removeAttachment, dispatch),
    setActiveUpdatingForm: bindActionCreators(setActiveUpdatingForm, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
  })
)(AttachmentButton)

// @flow
import React from "react"
import moment from "moment"
import { Popup } from "semantic-ui-react"

import TableOptions from "components/TableOptions"
import ColumnImage from "components/ColumnImage"
import TableRowLabel from "components/TableRowLabel"
import HoverableDescription from "components/HoverableDescription"

import placeholder from "assets/Images/placeholder.jpg"
import { deleteDeal } from "../../data/Deals/actions"

export default function SegmentToRow(
  {
    _id,
    club_id,
    name,
    date,
    category,
    images,
    description,
    spotlight,
    minimum_spend,
    music_type,
    dress_code,
    override_prices = [],
  },
  { clubsLoaded, clubs, setActiveUpdatingForm, openModal, deleteDeal, tables, selectClub, selectDeal }
) {
  const image = (images.length && images[0].path) || placeholder

  return {
    columns: [
      {
        component: (
          <span>
            <ColumnImage src={image} alt="" />
          </span>
        ),
      },
      { component: <HoverableDescription>{name}</HoverableDescription> },
      { component: <span>{category}</span> },
      { component: <HoverableDescription>{description}</HoverableDescription> },
      {
        component: <span>{minimum_spend}</span>,
      },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              selectClub(club_id)
              selectDeal(_id)
              setActiveUpdatingForm(_id)
              openModal()
            }}
            onDeleteOptionClick={() => {
              deleteDeal(_id)
            }}
            editOptionText="Edit deal"
            deleteOptionText="Delete deal"
          />
        ),
      },
    ],
    label: spotlight
      ? {
          component: <TableRowLabel icon="star" />,
        }
      : undefined,
  }
}

export const tableOptions = [
  {
    key: "all_tables",
    value: "all",
    text: "All Tables",
  },
  {
    key: "available_tables",
    value: "available",
    text: "Available Tables",
  },
  {
    key: "booked_tables",
    value: "booked",
    text: "Booked Tables",
  },
]

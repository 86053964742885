// @flow
import React from "react"
import { Grid, Divider } from "semantic-ui-react"

export const NoBookings = () => (
  <Grid.Row>
    <Grid.Column floated="left">
      No bookings for selected period
      <Divider hidden />
    </Grid.Column>
  </Grid.Row>
)

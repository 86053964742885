// @flow
import React from "react"
import { Divider } from "semantic-ui-react"
import styles from "./styles.module.scss"

export const TotalRow = ({
  totalSpend,
  totalBookings,
}: {
  totalSpend: ?number,
  totalBookings: ?number,
}) => (
  <ul>
    <li className={styles.borderTop}>
      <div className={styles.detailRow}>
        <div>
          <h3>Total</h3>
        </div>
        <div>
          {totalBookings}
          &nbsp; Bookings
        </div>
        <div className={styles.textRight}>
          <h3>
            $ {totalSpend}
            <Divider hidden horizontal />
          </h3>
        </div>
      </div>
    </li>
  </ul>
)

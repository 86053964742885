// @flow
import { UPDATE } from "./constants"
import type { DealsFilterType } from "./types"

export const updateDealsFilters = (filters: DealsFilterType) => (dispatch: Function) => {
  return dispatch({
    type: UPDATE,
    payload: filters,
  })
}

// @flow

import React from "react"

const mailTo = "partnerships@tablebooking.club"

const FooterInfo = () => (
  <p>
    Contact TABLE at
    <a href={`mailto: ${mailTo}`} className="link">
      &nbsp;
      {mailTo}
      &nbsp;
    </a>
    if you run into any issues.
  </p>
)

export default FooterInfo

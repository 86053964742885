// @flow
import React from "react"
import { Icon } from "semantic-ui-react"
import { connect } from "react-redux"

import PopupMenu from "components/PopupMenu"
import authService from "instances/AuthService"
import type { UserType } from "instances/User/types"

import styles from "./styles.module.css"

type PropTypes = {
  user: UserType,
}

class HeaderUserMenu extends React.PureComponent<PropTypes> {
  render() {
    return (
      <PopupMenu
        options={[{ text: "Logout", onClick: () => authService.logout() }]}
        popupProps={{ style: { transform: "translateY(-1em)", zIndex: 2100 } }}
        trigger={onClick => (
          <div
            onClick={onClick}
            className={styles.userContainer}
            name={"header-user-container"}
          >
            <React.Fragment>
              {/* <img
            alt="Avatar"
            src={"https://ui-avatars.com/api/?name=John-Smith&length=2"}
          /> */}
              <span>
                {(this.props.user && this.props.user.fullName) ||
                  this.props.user.email}
              </span>
              <Icon className={styles.icon} name="caret down" />
            </React.Fragment>
          </div>
        )}
      />
    )
  }
}

const mstp = ({ user }) => ({ user })

export default connect(
  mstp,
  null
)(HeaderUserMenu)

// @flow
import React from "react"
import moment from "moment"
import { Popup } from "semantic-ui-react"

import { SUPERADMIN } from "instances/User/constants"
import TableOptions from "components/TableOptions"
import ColumnImage from "components/ColumnImage"
import TableRowLabel from "components/TableRowLabel"
import HoverableDescription from "components/HoverableDescription"
import { getTransactionStatus } from "components/TransactionStatus/Options"
import AccessControl from "components/AccessControl"
import ConfirmReservation from "components/ConfirmReservation"
import TransactionStatus from "components/TransactionStatus"
import AttachmentButton from "components/AttachmentButton"

import placeholder from "assets/Images/placeholder.jpg"
import { deleteDeal } from "../../data/Deals/actions"

export default function SegmentToRow({ _id, deals }, { updateBooking, deleteBooking, role }) {
  const { booking, category, club_id, minimum_spend, name } = deals

  const {
    booking_number,
    comments,
    contact,
    confirmed,
    transaction_status,
    attachment,
    qty,
    date,
    minimum_spend: booking_minimum_spend,
  } = booking

  const formattedBookingNumber =
    booking_number && String(booking_number).replace(/^(\d{3})(\d{4})(\d+)$/, "$1-$2-$3")

  const row = {
    columns: [
      { component: <span>{formattedBookingNumber}</span> },
      { component: <HoverableDescription>{name}</HoverableDescription> },
      { component: <span>{date ? moment(date).format("DD-MM-YYYY") : "-"}</span> },
      { component: <span>{(booking_minimum_spend || minimum_spend) * (qty || 1)}</span> },
      { component: <span>{qty || 1}</span> },
      { component: <span>{contact || "-"}</span> },
      {
        component: (
          <AccessControl
            renderSuperAdmin={<ConfirmReservation id={_id} confirmed={confirmed} updateBooking={updateBooking} />}
            renderAdmin={<span>{confirmed ? "Yes" : "No"}</span>}
          />
        ),
      },
      {
        component: (
          <AccessControl
            renderSuperAdmin={
              <TransactionStatus id={_id} transactionStatus={transaction_status} updateBooking={updateBooking} />
            }
            renderAdmin={
              <span>{transaction_status ? getTransactionStatus(transaction_status) : "No payment yet"}</span>
            }
          />
        ),
      },
      {
        component: _id ? <AttachmentButton id={_id} attachment={attachment} /> : "-",
      },
      {
        component: (
          <TableOptions
            onDeleteOptionClick={() => {
              deleteBooking(_id)
            }}
            deleteOptionText={"Delete reservation"}
          />
        ),
      },
    ],
    label: {
      component: <TableRowLabel icon="checkmark" />,
    },
  }

  if (role !== SUPERADMIN) {
    // for clubadmin remove transaction_status - index 7 & contact - index 5
    row.columns.splice(7, 1)
    row.columns.splice(5, 1)
  }

  return row
}

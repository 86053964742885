import React from "react"

export const headers = [
  { component: <span>Name</span> },
  { component: <span>Promotion Type</span> },
  { component: <span>Discount Amount</span> },
  { component: <span>Expired Date</span> },
  { component: <span>Participating Clubs</span> },
  { component: <span /> },
]

// @flow
import React from "react"
import ReactDOM from "react-dom"

import styles from "./styles.module.css"

type PropTypes = {
  children: any,
}

type StateTypes = {}

const modalRoot: ?HTMLElement = document.getElementById("modal-root")

class Modal extends React.Component<PropTypes, StateTypes> {
  el = document.createElement("div")

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyboardClose.bind(this))
    modalRoot.appendChild(this.el)
    document.querySelector("body").style.overflow = "hidden"
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyboardClose.bind(this))
    document.querySelector("body").style.overflow = "auto"
    modalRoot.removeChild(this.el)
  }

  handleKeyboardClose(e) {
    if (e.key === "Esc" || e.key === "Escape" || e.key === 27) {
      this.props.onClose()
      e.preventDefault()
      return false
    }
  }

  handleDimmerClick() {
    this.props.onClose()
  }

  render() {
    const { children, trigger, isOpen } = this.props

    return (
      <React.Fragment>
        {trigger}
        {isOpen &&
          ReactDOM.createPortal(
            <React.Fragment>
              <div className={styles.masterContainer}>
                <div
                  className={styles.dimmer}
                  onClick={() => this.handleDimmerClick()}
                />
                <div className={styles.bodyContainer}>{children}</div>
              </div>
            </React.Fragment>,
            this.el
          )}
      </React.Fragment>
    )
  }
}

export default Modal

// @flow
import * as React from "react"
import { Segment, Grid } from "semantic-ui-react"

import styles from "./styles.module.css"

type PropTypes = {
  compact?: Boolean,
  headingComponent?: any,
  headers: Array<{ component: any }>,
  rows: Array<{
    label: { component: any },
    columns: Array<{ component: any, style: {} }>,
  }>,
}

const TableSection = ({ headers, rows, headingComponent, compact }: PropTypes) => (
  <React.Fragment>
    <div className={styles.bodyContainer}>
      {headingComponent}
      <table className={`${styles.table} ${compact ? styles.compactTable : ""}`}>
        <thead>
          <tr className={"heading-row"}>
            {headers.map(({ component }, i) => (
              <td key={i}>{component}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {row.columns.map(({ component, style }: { component: any, style: any }, j) => (
                <td key={j} style={style}>
                  {component}
                  {j === row.columns.length - 1 && row.label && row.label.component}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className={styles.mobileBodyContainer}>
      <div>
        {rows.map((row, i) => (
          <Segment key={`segment-${i}`} style={{ margin: "1em 0.5em" }}>
            <Grid columns="equal">
              {headers.map(({ component }, j) => (
                <Grid.Row key={`row-${j}`}>
                  {j === headers.length - 1 ? (
                    <React.Fragment>{rows[i].columns[j].component}</React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid.Column>
                        <span className={styles.mobileBodyHeader}>{component}</span>
                      </Grid.Column>
                      <Grid.Column>{rows[i].columns[j].component}</Grid.Column>
                    </React.Fragment>
                  )}
                </Grid.Row>
              ))}
            </Grid>
          </Segment>
        ))}
      </div>
    </div>
  </React.Fragment>
)

export default TableSection

// @flow
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { Grid } from "semantic-ui-react"

import TableSection from "components/TableSection/"
import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import Loading from "components/Loading"
import rowsCreator from "utils/rowsCreator"
import { openModal } from "data/Modals/actions"
import { fetchClubs, updateClub, deleteClubAndReload } from "data/Clubs/actions"
import { setActiveUpdatingForm } from "data/Forms/actions"
import { headers } from "./ClubTableHeaders"
import segmentToRow from "./SegmentToRow"

import styles from "./styles.module.css"
import { CLUB_MODAL } from "Constants"

type PropTypes = {
  fetchClubs: Function,
  updateClub: Function,
  deleteClubAndReload: Function,
  closeModal: Function,
  openModal: Function,
  clubs: {},
  modals: {
    CREATE_CLUB: string,
    UPDATE_CLUB: string,
  },
}
type StateTypes = {
  isModalOpen: boolean,
  activeModal: ?string,
}

class Clubs extends React.Component<PropTypes, StateTypes> {
  componentDidMount() {
    this.props.fetchClubs()
  }

  render() {
    return (
      <div className={styles.masterContainer}>
        <TopPanel title="Venues">
          <Grid columns={5} stackable>
            <FilterContainer>
              <FilterItem>
                <ModalTriggerButton onClick={() => this.props.openModal(CLUB_MODAL)} content="Create venue" />
              </FilterItem>
            </FilterContainer>
          </Grid>
        </TopPanel>
        <Loading isLoading={this.props.clubs.readLoading}>
          <TableSection
            headers={headers}
            rows={rowsCreator(segmentToRow, this.props.clubs.segments, {
              openModal: () => this.props.openModal(CLUB_MODAL),
              setActiveUpdatingForm: (id) => this.props.setActiveUpdatingForm(id),
              deleteClub: (id) => this.props.deleteClubAndReload(id),
              users: this.props.users.segments,
              tables: this.props.tables.segments,
            })}
          />
        </Loading>
      </div>
    )
  }
}

const mstp = ({ clubs, modals, forms, users }) => ({
  clubs,
  modals,
  forms,
  users,
})

const mdtp = (dispatch) => ({
  fetchClubs: bindActionCreators(fetchClubs, dispatch),
  updateClub: bindActionCreators(updateClub, dispatch),
  deleteClubAndReload: bindActionCreators(deleteClubAndReload, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  setActiveUpdatingForm: bindActionCreators(setActiveUpdatingForm, dispatch),
})

export default connect(mstp, mdtp)(Clubs)

// @flow
import * as React from "react"
import { BrowserRouter } from "react-router-dom"
import Routes from "wrappers/Routes"

const Router = (props: {}) => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
)

export default Router

import React from "react"
import TableOptions from "components/TableOptions"
import HoverableDescription from "components/HoverableDescription"

export default function segmentToRow(
  { _id, club_id, name, placement, capacity, minimum_spend, arrival_time },
  { openModal, setActiveUpdatingForm, deleteTable }
) {
  return {
    columns: [
      { component: <HoverableDescription>{name}</HoverableDescription> },
      { component: <HoverableDescription>{placement}</HoverableDescription> },
      { component: <span>{capacity}</span> },
      { component: <span>{minimum_spend}</span> },
      { component: <span>{arrival_time}</span> },
      { component: <span>...</span> },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              setActiveUpdatingForm(_id)
              openModal(_id)
            }}
            onDeleteOptionClick={() => deleteTable(_id)}
            deleteOptionText="Delete table"
            editOptionText="Edit table"
          />
        ),
        style: { width: "100%", textAlign: "right" },
      },
    ],
  }
}

// @flow
import React from "react"
import TableOptions from "components/TableOptions"

export default function segmentToRow(
  { _id, name, venue, role, phone_number, email },
  { deleteUser, clubs, setActiveUpdatingForm, openModal }
) {
  return {
    columns: [
      {
        component: (
          <span>
            <strong>{name}</strong>
          </span>
        ),
      },
      {
        component: (
          <span>
            {extractClubNameByAdminId({
              adminId: _id,
              clubs,
            })}
          </span>
        ),
      },
      { component: <span>{role}</span> },
      { component: <span>{phone_number}</span> },
      { component: <span>{email}</span> },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              setActiveUpdatingForm(_id)
              openModal()
            }}
            onDeleteOptionClick={() => deleteUser(_id)}
            editOptionText="Edit user"
            deleteOptionText="Delete user"
          />
        ),
      },
    ],
  }
}

function extractClubNameByAdminId({
  adminId,
  clubs,
}: {
  adminId: string,
  clubs: {},
}) {
  let output = null
  Object.keys(clubs).forEach(key => {
    if (clubs[key].admins && clubs[key].admins.includes(adminId)) output = clubs[key].name
  })
  return output ? output : "-"
}

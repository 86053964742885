import React from "react"
import Dropzone from "react-dropzone"

import styles from "./styles.module.css"

const ImagesUpload = ({ name, onChange, files = [], disabled }) => (
  <div className={styles.dropzone}>
    <Dropzone
      className="ui pink basic button upload"
      accept="image/jpeg, image/png, image/webp"
      name={name}
      onDrop={(filesToUpload) => {
        onChange([...files, ...filesToUpload])
      }}
      disabled={disabled}
    >
      Upload images
    </Dropzone>
  </div>
)

export default ImagesUpload

import React from "react"

export const headers = [
  { component: <span>Name</span> },
  { component: <span>Venue</span> },
  { component: <span>Role</span> },
  { component: <span>Phone</span> },
  { component: <span>Email</span> },
  { component: <span /> },
]

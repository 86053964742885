import React from "react"

export const headers = [
  { component: <span>Picture</span> },
  { component: <span>Name of the event</span> },
  { component: <span>Date of event</span> },
  { component: <span>Type of event</span> },
  { component: <span>Description</span> },
  { component: <span>Specific prices</span> },
  { component: <span /> },
]

import React from "react"
import { connect } from "react-redux"
import moment from "moment"

import { openModal } from "data/Modals/actions"
import Button from "components/Button"
import AccessControl from "components/AccessControl"
import FollowingBar from "components/FollowingBar"
import { RESERVATION_DETAILS_MODAL } from "Constants"

import styles from "./styles.module.css"

class Footer extends React.Component {
  render() {
    const { overview, filters } = this.props
    const { date_from, date_to } = filters
    const { segments: overviewData, readLoading } = overview
    let totalSpend = 0
    let bookedTables = 0
    Object.values(overviewData).forEach((club) => {
      club.dates.forEach((date) => {
        const tables = date.tables.filter((table) => table.booking)
        const table = tables.filter((table) => {
          if (table.booking && table.booking._id) {
            totalSpend += table.booking.minimum_spend || table.minimum_spend
            return table
          }
          return null
        })
        bookedTables += table.length
      })
    })

    const style = readLoading || !date_from || !date_to ? styles["hide"] : ""
    return (
      <AccessControl
        renderSuperAdmin={
          <FollowingBar
            className={`${styles.totalPriceContainer} ${style}`}
            leftPanelComponent={
              <div className={styles.followingBarLeftPanelContainer}>
                <div className="vertical-align">
                  <span className={styles.weak}>There were</span>
                  <span className={styles.strong}>{bookedTables} tables</span>
                  <span className={styles.weak}>booked from</span>
                  <span className={styles.strong}>{moment(date_from).format("MMM D")}</span>
                  <span className={styles.weak}>to</span>
                  <span className={styles.strong}>{moment(date_to).format("MMM D")}</span>
                </div>
              </div>
            }
            rightPanelComponent={
              <div className={styles.followingBarRightPanelContainer}>
                <div className={styles.totalPrice}>
                  <div>฿{totalSpend}</div>
                  <div>Total</div>
                </div>

                <Button onClick={() => this.props.openModal(RESERVATION_DETAILS_MODAL)}>View details</Button>
              </div>
            }
          />
        }
      />
    )
  }
}

export default connect(
  ({ overview, filters }) => ({
    overview,
    filters,
  }),
  { openModal }
)(Footer)

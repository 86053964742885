// @flow
import React from "react"
import TableOptions from "components/TableOptions"
import placeholder from "assets/Images/placeholder.jpg"
import HoverableDescription from "components/HoverableDescription"
import ColumnImage from "components/ColumnImage"

export default function segmentToRow(
  {
    _id,
    name,
    address,
    capacity,
    admins,
    opening_hours,
    open,
    closed,
    music,
    contact,
    description,
    music_type,
    dress_code,
    location,
    images,
    booked_before,
  },
  { openModal, setActiveUpdatingForm, deleteClub, users, tables }
) {
  const image = (images.length && images[0].path) || placeholder

  return {
    columns: [
      {
        component: (
          <span>
            <ColumnImage src={image} alt="" />
          </span>
        ),
      },
      {
        component: (
          <HoverableDescription>
            <strong>{name}</strong>
          </HoverableDescription>
        ),
      },
      {
        component: (
          <HoverableDescription>
            <strong>{location ? `${location.country}-${location.city}` : "-"}</strong>
          </HoverableDescription>
        ),
      },
      { component: <HoverableDescription>{address}</HoverableDescription> },
      {
        component: (
          <span>
            {capacity ||
              (() => {
                let counter = 0
                Object.keys(tables).forEach((key) => {
                  if (tables[key].club_id === _id) {
                    counter++
                  }
                })
                return counter
              })()}
          </span>
        ),
      },
      { component: <span>{booked_before ? `${booked_before} days` : "Same day booking"}</span> },
      {
        component: (
          <HoverableDescription>
            {(() => {
              const output = []
              if (admins) {
                Object.keys(users).forEach((key) => {
                  if (admins.includes(users[key]._id)) output.push(users[key].name)
                })
              }
              return output.join(", ")
            })()}
          </HoverableDescription>
        ),
      },
      {
        component: <span>{opening_hours || `${open || ""} - ${closed || ""}`}</span>,
      },
      { component: <HoverableDescription>{music_type}</HoverableDescription> },
      { component: <HoverableDescription>{dress_code}</HoverableDescription> },
      { component: <HoverableDescription>{description}</HoverableDescription> },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              setActiveUpdatingForm(_id)
              openModal()
            }}
            onDeleteOptionClick={() => {
              deleteClub(_id)
            }}
            editOptionText="Edit club"
            deleteOptionText="Delete club"
          />
        ),
      },
    ],
  }
}

import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"

import Modal from "components/Modal"
import dropdownize from "utils/dropdownize"
import PromotionForm from "views/Promotions/PromotionForm"
import Form from "components/Form"
import { createPromotionAndReload, updatePromotionAndReload } from "data/Promotions/actions"
import { PROMOTION_FORM, PROMOTION_MODAL } from "Constants"

class PromotionModal extends React.Component {
  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(PROMOTION_MODAL, activeModal)
  }

  createPromotion = (promotion) => {
    const { createPromotionAndReload, activeModal } = this.props

    const promotionData = {
      params: promotion,
      modalId: PROMOTION_MODAL,
      formId: activeModal,
    }

    createPromotionAndReload(promotionData)
  }

  updatePromotion = (promotion) => {
    const { updatePromotionAndReload, activeModal, initialValues } = this.props

    const promotionData = {
      params: promotion,
      modalId: PROMOTION_MODAL,
      formId: activeModal,
    }

    updatePromotionAndReload(promotionData)
  }

  render() {
    const { initialValues, clubs } = this.props

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={PROMOTION_FORM}
          header={initialValues ? "Update promotion" : "Create new promotion"}
          submitButtonText={initialValues ? "Update promotion" : "Create promotion"}
          initialValues={initialValues}
          onCancel={this.closeModal}
          onSubmit={initialValues ? this.updatePromotion : this.createPromotion}
        >
          <PromotionForm
            data={{
              clubsOptions: dropdownize(clubs.segments),
            }}
          />
        </Form>
      </Modal>
    )
  }
}

const mstp = ({ promotions, forms, clubs }) => {
  const { activeUpdatingForm } = forms

  return {
    clubs,
    activeModal: activeUpdatingForm,
    initialValues: activeUpdatingForm ? promotions.segments[activeUpdatingForm] : null,
  }
}

const actions = {
  closeModalAndUnsetActiveUpdatingForm,
  createPromotionAndReload,
  updatePromotionAndReload,
}

export default connect(mstp, actions)(PromotionModal)

// @flow
import React from "react"
import { connect } from "react-redux"

import TableSection from "components/TableSection/"
import Loading from "components/Loading"
import type { UserType } from "instances/User/types"
import { openModal, closeModal } from "data/Modals/actions"
import {
  setActiveUpdatingForm,
  unsetActiveUpdatingForm,
} from "data/Forms/actions"
import {
  fetchTables,
  createTableAndReload,
  deleteTableAndReload,
  updateTableAndReload,
} from "data/Tables/actions"

import styles from "./styles.module.css"
import rowsCreator from "utils/rowsCreator"
import { headers } from "./TableHeaders"
import segmentToRow from "./SegmentToRow"
import { TABLE_MODAL } from "Constants"
import Filters from "./Filters"

type PropTypes = {
  user: UserType,
  isLoading: boolean,
  fetchTables: Function,
  createTable: Function,
  updateTable: Function,
  createTableAndReload: Function,
  openModal: Function,
  closeModal: Function,
}

class Tables extends React.Component<PropTypes> {
  componentDidMount() {
    this.props.fetchTables()
  }

  render() {
    const { openModal, filters } = this.props

    return (
      <div className={styles.masterContainer}>
        <Filters />

        <Loading isLoading={this.props.tables.readLoading}>
          {(() => {
            const output = []
            Object.keys(this.props.clubs.segments).forEach(clubKey => {
              const tables = { ...this.props.tables.segments }
              Object.keys(tables).forEach(tableKey => {
                if (tables[tableKey].club_id !== clubKey)
                  delete tables[tableKey]
              })
              if (Object.keys(tables).length)
                output.push(
                  <TableSection
                    compact
                    key={clubKey}
                    headingComponent={this.props.clubs.segments[clubKey].name}
                    headers={headers}
                    rows={rowsCreator(segmentToRow, tables, {
                      openModal: id => openModal(TABLE_MODAL),
                      setActiveUpdatingForm: id =>
                        this.props.setActiveUpdatingForm(id),
                      deleteTable: id =>
                        this.props.deleteTableAndReload(id, filters),
                    })}
                  />
                )
            })
            return output
          })()}
        </Loading>
      </div>
    )
  }
}

const mstp = state => {
  const { tables, modals, clubs, forms, filters } = state

  return {
    tables,
    modals,
    clubs,
    forms,
    filters,
  }
}

export default connect(
  mstp,
  {
    openModal,
    closeModal,
    fetchTables,
    setActiveUpdatingForm,
    unsetActiveUpdatingForm,
    createTableAndReload,
    deleteTableAndReload,
    updateTableAndReload,
  }
)(Tables)

import React from "react"

export const headers = [
  { component: <span>Picture</span> },
  { component: <span>Name</span> },
  { component: <span>Location</span> },
  { component: <span>Address</span> },
  { component: <span>Table capacity</span> },
  { component: <span>Booked before</span> },
  { component: <span>Venue admins</span> },
  { component: <span>Open period</span> },
  { component: <span>Music</span> },
  { component: <span>Dresscode</span> },
  { component: <span>Description</span> },
  { component: <span /> },
]

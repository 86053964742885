import React from "react"
import { connect } from "react-redux"
import { closeModal } from "data/Modals/actions"
import { Grid } from "semantic-ui-react"
import closeIcon from "assets/Icons/close.svg"

import Modal from "components/Modal"
import { RESERVATION_DETAILS_MODAL } from "Constants"
import ReservationDetails from "components/ReservationDetails"

class ReservationDetailsModal extends React.Component {
  closeModal = () => {
    const { closeModal } = this.props

    closeModal(RESERVATION_DETAILS_MODAL)
  }

  render() {
    const { overview, filters = {} } = this.props

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Grid padded columns="equal">
          <Grid.Row>
            <Grid.Column>
              <h1 className="font-weight-400">
                Bookings
                <img
                  alt="x"
                  src={closeIcon}
                  onClick={this.closeModal}
                  className="close-icon"
                />
              </h1>
            </Grid.Column>
          </Grid.Row>
          <ReservationDetails overview={overview.segments} filters={filters} />
        </Grid>
      </Modal>
    )
  }
}

const mstp = ({ overview, filters }) => ({ overview, filters })

const actions = { closeModal }

export default connect(
  mstp,
  actions
)(ReservationDetailsModal)

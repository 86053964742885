import React from "react"
import { Image, Icon, Button } from "semantic-ui-react"

import styles from "./styles.module.css"

const ImagesPreview = ({ files = [], onChange }) => {
  function deleteImages(file) {
    onChange(files.filter(f => f !== file))
  }

  return (
    <div className={styles.preview}>
      {files.map((file, index) => {
        const filePreview = file._id ? file.path : file.preview
        if (!filePreview) return null
        return (
          <div
            key={`file-${filePreview}-${index}`}
            className={styles.imageContainer}
          >
            <Image src={filePreview} size="medium" />
            <Button
              icon
              className={styles.deleteButton}
              onClick={() => deleteImages(file)}
              size="tiny"
              type="button"
            >
              <Icon name="trash" className={styles.icon} />
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default ImagesPreview

// @flow

import { instance as axios } from "instances/Axios"
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "./constants"
import type { UsersType } from "./types"

import { fetchClubs } from "data/Clubs/actions"

type DataType = {
  params: UsersType,
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const linkUserToClub = async (
  clubId: string, // id of the club
  adminId: string // id of the club admin user
) =>
  await axios({
    url: "/clubs/admin",
    method: "post",
    data: {
      club_id: clubId,
      admin_id: adminId,
    },
  })

export const createUser = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
    promise: httpClient => httpClient.post("/admins/register", params),
    successMessage: "Successfully created new user",
    errorMessage: "Failed to create user",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export function createUserAndReload(data: DataType) {
  return async (dispatch: Function, getState: Function) => {
    const { admin, _id } = data.params
    const finalCreateType = await dispatch(createUser(data))

    if (isSuccessType(finalCreateType)) {
      if (admin) await linkUserToClub(admin, _id)
      dispatch(fetchUsers())
      dispatch(fetchClubs())
    }

    return finalCreateType
  }
}

export const updateUser = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: httpClient =>
      httpClient.put("/admins/me", { ...params, user_id: params._id }),
    successMessage: "Update user data success",
    errorMessage: "Failed to update user",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export function updateUserAndReload(data: DataType) {
  return async (dispatch: Function, getState: Function) => {
    const { admin, _id } = data.params
    const finalCreateType = await dispatch(updateUser(data))

    if (isSuccessType(finalCreateType)) {
      if (admin) await linkUserToClub(admin, _id)
      dispatch(fetchUsers())
      dispatch(fetchClubs())
    }

    return finalCreateType
  }
}

export const fetchUsers = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: httpClient =>
      httpClient.get("/admins/all", {
        fields: "name,email,phone_number",
      }),
    payloadModifier: payload => payload.admins,
  },
})

export const deleteUser = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: httpClient => httpClient.post("/delete", { user_id: id }),
    successMessage: "User is successfully deleted",
    errorMessage: "Failed to delete user",
  },
})

// Operation
export function deleteUserAndReload(id: string) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteUser(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchUsers())
    }

    return finalCreateType
  }
}

export const changePassword = (params: UsersType, form: string) => ({
  [CALL_API]: {
    types: [
      CHANGE_PASSWORD_REQUEST,
      CHANGE_PASSWORD_SUCCESS,
      CHANGE_PASSWORD_FAILURE,
    ],
    promise: httpClient => httpClient.put("/users/password", params),
    successMessage: "Password is successfully changed",
    errorMessage: "Failed to change password",
    formResetName: form,
  },
})

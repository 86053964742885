// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"
import { LocationType } from "./types"

type DataType = {
  params: LocationType,
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = (action) => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const createLocation = ({ params, form, modalId, formId }: DataType) => {
  return {
    [CALL_API]: {
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
      promise: (httpClient) => httpClient.post("/locations", params),
      successMessage: "Successfully created new location",
      errorMessage: "Failed to create new location",
      formResetName: form,
      closeModalId: modalId,
      resetActiveFormId: formId,
    },
  }
}

// Operation
export function createLocationAndReload(data: DataType) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createLocation(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchLocations())
    }

    return finalCreateType
  }
}

export const fetchLocations = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: (httpClient) => httpClient.get("/locations"),
    payloadModifier: (payload) => (Array.isArray(payload) ? payload : [payload]),
  },
})

export const updateLocation = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: (httpClient) => httpClient.put("/locations", { ...params, location_id: params._id }),
    successMessage: "Successfully updated location data",
    errorMessage: "Failed to update location",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export function updateLocationAndReload(data: DataType) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(updateLocation(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchLocations())
    }

    return finalCreateType
  }
}

export const deleteLocation = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: (httpClient) => httpClient.delete("/locations", { params: { id } }),
    successMessage: "Location is successfully deleted",
    errorMessage: "Failed to delete location",
  },
})

// Operation
export function deleteLocationAndReload(id: string) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteLocation(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchLocations())
    }

    return finalCreateType
  }
}

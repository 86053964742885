import React, { Component } from "react"
import Button from "components/Button"
import { instance as axios } from "instances/Axios"

export default class FileExport extends Component {
  state = {
    ref: React.createRef(),
    downloading: false,
  }

  fileExport = () => {
    const { id } = this.props
    this.setState({ downloading: true })
    axios({
      url: "/bookings/download",
      method: "GET",
      params: { id },
      responseType: "blob",
    }).then(response => {
      if (response) {
        const disposition = response.headers["content-disposition"]
        const index = disposition.indexOf("\"")
        if (index === -1) return
        const fileName = disposition.slice(index + 1, disposition.length - 1)

        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        })
        const { ref } = this.state
        ref.current.href = URL.createObjectURL(blob)
        ref.current.download = fileName
        ref.current.click()
      }
      this.setState({ downloading: false })
    })
  }

  render() {
    const { downloading } = this.state
    return (
      <span>
        <a style={{ display: "none" }} href="empty" ref={this.state.ref}>
          ref
        </a>
        <Button
          style={{ marginRight: "1em" }}
          type="button"
          onClick={this.fileExport}
          disabled={downloading}
        >
          {this.props.text}
        </Button>
      </span>
    )
  }
}

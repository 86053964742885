import React from "react"
import { Checkbox } from "semantic-ui-react"
import { debounce } from "lodash"

export default class ConfirmReservation extends React.Component {
  state = {
    checked: !!this.props.confirmed,
    fetching: false,
  }

  confirmBooking = debounce(async checked => {
    const { id, updateBooking } = this.props
    const confirmBooking = await updateBooking({
      _id: id,
      confirmed: checked,
    })
    if (confirmBooking && confirmBooking.type.includes("FAILURE")) {
      this.setState(state => ({ checked: !state.checked }))
    }
    this.setState({ fetching: false })
  })

  onChange = (e, { value }) => {
    let checked

    this.setState(state => {
      checked = !state.checked
      if (!state.fetching) {
        this.confirmBooking(checked)
        return { checked, fetching: true }
      }
    })
  }

  render() {
    return <Checkbox checked={this.state.checked} onChange={this.onChange} />
  }
}

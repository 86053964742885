// @flow
import React from "react"
import { Link } from "react-router-dom"
import menuIcon from "assets/Icons/menu.svg"
import closeIcon from "assets/Icons/close.svg"
import { debounce } from "lodash"

import Logo from "components/Logo"
import HeaderUserMenu from "components/HeaderUserMenu"

import styles from "./styles.module.css"

type LinkType = {
  name?: string,
  text: string,
  uri: string,
}

type HeaderPropTypes = {
  path: string,
  links: Array<LinkType>,
}

type HeaderStateType = {
  scrollDirection: string,
  scrolledFarEnough: boolean,
  isMenuOpen: boolean,
}

type NavigationItemPropTypes = {
  ...LinkType,
  path: string,
}

class Header extends React.Component<HeaderPropTypes, HeaderStateType> {
  lastY: number

  lastY = 0
  state = {
    scrollDirection: "down",
    scrolledFarEnough: false,
    isMenuOpen: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = debounce((event: SyntheticEvent<*>) => {
    const Y = window.pageYOffset
    const { lastY } = this
    if (Y > lastY && this.state.scrollDirection === "up") {
      this.setState({ scrollDirection: "down" })
    }
    if (Y < lastY && this.state.scrollDirection === "down") {
      this.setState({ scrollDirection: "up" })
    }
    if (Y >= 60) this.setState({ scrolledFarEnough: true })
    else this.setState({ scrolledFarEnough: false })
    this.lastY = Y
  }, 200)

  openMenu() {
    this.setState({ isMenuOpen: true })
  }

  closeMenu() {
    this.setState({ isMenuOpen: false })
  }

  render() {
    const { path, links } = this.props
    const { scrollDirection, scrolledFarEnough, isMenuOpen } = this.state
    return (
      <React.Fragment>
        <MobileMenu
          links={links}
          isOpen={isMenuOpen}
          closeMenu={() => this.closeMenu()}
        />
        <header
          onClick={() => this.openMenu()}
          className={`${styles.mobileContainer} ${
            scrolledFarEnough ? styles.visibleContainer : ""
          }`}
        >
          <img src={menuIcon} alt={"Menu"} />
        </header>
        <header
          className={`${styles.masterContainer} ${
            scrolledFarEnough && scrollDirection === "down" ? styles.hidden : ""
          }`}
        >
          <Logo />
          <div className={styles.navigationContainer}>
            {links.map(({ text, name, uri }, i) => (
              <NavigationItem
                key={i}
                path={path}
                text={text}
                name={name}
                uri={uri}
              />
            ))}
          </div>
          <HeaderUserMenu />
        </header>
      </React.Fragment>
    )
  }
}

const NavigationItem = ({ path, text, name, uri }: NavigationItemPropTypes) => (
  <React.Fragment>
    <Link name={name} to={uri}>
      <div
        className={`${styles.navigationLinkContainer} ${
          uri === path ? styles.activeLinkContainer : ""
        }`}
      >
        {text}
      </div>
    </Link>
  </React.Fragment>
)

const MobileMenu = ({ closeMenu, isOpen, links }) => (
  <div
    className={`${styles.mobileMenu} ${
      isOpen ? styles.menuOpen : styles.menuClosed
    }`}
  >
    <img
      src={closeIcon}
      alt=">"
      className={styles.closeIcon}
      onClick={closeMenu}
    />
    <div>
      {links.map(({ text, uri, name }, i) => (
        <Link name={name} to={uri} key={i}>
          <div>{text}</div>
        </Link>
      ))}
    </div>
  </div>
)

export default Header

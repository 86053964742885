import React from "react"
import { Field } from "redux-form"
import { Grid, Input } from "semantic-ui-react"
import {
  InputField,
  SelectField,
  TextAreaField,
} from "react-semantic-redux-form"
import { required } from "wrappers/FormValidation/validations"
import renderDateField from "components/DateField/renderDateField"
import ReservationFormHeader from "components/ReservationFormHeader"

class ReservationForm extends React.Component {
  state = {
    tables: this.props.data.tablesOptions,
    tablesByClub: this.props.data.tablesOptions,
    arrivalTime: this.props.initialValues.arrival_time || "",
    enableTables: !!this.props.initialValues.club_id,
    clubId: this.props.initialValues.club_id,
  }

  handleVenueChange = (param, nextClubId) => {
    if (nextClubId === this.state.clubId) return
    const tablesByClub = this.state.tables.filter(
      table => table.club_id === nextClubId
    )

    this.setState({
      tablesByClub,
      enableTables: true,
      arrivalTime: "",
      clubId: nextClubId,
    })
  }

  handleTableChange = (param, nextTableId) => {
    const table = this.props.data.tables[nextTableId]
    if (table) this.setState({ arrivalTime: table.arrival_time })
  }

  render() {
    const { initialValues = {} } = this.props
    const { _id, table_name, minimum_spend, placement } = initialValues

    return (
      <React.Fragment>
        {_id && (
          <ReservationFormHeader
            tableName={table_name}
            minSpend={minimum_spend}
            placement={placement}
          />
        )}
        <Grid.Row>
          <Grid.Column>
            <label>Date</label>
            <Field
              name="date"
              component={renderDateField}
              validate={[required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Booked for</label>
            <Field
              name="name"
              component={InputField}
              type="text"
              placeholder="Enter name"
              validate={[required]}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <label>Booked by</label>
            <Field
              name="booked_by"
              component={InputField}
              type="text"
              placeholder="Enter name"
              validate={[required]}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Venue</label>
            <Field
              name="club_id"
              component={SelectField}
              options={this.props.data.clubsOptions}
              validate={[required]}
              placeholder="Select Club"
              onChange={this.handleVenueChange}
            />
          </Grid.Column>
          <Grid.Column>
            <label>Table</label>
            <Field
              name="table_id"
              disabled={!this.state.enableTables}
              component={SelectField}
              options={this.state.tablesByClub}
              validate={[required]}
              placeholder="Select table"
              onChange={this.handleTableChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Arrival time (latest time guests can show up)</label>
            <Input value={this.state.arrivalTime} disabled fluid />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Contact information</label>
            <Field
              name="contact"
              component={InputField}
              type="text"
              placeholder="Enter contact info"
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label>Comments</label>
            <Field
              name="comments"
              autoHeight
              component={TextAreaField}
              type="text"
            />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    )
  }
}

export default ReservationForm

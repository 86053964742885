// @flow
import React from "react"
import TableOptions from "components/TableOptions"
import placeholder from "assets/Images/placeholder.jpg"
import HoverableDescription from "components/HoverableDescription"
import ColumnImage from "components/ColumnImage"
import { deleteLocation } from "../../data/Locations/actions"

export default function segmentToRow(
  { _id, country, city },
  { openModal, setActiveUpdatingForm, deleteLocation }
) {
  return {
    columns: [
      {
        component: (
          <HoverableDescription>
            <strong>{country}</strong>
          </HoverableDescription>
        ),
      },
      { component: <HoverableDescription>{city}</HoverableDescription> },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              setActiveUpdatingForm(_id)
              openModal()
            }}
            onDeleteOptionClick={() => {
              deleteLocation(_id)
            }}
            editOptionText="Edit location"
            deleteOptionText="Delete location"
          />
        ),
      },
    ],
  }
}

// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"
import { PromotionType } from "./types"

type DataType = {
  params: PromotionType,
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = (action) => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const createPromotion = ({ params, form, modalId, formId }: DataType) => {
  return {
    [CALL_API]: {
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
      promise: (httpClient) => httpClient.post("/promotions", params),
      successMessage: "Successfully created new promotion",
      errorMessage: "Failed to create new promotion (Name might be duplicate with the active promotion code)",
      formResetName: form,
      closeModalId: modalId,
      resetActiveFormId: formId,
    },
  }
}

// Operation
export function createPromotionAndReload(data: DataType) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createPromotion(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchPromotions())
    }

    return finalCreateType
  }
}

export const fetchPromotions = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: (httpClient) => httpClient.get("/promotions"),
    payloadModifier: (payload) => (Array.isArray(payload) ? payload : [payload]),
  },
})

export const updatePromotion = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: (httpClient) => httpClient.put("/promotions", { ...params, promotion_id: params._id }),
    successMessage: "Successfully updated promotion data",
    errorMessage: "Failed to update promotion (Name might be duplicate with the active promotion code)",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export function updatePromotionAndReload(data: DataType) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(updatePromotion(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchPromotions())
    }

    return finalCreateType
  }
}

export const deletePromotion = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: (httpClient) => httpClient.delete("/promotions", { params: { id } }),
    successMessage: "Promotion is successfully deleted",
    errorMessage: "Failed to delete promotion",
  },
})

// Operation
export function deletePromotionAndReload(id: string) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deletePromotion(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchPromotions())
    }

    return finalCreateType
  }
}

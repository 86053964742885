// @flow
import React from "react"

import styles from "./styles.module.css"

type PropTypes = {
  title: string,
  subtitle?: string,
  children: any,
}

const TopPanel = ({ title, subtitle, children }: PropTypes) => (
  <div className={styles.titlesContainer}>
    <h1>{title}</h1>
    {subtitle ? <h2>{subtitle}</h2> : <h2>&nbsp;</h2>}
    {children}
  </div>
)

export default TopPanel

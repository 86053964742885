import React from "react"
import moment from "moment"
import TopPanel from "components/TopPanel"
import { TotalRow } from "./TotalRow"
import { DetailRow } from "./DetailRow"
import { NoBookings } from "./NoBookings"

function formatDate(date) {
  return moment(date).format("MMM D")
}

export const ReservationDetails = ({ overview = {}, filters = {} }) => {
  const { date_from, date_to } = filters
  let totalSpend = 0
  let tableSpend = 0
  let totalBookings = 0
  const date = `${formatDate(date_from)} - ${formatDate(date_to)}`

  if (!Object.keys(overview).length) return <NoBookings />

  return Object.values(overview).map((club) => {
    totalSpend = 0
    totalBookings = 0
    return (
      <React.Fragment key={`club-${club._id}`}>
        <TopPanel title={club.name} subtitle={date} />
        <ul>
          {club.dates
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((date, index) => {
              const { date: currentDate, tables: currentTables } = date
              tableSpend = 0
              const tables = currentTables.filter((table) => {
                if (table.booking && table.booking._id) {
                  tableSpend += table.booking.minimum_spend || table.minimum_spend
                  return table
                }
                return null
              })
              if (!tables.length) return null
              totalSpend += tableSpend
              totalBookings += tables.length

              return (
                <DetailRow
                  key={`club-${club._id}-date-${currentDate}`}
                  date={moment(currentDate).format("MMM D, YYYY")}
                  bookings={tables.length}
                  spend={tableSpend}
                />
              )
            })}
        </ul>
        {totalBookings ? <TotalRow totalSpend={totalSpend} totalBookings={totalBookings} /> : <NoBookings />}
      </React.Fragment>
    )
  })
}

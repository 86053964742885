// @flow
import React from "react"
import { Icon, Menu, Button } from "semantic-ui-react"
import PopupMenu from "components/PopupMenu"
import styles from "./styles.module.css"

type PropTypes = {
  onEditOptionClick: Function,
  onDeleteOptionClick: Function,
  editOptionText: string,
  deleteOptionText: string,
}

const TableOptions = ({
  onEditOptionClick,
  onDeleteOptionClick,
  editOptionText,
  deleteOptionText,
}: PropTypes) => {
  const options = []
  if (editOptionText)
    options.push({
      component: (
        <Menu.Item onClick={onEditOptionClick}>{editOptionText}</Menu.Item>
      ),
    })
  if (deleteOptionText)
    options.push({ text: deleteOptionText, onClick: onDeleteOptionClick })
  return (
    <React.Fragment>
      <span className="no-mobile">
        <PopupMenu
          trigger={onClick => (
            <Icon
              onClick={onClick}
              style={{ cursor: "pointer" }}
              size="large"
              name="ellipsis horizontal"
            />
          )}
          options={options}
        />
      </span>
      <div className={`only-mobile ${styles.optionsRow}`}>
        {editOptionText && (
          <Button
            onClick={onEditOptionClick}
            size="big"
            basic
            color="pink"
            fluid
            content={editOptionText}
          />
        )}
        {deleteOptionText && (
          <Button
            onClick={onDeleteOptionClick}
            size="big"
            basic
            color="pink"
            // fluid
            icon="trash"
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default TableOptions

import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"

import Modal from "components/Modal"
import TableForm from "views/Tables/TableForm"
import { TABLE_FORM, TABLE_MODAL } from "Constants"
import Form from "components/Form"
import { createTableAndReload, updateTableAndReload } from "data/Tables/actions"
import dropdownize from "utils/dropdownize"

class TableModal extends React.Component {
  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(TABLE_MODAL, activeModal)
  }

  createTable = (table) => {
    const { createTableAndReload, activeModal, filters } = this.props

    const paramsTable = table

    if (paramsTable.override_prices) {
      paramsTable.override_prices = table.override_prices.reduce((result, price) => {
        if (price.date && price.minimum_spend) {
          result.push(price)
        }
        return result
      }, [])
    }

    createTableAndReload(
      {
        params: table,
        modalId: TABLE_MODAL,
        formId: activeModal,
      },
      filters
    )
  }

  updateTable = (table) => {
    const { updateTableAndReload, activeModal, filters } = this.props

    const paramsTable = table

    if (paramsTable.override_prices) {
      paramsTable.override_prices = table.override_prices.reduce((result, price) => {
        if (price.date && price.minimum_spend) {
          result.push(price)
        }
        return result
      }, [])
    }

    updateTableAndReload(
      {
        params: paramsTable,
        modalId: TABLE_MODAL,
        formId: activeModal,
      },
      filters
    )
  }

  render() {
    const { initialValues, clubs } = this.props

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={TABLE_FORM}
          header={initialValues ? "Update table" : "Create table"}
          submitButtonText={initialValues ? "Update table" : "Create table"}
          initialValues={initialValues}
          onCancel={this.closeModal}
          onSubmit={initialValues ? this.updateTable : this.createTable}
        >
          <TableForm
            data={{
              clubsOptions: dropdownize(clubs.segments),
            }}
          />
        </Form>
      </Modal>
    )
  }
}

const mstp = (state) => {
  const { tables, clubs, forms, filters } = state
  const { activeUpdatingForm } = forms

  return {
    clubs,
    filters,
    activeModal: activeUpdatingForm,
    initialValues: activeUpdatingForm ? tables.segments[activeUpdatingForm] : null,
  }
}

const actions = {
  closeModalAndUnsetActiveUpdatingForm,
  createTableAndReload,
  updateTableAndReload,
}

export default connect(mstp, actions)(TableModal)

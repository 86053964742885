// @flow
import * as React from "react"
import { Popup, Menu } from "semantic-ui-react"

type PopupMenuItemType = {
  text?: string,
  component?: React.ComponentType<*>,
  onClick: Function,
}

type PopupMenuPropTypes = {
  options: Array<PopupMenuItemType>,
  trigger: Function,
  popupProps?: any,
  menuProps?: any,
  onItemClick?: Function,
}

type StateTypes = {
  isOpen: boolean,
}

class PopupMenu extends React.PureComponent<PopupMenuPropTypes, StateTypes> {
  state = { isOpen: false }

  openPopup() {
    this.setState({ isOpen: true })
  }

  closePopup(e) {
    this.setState({ isOpen: false })
  }

  render() {
    const { options, trigger, popupProps, menuProps, onItemClick } = this.props
    const additionalStyles = (popupProps && popupProps.style) || {}
    return (
      <Popup
        open={this.state.isOpen}
        onClose={e => this.closePopup(e)}
        hideOnScroll
        on={"click"}
        trigger={trigger(() => this.openPopup())}
        content={
          <Menu
            secondary
            fluid
            vertical
            style={{ margin: 0, padding: 0 }}
            {...menuProps}
          >
            {options.map(
              ({ text, component, onClick }, i) =>
                component ? (
                  <React.Fragment key={i}>{component}</React.Fragment>
                ) : (
                  text && (
                    <Menu.Item
                      style={{
                        margin: 0,
                        minWidth: 100,
                      }}
                      onClick={(...args) => {
                        onItemClick && onItemClick(i)
                        onClick && onClick(...args)
                      }}
                      key={i}
                    >
                      {text}
                    </Menu.Item>
                  )
                )
            )}
          </Menu>
        }
        {...popupProps}
        style={{ padding: 0, ...additionalStyles }}
      />
    )
  }
}

export default PopupMenu

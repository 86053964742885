import {
  DEALS,
  CLUB_SELECTED,
  DEAL_SELECTED,
  OVERRIDE_TABLE_PRICES_MODIFICATION,
  CATEGORY_SELECTED,
} from "./constants"

import { commonReducerState, commonReducerActionHandlers } from "utils/commonReducerUtils"

const DealsReducer = (
  state = {
    ...commonReducerState,
    selectedClub: null,
    selectedCategory: null,
    selectedDeal: null,
    dealsWithOverridenTablePrices: {},
  },
  { type, payload }
) => {
  return commonReducerActionHandlers(
    {
      state,
      type,
      payload,
    },
    DEALS,
    () => {
      switch (type) {
        case CLUB_SELECTED:
          return {
            ...state,
            selectedClub: payload,
          }
        case CATEGORY_SELECTED:
          return {
            ...state,
            selectedCategory: payload,
          }
        case DEAL_SELECTED:
          return {
            ...state,
            selectedDeal: payload,
          }
        case OVERRIDE_TABLE_PRICES_MODIFICATION:
          const dealsWithOverridenTablePrices = {
            ...state.dealsWithOverridenTablePrices,
          }
          if (!payload.value) delete dealsWithOverridenTablePrices[payload.id]
          else dealsWithOverridenTablePrices[payload.id] = payload.value
          return {
            ...state,
            dealsWithOverridenTablePrices,
          }
        default:
          return state
      }
    }
  )
}
export default DealsReducer

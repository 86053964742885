import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Dropdown } from "semantic-ui-react"

import { updateFilters } from "data/Filters/actions"
import dropdownize from "utils/dropdownize"

class ClubFilter extends React.Component {
  onChange = (e, { name, value }) => {
    const { filters, updateFilters, applyFilters } = this.props
    if (filters[name] === value || (!filters[name] && value === "all")) return
    filters[name] = value === "all" ? "" : value
    updateFilters(filters)
    applyFilters(filters)
  }

  render() {
    return (
      <Dropdown
        name="club"
        value={this.props.filters.club || "all"}
        options={this.props.clubs}
        onChange={this.onChange}
      />
    )
  }
}

const mstp = ({ clubs, filters = {} }) => ({
  clubs: [
    {
      key: "all_clubs",
      value: "all",
      text: "All Clubs",
    },
    ...dropdownize(clubs.segments),
  ],
  filters,
  club: filters.club,
})

const mdtp = dispatch => ({
  updateFilters: bindActionCreators(updateFilters, dispatch),
})

export default connect(mstp, mdtp)(ClubFilter)

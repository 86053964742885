// @flow
import * as React from "react"
import { compose } from "redux"
import { Route, Switch, Redirect } from "react-router-dom"

import Login from "views/Login"

import SuperAdminRegister from "views/SuperAdminRegister"
import Clubs from "views/Clubs"
import Customers from "views/Customers"
import Locations from "views/Locations"
import Events from "views/Events"
import Deals from "views/Deals"
import Overview from "views/Overview"
import Settings from "views/Settings"
import Tables from "views/Tables"
import Users from "views/Users"
import ForgotPassword from "views/ForgotPassword"
import BookedDeals from "views/BookedDeals"

import Page404 from "views/Page404"

import ClubAdminRegister from "views/ClubAdminRegister"

import withContainer from "wrappers/Container"
import withLayout from "wrappers/Layout"
import withTitle from "wrappers/Title"
import StatelessHeader from "components/Header"
import { SUPERADMIN, CLUBADMIN } from "instances/User/constants"

import withProtection from "wrappers/Protection"
import withNecessaryCommonData from "wrappers/NecessaryCommonData"
import withHeaderNavigation from "wrappers/HeaderNavigation"
import Promotions from "../../views/Promotions"

const Header = withHeaderNavigation(StatelessHeader)

const REGISTER = "register"
const UNAUTHORIZED = "unauthorized"

export default function Routes(props: {}) {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={"/"} render={() => <Redirect to={"/login"} />} />
        <Route path={"/login"} component={withProtection([UNAUTHORIZED])(Login)} />

        <Route path="/forgot-password" component={compose(withProtection([UNAUTHORIZED]))(ForgotPassword)} />

        <Route
          path={"/super-admin/register"}
          component={compose(withProtection([REGISTER]), withTitle("Register"))(SuperAdminRegister)}
        />
        <Route
          path={"/club-admin/register"}
          component={compose(withProtection([REGISTER]), withTitle("Register"))(ClubAdminRegister)}
        />

        <Route
          path={"/overview"}
          component={compose(
            withProtection([SUPERADMIN, CLUBADMIN]),
            withTitle("Overview"),
            withLayout({ Header }),
            withNecessaryCommonData(["clubs", "tables"]),
            withContainer
          )(Overview)}
        />

        <Route
          path={"/booked-deals"}
          component={compose(
            withProtection([SUPERADMIN, CLUBADMIN]),
            withTitle("Booked Deals"),
            withLayout({ Header }),
            withNecessaryCommonData(["clubs", "deals"]),
            withContainer
          )(BookedDeals)}
        />

        <Route
          path={"/tables"}
          component={compose(
            withProtection([SUPERADMIN, CLUBADMIN]),
            withTitle("Tables"),
            withLayout({ Header }),
            withNecessaryCommonData(["clubs"]),
            withContainer
          )(Tables)}
        />
        <Route
          path={"/clubs"}
          component={compose(
            withProtection([SUPERADMIN]),
            withTitle("Venues"),
            withLayout({ Header }),
            withNecessaryCommonData(["users", "tables", "locations"]),
            withContainer
          )(Clubs)}
        />
        <Route
          path={"/events"}
          component={compose(
            withProtection([SUPERADMIN, CLUBADMIN]),
            withTitle("Events"),
            withLayout({ Header }),
            withNecessaryCommonData(["clubs", "tables"]),
            withContainer
          )(Events)}
        />
        <Route
          path={"/deals"}
          component={compose(
            withProtection([SUPERADMIN, CLUBADMIN]),
            withTitle("Deals"),
            withLayout({ Header }),
            withNecessaryCommonData(["clubs", "tables", "categories"]),
            withContainer
          )(Deals)}
        />
        <Route
          path={"/users"}
          component={compose(
            withProtection([SUPERADMIN]),
            withTitle("Users"),
            withLayout({ Header }),
            withNecessaryCommonData(["clubs"]),
            withContainer
          )(Users)}
        />
        <Route
          path={"/customers"}
          component={compose(
            withProtection([SUPERADMIN]),
            withTitle("Customers"),
            withLayout({ Header }),
            withContainer
          )(Customers)}
        />
        <Route
          path={"/locations"}
          component={compose(
            withProtection([SUPERADMIN]),
            withTitle("Locations"),
            withLayout({ Header }),
            withContainer
          )(Locations)}
        />
        <Route
          path={"/promotions"}
          component={compose(
            withProtection([SUPERADMIN]),
            withTitle("Promotions"),
            withLayout({ Header }),
            withNecessaryCommonData(["clubs"]),
            withContainer
          )(Promotions)}
        />
        <Route
          path={"/settings"}
          component={compose(
            withProtection([SUPERADMIN, CLUBADMIN]),
            withTitle("Settings"),
            withLayout({ Header }),
            withContainer
          )(Settings)}
        />

        <Route component={Page404} />
      </Switch>
    </React.Fragment>
  )
}

import {
  EVENTS,
  CLUB_SELECTED,
  EVENT_SELECTED,
  OVERRIDE_TABLE_PRICES_MODIFICATION,
} from "./constants"

import {
  commonReducerState,
  commonReducerActionHandlers,
} from "utils/commonReducerUtils"

const EventsReducer = (
  state = {
    ...commonReducerState,
    selectedClub: null,
    selectedEvent: null,
    eventsWithOverridenTablePrices: {},
  },
  { type, payload }
) => {
  return commonReducerActionHandlers(
    {
      state,
      type,
      payload,
    },
    EVENTS,
    () => {
      switch (type) {
        case CLUB_SELECTED:
          return {
            ...state,
            selectedClub: payload,
          }
        case EVENT_SELECTED:
          return {
            ...state,
            selectedEvent: payload,
          }
        case OVERRIDE_TABLE_PRICES_MODIFICATION:
          const eventsWithOverridenTablePrices = {
            ...state.eventsWithOverridenTablePrices,
          }
          if (!payload.value) delete eventsWithOverridenTablePrices[payload.id]
          else eventsWithOverridenTablePrices[payload.id] = payload.value
          return {
            ...state,
            eventsWithOverridenTablePrices,
          }
        default:
          return state
      }
    }
  )
}
export default EventsReducer

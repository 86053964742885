import React from "react"
import TableOptions from "components/TableOptions"
import TableRowLabel from "components/TableRowLabel"
import AttachmentButton from "components/AttachmentButton"
import AccessControl from "components/AccessControl"

import ConfirmReservation from "components/ConfirmReservation"
import TransactionStatus from "components/TransactionStatus"
import { getTransactionStatus } from "components/TransactionStatus/Options"
import HoverableDescription from "components/HoverableDescription"
import { SUPERADMIN } from "instances/User/constants"

export default function segmentToRow(
  { name, placement, capacity, minimum_spend, arrival_time, spotlight, booking = {} },
  { setActiveUpdatingForm, deleteBooking, openModal, updateBooking, date, role }
) {
  const {
    _id,
    contact,
    confirmed,
    booked_by,
    transaction_status,
    attachment,
    comments,
    name: booked_for,
    booking_number,
    minimum_spend: booking_minimum_spend,
  } = booking

  const isReservation = booking && _id
  const formattedBookingNumber =
    booking_number && String(booking_number).replace(/^(\d{3})(\d{4})(\d+)$/, "$1-$2-$3")

  const row = {
    columns: [
      { component: <span>{formattedBookingNumber}</span> },
      { component: <HoverableDescription>{name}</HoverableDescription> },
      { component: <HoverableDescription>{placement}</HoverableDescription> },
      { component: <span>{capacity}</span> },
      { component: <span>{booking_minimum_spend || minimum_spend}</span> },
      {
        component: (
          <AccessControl
            renderSuperAdmin={<span>{booked_by || "-"}</span>}
            renderAdmin={<span>{booked_for || "-"}</span>}
          />
        ),
      },
      { component: <span>{arrival_time}</span> },
      { component: <span>{contact || "-"}</span> },
      {
        component: isReservation ? (
          <AccessControl
            renderSuperAdmin={<ConfirmReservation id={_id} confirmed={confirmed} updateBooking={updateBooking} />}
            renderAdmin={<span>{confirmed ? "Yes" : "No"}</span>}
          />
        ) : (
          <span>-</span>
        ),
      },
      {
        component: isReservation ? (
          <AccessControl
            renderSuperAdmin={
              <TransactionStatus id={_id} transactionStatus={transaction_status} updateBooking={updateBooking} />
            }
            renderAdmin={
              <span>{transaction_status ? getTransactionStatus(transaction_status) : "No payment yet"}</span>
            }
          />
        ) : (
          <span>-</span>
        ),
      },
      {
        component: <HoverableDescription>{comments || "-"}</HoverableDescription>,
      },
      {
        component: _id ? <AttachmentButton id={_id} attachment={attachment} /> : "-",
      },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              setActiveUpdatingForm(_id)
              openModal()
            }}
            onDeleteOptionClick={() => {
              if (isReservation) deleteBooking(_id)
            }}
            editOptionText={isReservation ? "Edit reservation" : "Add reservation"}
            deleteOptionText={isReservation ? "Delete reservation" : ""}
          />
        ),
      },
    ],
    label: isReservation
      ? {
          component: <TableRowLabel icon="checkmark" />,
        }
      : null,
  }

  if (role !== SUPERADMIN) {
    // for clubadmin remove transaction_status - index 8 & contact - index 6
    row.columns.splice(8, 1)
    row.columns.splice(6, 1)
  }

  return row
}

import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { fetchBookings } from "data/Overview/actions"
import { openModal } from "data/Modals/actions"
import { updateFilters } from "data/Filters/actions"

import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import AccessControl from "components/AccessControl"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import TransactionStatusFilter from "components/TransactionStatusFilter"
import DateRangeFilter from "components/DateRangeFilter"
import TableFilter from "components/TableFilter"
import ClubFilter from "components/ClubFilter"
import { RESERVATION_MODAL } from "Constants"

import { Grid } from "semantic-ui-react"

class Filters extends React.Component {
  componentDidMount() {
    const dateFrom = moment()
    const filters = {
      date_from: dateFrom.format("YYYY-MM-DD"),
      date_to: moment(dateFrom)
        .add(15, "days")
        .format("YYYY-MM-DD"),
      transaction_status: "",
      club: "",
      table: "",
    }
    this.props.updateFilters(filters)
    this.applyFilters(filters)
  }

  componentWillUnmount() {
    this.props.updateFilters()
  }

  applyFilters = currentFilters => {
    const filters = currentFilters || this.props.filters
    delete filters.table
    this.props.fetchBookings({
      params: filters,
    })
  }

  render() {
    return (
      <React.Fragment>
        <TopPanel title="Reservation Overview">
          <Grid columns={5} stackable>
            <AccessControl
              renderSuperAdmin={
                <FilterContainer>
                  <FilterItem className="fullWidth">
                    <TransactionStatusFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem className="mobileBreakpoint" />
                  <FilterItem>
                    <ClubFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <TableFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem className="mobileBreakpoint" />
                  <FilterItem>
                    <DateRangeFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <ModalTriggerButton
                      onClick={() => this.props.openModal(RESERVATION_MODAL)}
                      content="Add reservation"
                    />
                  </FilterItem>
                </FilterContainer>
              }
              renderAdmin={
                <FilterContainer>
                  <FilterItem>
                    <TableFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <DateRangeFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <ModalTriggerButton
                      onClick={() => this.props.openModal(RESERVATION_MODAL)}
                      content="Add reservation"
                    />
                  </FilterItem>
                </FilterContainer>
              }
            />
          </Grid>
        </TopPanel>
      </React.Fragment>
    )
  }
}

const mstp = ({ modals, tables, filters, clubs }) => ({
  modals,
  tables,
  filters,
  clubs,
  club: clubs.segments[filters.club],
})

const mdtp = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  updateFilters: bindActionCreators(updateFilters, dispatch),
  fetchBookings: bindActionCreators(fetchBookings, dispatch),
})

export default connect(
  mstp,
  mdtp
)(Filters)

// @flow
import * as React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"

import {
  fetchBookings,
  createBookingAndReload,
  updateBooking,
  deleteBookingAndReload,
} from "data/Overview/actions"
import { openModal } from "data/Modals/actions"
import { setActiveUpdatingForm } from "data/Forms/actions"

import Loading from "components/Loading"

import rowsCreator from "utils/rowsCreator"
import segmentToRow from "./SegmentToRow"

import TableSection from "components/TableSection"
import TableLabel from "components/TableLabel"
import Filters from "./Filters"
import Footer from "./Footer"
import { headers } from "./OverviewTableHeaders"

import { SUPERADMIN } from "instances/User/constants"
import { RESERVATION_MODAL } from "Constants"
import styles from "./styles.module.css"

import type { UserType } from "instances/User/types"

type PropTypes = {
  user: UserType,
}

class Overview extends React.Component<PropTypes> {
  render() {
    const {
      overview,
      user,
      filters,
      openModal,
      setActiveUpdatingForm,
      deleteBookingAndReload,
      updateBooking,
    } = this.props
    const { segments: overviewData, readLoading } = overview
    const { role } = user
    const isSuperAdmin = role === SUPERADMIN

    return (
      <div className={styles.masterContainer}>
        <Filters />
        <Loading isLoading={readLoading}>
          {Object.values(overviewData).map(club => (
            <React.Fragment key={`club-${club._id}`}>
              <h3>{club.name}</h3>
              {club.dates
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((date, index) => {
                  const { date: currentDate, tables } = date
                  return (
                    <React.Fragment key={`club-${club._id}-${currentDate}`}>
                      <TableLabel>{moment(currentDate).format("D MMMM YYYY")}</TableLabel>
                      <TableSection
                        key={`club-table-${club._id}-${currentDate}`}
                        headers={headers(isSuperAdmin)}
                        rows={rowsCreator(segmentToRow, tables, {
                          openModal: which => openModal(RESERVATION_MODAL),
                          setActiveUpdatingForm: id => setActiveUpdatingForm(id),
                          deleteBooking: id => deleteBookingAndReload(id, filters),
                          updateBooking: params => updateBooking({ params }),
                          currentDate,
                          role,
                        })}
                      />
                    </React.Fragment>
                  )
                })}
            </React.Fragment>
          ))}
        </Loading>
        <Footer />
      </div>
    )
  }
}

const mstp = ({ overview, modals, forms, clubs, tables, filters, user }) => ({
  overview,
  modals,
  forms,
  clubs,
  filters,
  user,
})

const mdtp = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  fetchBookings: bindActionCreators(fetchBookings, dispatch),
  createBookingAndReload: bindActionCreators(createBookingAndReload, dispatch),
  updateBooking: bindActionCreators(updateBooking, dispatch),
  deleteBookingAndReload: bindActionCreators(deleteBookingAndReload, dispatch),
  setActiveUpdatingForm: bindActionCreators(setActiveUpdatingForm, dispatch),
})

export default connect(mstp, mdtp)(Overview)

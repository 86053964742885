import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Dropdown } from "semantic-ui-react"
import { updateFilters } from "data/Filters/actions"
import { tableOptions } from "./Options"

class TableFilter extends React.Component {
  onChange = (e, { name, value }) => {
    const { filters, updateFilters, applyFilters } = this.props
    if (filters[value] || (name !== "table" && value === "all")) return
    tableOptions.forEach(option => {
      if (option.value === value) {
        filters[value] = true
      } else {
        delete filters[option.value]
      }
    })
    updateFilters(filters)
    delete filters.table
    applyFilters(filters)
  }
  render() {
    return (
      <Dropdown
        name="table"
        value={this.props.table}
        options={tableOptions}
        onChange={this.onChange}
      />
    )
  }
}

const mstp = ({ filters = {} }) => ({
  filters,
  table: filters.available ? "available" : filters.booked ? "booked" : "all",
})

const mdtp = dispatch => ({
  updateFilters: bindActionCreators(updateFilters, dispatch),
})

export default connect(
  mstp,
  mdtp
)(TableFilter)

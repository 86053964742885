// @flow
import React from "react"
import { Statistic } from "semantic-ui-react"
import styles from "./styles.module.css"

type PropTypes = {
  label: string,
  value: string,
}

export const ReservationFormHeaderItem = ({ label, value }: PropTypes) => (
  <Statistic>
    <Statistic.Label className={styles.statisticsLabel}>
      {label}
    </Statistic.Label>
    <Statistic.Value className={`${styles.statisticsValue} statistics-value`}>
      {value}
    </Statistic.Value>
  </Statistic>
)

// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"
import { ClubType } from "./types"
import { uploadFiles, deleteAllImages } from "utils/FileUpload"

type DataType = {
  params: ClubType,
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const createClub = ({ params, form, modalId, formId }: DataType) => {
  return {
    [CALL_API]: {
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
      promise: httpClient => httpClient.post("/clubs", params),
      successMessage: "Successfully created new club",
      errorMessage: "Failed to create new club",
      formResetName: form,
      closeModalId: modalId,
      resetActiveFormId: formId,
    },
  }
}

// Operation
export function createClubWithImagesAndReload(
  data: DataType,
  filters: FilterType
) {
  return async (dispatch: Function, getState: Function) => {
    const { images = [], ...rest } = data.params
    data.params = rest
    const finalCreateType = await dispatch(createClub(data))

    if (isSuccessType(finalCreateType)) {
      const { club_id: id } = finalCreateType.payload
      images.filter(image => !image._id && image.name)
      await dispatch(uploadFiles({ id, files: images, type: "club" }))
      dispatch(fetchClubs({ params: filters }))
    }

    return finalCreateType
  }
}

// Operation
export function createClubAndReload(data: DataType) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createClub(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchClubs())
    }

    return finalCreateType
  }
}

export const fetchClubs = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: httpClient => httpClient.get("/clubs"),
    payloadModifier: payload => (Array.isArray(payload) ? payload : [payload]),
  },
})

export const updateClub = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: httpClient =>
      httpClient.put("/clubs", { ...params, club_id: params._id }),
    successMessage: "Successfully updated club data",
    errorMessage: "Failed to update club",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export function updateClubAndReload(data: DataType, filters: FilterType) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(updateClub(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchClubs(filters))
    }

    return finalCreateType
  }
}

// Operation
export function updateClubWithImagesAndReload(
  data: DataType,
  filters: FilterType
) {
  return async (dispatch: Function, getState: Function) => {
    const { images, storedImages, ...rest } = data.params
    data.params = rest
    deleteAllImages(dispatch, images, storedImages)

    const newImages = images.filter(image => !image._id && image.name)
    if (!newImages.length) {
      return dispatch(updateClubAndReload(data, filters))
    }

    const finalCreateType = await dispatch(
      uploadFiles({ id: rest._id, files: newImages, type: "club" })
    )

    if (isSuccessType(finalCreateType)) {
      dispatch(updateClubAndReload(data, filters))
    }
    return finalCreateType
  }
}

export const deleteClub = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: httpClient => httpClient.delete("/clubs", { params: { id } }),
    successMessage: "Venue is successfully deleted",
    errorMessage: "Failed to delete club",
  },
})

// Operation
export function deleteClubAndReload(id: string) {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteClub(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchClubs())
    }

    return finalCreateType
  }
}

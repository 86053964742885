import React from "react"
import { connect } from "react-redux"
import { closeModal } from "data/Modals/actions"

import Modal from "components/Modal"
import CustomerForm from "views/Customers/CustomerForm"
import Form from "components/Form"
import { createCustomerAndReload } from "data/Customers/actions"
import { CUSTOMER_FORM, CUSTOMER_MODAL } from "Constants"

class CustomerModal extends React.Component {
  closeModal = () => {
    this.props.closeModal(CUSTOMER_MODAL)
  }

  createCustomer = ({ passwordConfirm, ...customer }) => {
    this.props.createCustomerAndReload({
      params: customer,
      modalId: CUSTOMER_MODAL,
    })
  }

  render() {
    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={CUSTOMER_FORM}
          header="Add new customer"
          submitButtonText="Create customer"
          initialValues={this.props.initialValues}
          onCancel={this.closeModal}
          onSubmit={this.createCustomer}
          columns={2}
        >
          <CustomerForm />
        </Form>
      </Modal>
    )
  }
}

export default connect(
  null,
  { closeModal, createCustomerAndReload }
)(CustomerModal)

// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"

import type { FilterType } from "data/Filters/types"

type DataType = {
  params: { _id: string },
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const createTable = ({ params, form, modalId, formId }: DataType) => {
  return {
    [CALL_API]: {
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
      promise: httpClient => httpClient.post("/tables", params),
      successMessage: "Successfully created new table",
      errorMessage: "Failed to create table",
      formResetName: form,
      closeModalId: modalId,
      resetActiveFormId: formId,
    },
  }
}

// Operation
export const createTableAndReload = (data: DataType, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createTable(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchTables({ params: filters }))
    }

    return finalCreateType
  }
}

export const fetchTables = (params: ?{ _id: string, club: ?string }) => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: httpClient => httpClient.get("/tables", params),
  },
})

export const updateTable = ({ params, form, modalId, formId }: DataType) => {
  return {
    [CALL_API]: {
      types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
      promise: httpClient =>
        httpClient.put("/tables", {
          ...params,
          table_id: params._id,
        }),
      successMessage: "Table is successfully updated",
      errorMessage: "Failed to update table",
      formResetName: form,
      closeModalId: modalId,
      resetActiveFormId: formId,
    },
  }
}

// Operation
export const updateTableAndReload = (data: DataType, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(updateTable(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchTables({ params: filters }))
    }

    return finalCreateType
  }
}

export const deleteTable = (id: string) => {
  return {
    [CALL_API]: {
      types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
      promise: httpClient =>
        httpClient.delete("/tables", { params: { id: id } }),
      successMessage: "Table is successfully deleted",
      errorMessage: "Failed to delete table",
    },
  }
}

// Operation
export const deleteTableAndReload = (id: string, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteTable(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchTables({ params: filters }))
    }

    return finalCreateType
  }
}

// @flow
import React from "react"
import { Popup } from "semantic-ui-react"

const HoverableDescription = ({
  children,
  max,
}: {
  children: string,
  max?: number,
}) => {
  let shouldRenderPopup = true
  const short = (() => {
    if (!children) return null
    if (!max) max = 25
    if (children.length > max) return `${children.substring(0, max)}...`
    shouldRenderPopup = false
    return children
  })()
  return shouldRenderPopup ? (
    <Popup
      position="bottom center"
      basic
      on="hover"
      trigger={<span>{short}</span>}
    >
      <span>{children || null}</span>
    </Popup>
  ) : (
    children
  )
}

export default HoverableDescription

// @flow
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { Grid } from "semantic-ui-react"

import TableSection from "components/TableSection/"
import TopPanel from "components/TopPanel"
import Loading from "components/Loading"
import ModalTriggerButton from "components/ModalTriggerButton"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import { openModal } from "data/Modals/actions"
import { fetchUsers, deleteUserAndReload } from "data/Users/actions"
import { setActiveUpdatingForm } from "data/Forms/actions"
import { headers } from "./UserTableHeaders"
import segmentToRow from "./SegmentToRow"

import styles from "./styles.module.css"
import rowsCreator from "utils/rowsCreator"
import { USER_MODAL } from "Constants"

type PropTypes = {
  fetchUsers: Function,
  deleteUser: Function,
  openModal: Function,
}

class Users extends React.Component<PropTypes> {
  componentDidMount() {
    this.props.fetchUsers()
  }

  render() {
    return (
      <div className={styles.masterContainer}>
        <TopPanel title="Users/Admins">
          <Grid columns={5} stackable>
            <FilterContainer>
              <FilterItem>
                <ModalTriggerButton
                  onClick={() => this.props.openModal(USER_MODAL)}
                  content="Create user"
                />
              </FilterItem>
            </FilterContainer>
          </Grid>
        </TopPanel>
        <Loading isLoading={this.props.users.readLoading}>
          <TableSection
            headers={headers}
            rows={rowsCreator(segmentToRow, this.props.users.segments, {
              setActiveUpdatingForm: id => this.props.setActiveUpdatingForm(id),
              openModal: () => this.props.openModal(USER_MODAL),
              deleteUser: id => this.props.deleteUserAndReload(id),
              clubs: this.props.clubs.segments,
            })}
          />
        </Loading>
      </div>
    )
  }
}

const mstp = ({ users, modals, forms, clubs }) => ({
  users,
  modals,
  forms,
  clubs,
})

const mdtp = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  fetchUsers: bindActionCreators(fetchUsers, dispatch),
  deleteUserAndReload: bindActionCreators(deleteUserAndReload, dispatch),
  setActiveUpdatingForm: bindActionCreators(setActiveUpdatingForm, dispatch),
})

export default connect(
  mstp,
  mdtp
)(Users)

// @flow

import React from "react"
import { Grid, Header } from "semantic-ui-react"

type PropTypes = {
  header: string,
  className: string,
}

export default ({ header, className }: PropTypes) => (
  <Grid padded>
    <Grid.Column>
      <Header className={className} content={header} />
    </Grid.Column>
  </Grid>
)

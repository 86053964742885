// @flow

import React from "react"
import { Field } from "redux-form"
import { Grid } from "semantic-ui-react"
import renderDateField from "components/DateField/renderDateField"
import { InputField, LabelInputField, SelectField } from "react-semantic-redux-form"
import { required, onlyNumericAndSpaces } from "wrappers/FormValidation/validations"

const TableForm = ({ data }) => {
  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column>
          <label>Name of the table</label>
          <Field
            name="name"
            component={InputField}
            type="text"
            placeholder="Enter name of the table"
            validate={[required]}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <label>Venue</label>
          <Field
            name="club_id"
            component={SelectField}
            options={data.clubsOptions}
            validate={[required]}
            placeholder="Select Venue"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <label>Location</label>
          <Field
            name="placement"
            component={InputField}
            type="text"
            placeholder="Enter location"
            validate={[required]}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <label>Capacity</label>
          <Field
            name="capacity"
            component={InputField}
            type="number"
            validate={[required, onlyNumericAndSpaces]}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <label>Arrival time (latest time guests can show up)</label>
          <Field
            name="arrival_time"
            component={InputField}
            type="text"
            placeholder="Enter time"
            validate={[required]}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <label>Minimum spend</label>
          <Field
            name="minimum_spend"
            component={LabelInputField}
            type="number"
            validate={[required, onlyNumericAndSpaces]}
            label={{ basic: true, content: "฿" }}
            labelPosition="left"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          <label>Override Minimum spend (Minimum spend will change when user booked on selected dates)</label>
        </Grid.Column>
      </Grid.Row>

      {[1, 2, 3, 4, 5].map((value, index) => (
        <Grid.Row style={{ alignItems: "end" }} key={index}>
          <Grid.Column width={1}>
            <label>{index + 1}.</label>
          </Grid.Column>
          <Grid.Column>
            <Field name={`override_prices[${index}].date`} component={renderDateField} placeholder="Enter Date" />
          </Grid.Column>
          <Grid.Column>
            <Field
              name={`override_prices[${index}].minimum_spend`}
              component={LabelInputField}
              type="number"
              validate={[onlyNumericAndSpaces]}
              label={{ basic: true, content: "฿" }}
              labelPosition="left"
            />
          </Grid.Column>
        </Grid.Row>
      ))}
    </React.Fragment>
  )
}

export default TableForm

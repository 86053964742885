// @flow
import * as React from "react"

const base = "Table"

const withTitle = (title: string) => (Component: React.ComponentType<any>) =>
  class Title extends React.Component<{}> {
    componentDidMount() {
      document.title = `${base} | ${title}`
    }

    componentWillUnmount() {
      document.title = base
    }

    render() {
      return <Component {...this.props} />
    }
  }

export default withTitle

// @flow
import { CALL_API } from "middleware/api"

const CREATE_REQUEST = "FILE_CREATE_REQUEST"
const CREATE_SUCCESS = "FILE_CREATE_SUCCESS"
const CREATE_FAILURE = "FILE_CREATE_FAILURE"
const DELETE_REQUEST = "FILE_DELETE_REQUEST"
const DELETE_SUCCESS = "FILE_DELETE_SUCCESS"
const DELETE_FAILURE = "FILE_DELETE_FAILURE"
const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
}

type FileType = {
  params: {
    files: ?Array<Object>,
    id: string,
    type: string,
  },
}

type DataType = {
  form: string,
  modalId: string,
  formId: string,
}

export const uploadFiles = (params: FileType, rest: DataType) => {
  return {
    [CALL_API]: {
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
      promise: httpClient =>
        httpClient.post("/files", createFormDataFromParams(params), config),
      errorMessage: "Image upload failed",
      ...rest,
    },
  }
}

export const deleteFiles = ({ _id: id, ...image }: FileType) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: httpClient => httpClient.delete("/files", { params: { id, ...image } }),
    errorMessage: "Failed to delete image",
  },
})

export const deleteAllImages = (
  dispatch: Function,
  images: Array<FileType>,
  storedImages = []
) => {
  const deletedImages = storedImages.filter(
    storedImage => !images.find(image => image._id === storedImage._id)
  )

  if (deletedImages.length) {
    const requests = deletedImages.map((image) => {
      return dispatch(deleteFiles(image))
    })
    Promise.all(requests)
  }
}

const createFormDataFromParams = (params: FileType) =>
  Object.keys(params).reduce((result, param) => {
    const value = params[param]
    if (param === "files") {
      value.forEach(file => {
        result.append(`${param}`, file, file.name)
      })
    } else {
      value && result.append(param, value)
    }
    return result
  }, new FormData())

import { combineReducers } from "redux"

import user from "instances/User/reducer"
import notifications from "wrappers/Notifications/reducer"
import { reducer as formReducer } from "redux-form"

import forms from "data/Forms/reducer"
import modals from "data/Modals/reducer"
import filters from "data/Filters/reducer"
import dealsFilters from "data/DealsFilters/reducer"
import clubs from "data/Clubs/reducer"
import locations from "data/Locations/reducer"
import promotions from "data/Promotions/reducer"
import users from "data/Users/reducer"
import tables from "data/Tables/reducer"
import events from "data/Events/reducer"
import customers from "data/Customers/reducer"
import overview from "data/Overview/reducer"
import bookedDeals from "data/BookedDeals/reducer"
import deals from "data/Deals/reducer"
import categories from "data/Categories/reducer"

export default combineReducers({
  forms,
  modals,
  filters,
  dealsFilters,
  notifications,
  user, // currently logged in user
  users,
  clubs,
  tables,
  events,
  deals,
  categories,
  customers,
  overview,
  locations,
  promotions,
  bookedDeals,
  form: formReducer,
})

export const EVENTS = "EVENTS"
const which = EVENTS

export const CREATE_REQUEST = `${which}_CREATE_REQUEST`
export const CREATE_SUCCESS = `${which}_CREATE_SUCCESS`
export const CREATE_FAILURE = `${which}_CREATE_FAILURE`
export const READ_REQUEST = `${which}_READ_REQUEST`
export const READ_SUCCESS = `${which}_READ_SUCCESS`
export const READ_FAILURE = `${which}_READ_FAILURE`
export const UPDATE_REQUEST = `${which}_UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${which}_UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${which}_UPDATE_FAILURE`
export const DELETE_REQUEST = `${which}_DELETE_REQUEST`
export const DELETE_SUCCESS = `${which}_DELETE_SUCCESS`
export const DELETE_FAILURE = `${which}_DELETE_FAILURE`

export const CREATE = "CREATE_EVENT"
export const UPDATE = "UPDATE_EVENT"

export const CLUB_SELECTED = "EVENTS_CLUB_SELECTED"
export const EVENT_SELECTED = "EVENTS_EVENT_SELECTED"
export const OVERRIDE_TABLE_PRICES_MODIFICATION =
  "EVENTS_OVERRIDE_TABLE_PRICES_MODIFICATION"

export const TABLE_PRICE_OVERRIDE = "EVENTS_TABLE_PRICE_OVERRIDE"

export const transactionStatusOptions = (keyPrefix: string) => [
  {
    key: `${keyPrefix}no_payment`,
    value: "no_payment",
    text: "No payment yet",
  },
  {
    key: `${keyPrefix}invoiced`,
    value: "invoiced",
    text: "Invoiced",
  },
  {
    key: `${keyPrefix}completed`,
    value: "completed",
    text: "Payment completed",
  },
  {
    key: `${keyPrefix}archived`,
    value: "archived",
    text: "Archived",
  },
]

export const getTransactionStatus = value => {
  const status = transactionStatusOptions().find(
    status => status.value === value
  )
  return (status && status.text) || status.value || "-"
}

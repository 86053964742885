// @flow
import React from "react"
import styles from "./styles.module.scss"

type PropTypes = {
  date: ?string,
  bookings: ?number,
  spend: ?number,
}

export const DetailRow = ({ date, bookings, spend }: PropTypes) => (
  <li className={styles.borderTopLight}>
    <div className={styles.detailRow}>
      <div>{date}</div>
      <div>
        {bookings}
        &nbsp; Bookings
      </div>
      <div className={styles.textRight}>$ {spend}</div>
    </div>
  </li>
)

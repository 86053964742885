// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  CLUB_SELECTED,
  DEAL_SELECTED,
  CATEGORY_SELECTED,
  OVERRIDE_TABLE_PRICES_MODIFICATION,
} from "./constants"
import { CLUBADMIN } from "instances/User/constants"

import type { EventType } from "./types"
import type { FilterType } from "data/Filters/types"
import { uploadFiles, deleteAllImages } from "utils/FileUpload"

type DataType = {
  params: EventType,
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const fetchDeals = (params: ?{ category: ?string }) => {
  return {
    [CALL_API]: {
      types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
      promise: httpClient => httpClient.get("/deals", params),
    },
  }
}

export const createDeal = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
    promise: httpClient => httpClient.post("/deals", params),
    successMessage: "Successfully created new deal",
    errorMessage: "Failed to create deal",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export const createDealAndReload = (data: DataType, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createDeal(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchDeals({ params: filters }))
    }

    return finalCreateType
  }
}

// Operation
export function createDealWithImagesAndReload(data: DataType, filters: FilterType) {
  return async (dispatch: Function, getState: Function) => {
    const { images = [], ...rest } = data.params
    data.params = rest
    const finalCreateType = await dispatch(createDeal(data))

    if (isSuccessType(finalCreateType)) {
      const { event_id: id } = finalCreateType.payload
      images.filter(image => !image._id && image.name)
      await dispatch(uploadFiles({ id, files: images, type: "deal" }))

      dispatch(fetchDeals({ params: filters }))
    }

    return finalCreateType
  }
}

export const updateDeal = ({ params, form, modalId, formId }: DataType, role: string) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: httpClient => {
      const body = {
        ...params,
        event_id: params._id,
      }
      if (role === CLUBADMIN) delete body.spotlight // club admin can't update spotlight events
      return httpClient.put("/deals", body)
    },
    successMessage: "Deal is successfully updated",
    errorMessage: "Failed to update deal",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export const updateDealAndReload = (data: DataType, filters: FilterType, role: string) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(updateDeal(data, role))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchDeals({ params: filters }))
    }

    return finalCreateType
  }
}

// Operation
export function updateDealWithImagesAndReload(data: DataType, filters: FilterType) {
  return async (dispatch: Function, getState: Function) => {
    const { images, storedImages, ...rest } = data.params
    data.params = rest
    deleteAllImages(dispatch, images, storedImages)

    const newImages = images.filter(image => !image._id && image.name)
    if (!newImages.length) {
      return dispatch(updateDealAndReload(data, filters))
    }

    const finalCreateType = await dispatch(uploadFiles({ id: rest._id, files: newImages, type: "deal" }))

    if (isSuccessType(finalCreateType)) {
      dispatch(updateDealAndReload(data, filters))
    }
    return finalCreateType
  }
}

export const deleteDeal = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: httpClient => httpClient.delete("/deals", { params: { id } }),
    successMessage: "Deal is successfully deleted",
    errorMessage: "Failed to delete deal",
  },
})

// Operation
export const deleteDealAndReload = (id: string, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteDeal(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchDeals({ params: filters }))
    }

    return finalCreateType
  }
}

export const selectClub = (id: string) => ({
  type: CLUB_SELECTED,
  payload: id,
})

export const selectCategory = (id: string) => ({
  type: CATEGORY_SELECTED,
  payload: id,
})

export const selectDeal = (id: string) => ({
  type: DEAL_SELECTED,
  payload: id,
})

export const modifyDealsWithOverridenTablePrices = ({
  id,
  value,
}: {
  id: string, // event id
  value: boolean, //
}) => {
  return {
    type: OVERRIDE_TABLE_PRICES_MODIFICATION,
    payload: { id, value },
  }
}

import { SET, UNSET } from "./constants"

const ModalsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET:
      return (() => {
        return action.payload
      })()
    case UNSET:
      return (() => {
        return {}
      })()
    default:
      return state
  }
}

export default ModalsReducer

// @flow
import React from "react"
import { Icon } from "semantic-ui-react"

export const headers = (isSuperAdmin: ?boolean) => {
  const all = [
    { component: <span>Booking Id</span> },
    { component: <span>Name</span> },
    { component: <span>Location</span> },
    { component: <span>Capacity</span> },
    { component: <span>Min. spend</span> },
    { component: <span>Booked {isSuperAdmin ? "by" : "for"}</span> },
    { component: <span>Arrival time</span> },
    { component: <span>Contact</span> },
    { component: <span>Confirmed</span> },
    { component: <span>Transaction status</span> },
    { component: <span>Comments</span> },
    {
      component: <Icon size="large" name="attach" />,
    },
    { component: <span /> },
  ]

  if (!isSuperAdmin) {
    // for clubadmin remove transaction_status - index 8 & contact - index 6
    all.splice(9, 1)
    all.splice(7, 1)
  }

  return all
}

import { connect } from "react-redux"
import { getUserRole } from "instances/User/selectors"

import { SUPERADMIN } from "instances/User/constants"

const AccessControl = ({ role, renderSuperAdmin, renderAdmin }) => {
  return role === SUPERADMIN ? renderSuperAdmin : renderAdmin
}

AccessControl.defaultProps = {
  renderSuperAdmin: null,
  renderAdmin: null,
}

export default connect(state => ({ role: getUserRole(state) }))(AccessControl)

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Dropdown } from "semantic-ui-react"
import { updateFilters } from "data/Filters/actions"
import { transactionStatusOptions } from "components/TransactionStatus/Options"

class TransactionStatusFilter extends React.Component {
  transactionStatusOptions = [
    {
      key: "transaction_status",
      value: "all",
      text: "All Transaction Statuses",
    },
    ...transactionStatusOptions("filter"),
  ]

  onChange = (e, { name, value }) => {
    const { filters, updateFilters, applyFilters } = this.props
    if (filters[name] === value || (!filters[name] && value === "all")) return
    filters[name] = value === "all" ? "" : value
    updateFilters(filters)
    applyFilters(filters)
  }

  render() {
    return (
      <Dropdown
        name="transaction_status"
        value={this.props.transactionStatus}
        options={this.transactionStatusOptions}
        onChange={this.onChange}
      />
    )
  }
}

const mstp = ({ filters = {} }) => ({
  filters,
  transactionStatus: filters.transaction_status || "all",
})

const mdtp = dispatch => ({
  updateFilters: bindActionCreators(updateFilters, dispatch),
})

export default connect(
  mstp,
  mdtp
)(TransactionStatusFilter)

import { SET, UNSET } from "./constants"

export function setUser(user) {
  return {
    type: SET,
    payload: user,
  }
}

export function unsetUser() {
  return { type: UNSET }
}

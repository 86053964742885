// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import TableSection from "components/TableSection/"
import TopPanel from "components/TopPanel"
import Loading from "components/Loading"
import { fetchCustomers, deleteCustomerAndReload } from "data/Customers/actions"
import { openModal } from "data/Modals/actions"
import ModalTriggerButton from "components/ModalTriggerButton"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import { headers } from "./Headers"
import { segmentToRow } from "./SegmentToRow"
import rowsCreator from "utils/rowsCreator"
import { Grid } from "semantic-ui-react"

import styles from "./styles.module.css"
import { CUSTOMER_MODAL } from "Constants"

class Customers extends React.Component<{}> {
  componentDidMount() {
    this.props.fetchCustomers()
  }

  render() {
    return (
      <div className={styles.masterContainer}>
        <TopPanel title="Customers">
          <Grid columns={5} stackable>
            <FilterContainer>
              <FilterItem>
                <ModalTriggerButton
                  onClick={() => this.props.openModal(CUSTOMER_MODAL)}
                  content="Create customer"
                />
              </FilterItem>
            </FilterContainer>
          </Grid>
        </TopPanel>
        <Loading isLoading={this.props.customers.readLoading}>
          <TableSection
            headers={headers}
            rows={rowsCreator(segmentToRow, this.props.customers.segments, {
              deleteCustomer: id => this.props.deleteCustomerAndReload(id),
            })}
          />
        </Loading>
      </div>
    )
  }
}

const mstp = ({ customers }) => ({ customers })

const mdtp = dispatch => ({
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  deleteCustomerAndReload: bindActionCreators(
    deleteCustomerAndReload,
    dispatch
  ),
  openModal: bindActionCreators(openModal, dispatch),
})

export default connect(
  mstp,
  mdtp
)(Customers)

// @flow
import { CALL_API } from "middleware/api"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  CLUB_SELECTED,
  EVENT_SELECTED,
  OVERRIDE_TABLE_PRICES_MODIFICATION,
} from "./constants"
import { CLUBADMIN } from "instances/User/constants"

import type { EventType } from "./types"
import type { FilterType } from "data/Filters/types"
import { uploadFiles, deleteAllImages } from "utils/FileUpload"

type DataType = {
  params: EventType,
  form: string,
  modalId: string,
  formId: string,
}

const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const fetchEvents = (
  params: ?{ date_from: ?string, date_to: ?string }
) => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: httpClient => httpClient.get("/events", params),
  },
})

export const createEvent = ({ params, form, modalId, formId }: DataType) => ({
  [CALL_API]: {
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
    promise: httpClient => httpClient.post("/events", params),
    successMessage: "Successfully created new event",
    errorMessage: "Failed to create event",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export const createEventAndReload = (data: DataType, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(createEvent(data))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchEvents({ params: filters }))
    }

    return finalCreateType
  }
}

// Operation
export function createEventWithImagesAndReload(
  data: DataType,
  filters: FilterType
) {
  return async (dispatch: Function, getState: Function) => {
    const { images = [], ...rest } = data.params
    data.params = rest
    const finalCreateType = await dispatch(createEvent(data))

    if (isSuccessType(finalCreateType)) {
      const { event_id: id } = finalCreateType.payload
      images.filter(image => !image._id && image.name)
      await dispatch(uploadFiles({ id, files: images, type: "event" }))

      dispatch(fetchEvents({ params: filters }))
    }

    return finalCreateType
  }
}

export const updateEvent = (
  { params, form, modalId, formId }: DataType,
  role: string
) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: httpClient => {
      const body = {
        ...params,
        event_id: params._id,
      }
      if (role === CLUBADMIN) delete body.spotlight // club admin can't update spotlight events
      return httpClient.put("/events", body)
    },
    successMessage: "Event is successfully updated",
    errorMessage: "Failed to update event",
    formResetName: form,
    closeModalId: modalId,
    resetActiveFormId: formId,
  },
})

// Operation
export const updateEventAndReload = (
  data: DataType,
  filters: FilterType,
  role: string
) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(updateEvent(data, role))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchEvents({ params: filters }))
    }

    return finalCreateType
  }
}

// Operation
export function updateEventWithImagesAndReload(
  data: DataType,
  filters: FilterType
) {
  return async (dispatch: Function, getState: Function) => {
    const { images, storedImages, ...rest } = data.params
    data.params = rest
    deleteAllImages(dispatch, images, storedImages)

    const newImages = images.filter(image => !image._id && image.name)
    if (!newImages.length) {
      return dispatch(updateEventAndReload(data, filters))
    }

    const finalCreateType = await dispatch(
      uploadFiles({ id: rest._id, files: newImages, type: "event" })
    )

    if (isSuccessType(finalCreateType)) {
      dispatch(updateEventAndReload(data, filters))
    }
    return finalCreateType
  }
}

export const deleteEvent = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: httpClient => httpClient.delete("/events", { params: { id } }),
    successMessage: "Event is successfully deleted",
    errorMessage: "Failed to delete event",
  },
})

// Operation
export const deleteEventAndReload = (id: string, filters: FilterType) => {
  return async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(deleteEvent(id))

    if (isSuccessType(finalCreateType)) {
      dispatch(fetchEvents({ params: filters }))
    }

    return finalCreateType
  }
}

export const selectClub = (id: string) => ({
  type: CLUB_SELECTED,
  payload: id,
})

export const selectEvent = (id: string) => ({
  type: EVENT_SELECTED,
  payload: id,
})

export const modifyEventsWithOverridenTablePrices = ({
  id,
  value,
}: {
  id: string, // event id
  value: boolean, //
}) => {
  return {
    type: OVERRIDE_TABLE_PRICES_MODIFICATION,
    payload: { id, value },
  }
}

import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"

import Modal from "components/Modal"
import UserForm from "views/Users/UserForm"
import Form from "components/Form"
import { createUserAndReload, updateUserAndReload } from "data/Users/actions"
import dropdownize from "utils/dropdownize"
import { USER_FORM, USER_MODAL } from "Constants"

class UserModal extends React.Component {
  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(USER_MODAL, activeModal)
  }

  createUser = user => {
    const { createUserAndReload, activeModal } = this.props

    createUserAndReload({
      params: user,
      modalId: USER_MODAL,
      formId: activeModal,
    })
  }

  updateUser = user => {
    const { updateUserAndReload, activeModal } = this.props

    updateUserAndReload({
      params: user,
      modalId: USER_MODAL,
      formId: activeModal,
    })
  }

  render() {
    const { initialValues, clubs } = this.props
    const { _id } = initialValues

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={USER_FORM}
          header={_id ? "Update user" : "Add new user"}
          submitButtonText={_id ? "Update user" : "Create user"}
          initialValues={initialValues}
          onCancel={this.closeModal}
          onSubmit={_id ? this.updateUser : this.createUser}
          columns={4}
        >
          <UserForm
            data={{
              clubsOptions: dropdownize(clubs.segments),
            }}
            // disabled={["admin"]}
          />
        </Form>
      </Modal>
    )
  }
}

const mstp = state => {
  const { users, clubs, forms } = state
  const { activeUpdatingForm } = forms
  const initialValues = activeUpdatingForm
    ? users.segments[activeUpdatingForm]
    : {}

  Object.values(clubs.segments).forEach(club => {
    if (club.admins) {
      const clubAdmin = club.admins.find(admin => admin === initialValues._id)
      if (clubAdmin) {
        initialValues.admin = club._id
      }
    }
  })

  return {
    clubs,
    activeModal: activeUpdatingForm,
    initialValues: initialValues,
  }
}

const actions = {
  closeModalAndUnsetActiveUpdatingForm,
  createUserAndReload,
  updateUserAndReload,
}

export default connect(
  mstp,
  actions
)(UserModal)

import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  fetchEvents,
  modifyEventsWithOverridenTablePrices,
} from "data/Events/actions"
import { openModal } from "data/Modals/actions"
import { updateFilters } from "data/Filters/actions"

import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import AccessControl from "components/AccessControl"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import DateRangeFilter from "components/DateRangeFilter"
import ClubFilter from "components/ClubFilter"
import { EVENT_MODAL } from "Constants"

import { Grid } from "semantic-ui-react"

class Filters extends React.Component {
  async componentDidMount() {
    const dateFrom = moment()
    const filters = {
      date_from: dateFrom.format("YYYY-MM-DD"),
      date_to: moment(dateFrom)
        .add(30, "days")
        .format("YYYY-MM-DD"),
      club: "",
    }
    const {
      updateFilters,
      fetchEvents,
      events,
      modifyEventsWithOverridenTablePrices,
    } = this.props
    updateFilters(filters)
    await fetchEvents({ params: filters })
    Object.keys(events.segments).forEach(key => {
      modifyEventsWithOverridenTablePrices({
        id: key,
        value: Boolean(events.segments[key].override_prices.length),
      })
    })
  }

  componentWillUnmount() {
    this.props.updateFilters()
  }

  applyFilters = currentFilters => {
    const filters = currentFilters || this.props.filters
    this.props.fetchEvents({
      params: filters,
    })
  }

  render() {
    return (
      <React.Fragment>
        <TopPanel title="Events">
          <Grid columns={5} stackable>
            <AccessControl
              renderSuperAdmin={
                <FilterContainer>
                  <FilterItem>
                    <ClubFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <DateRangeFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <ModalTriggerButton
                      onClick={() => this.props.openModal(EVENT_MODAL)}
                      content="Create Event"
                    />
                  </FilterItem>
                </FilterContainer>
              }
              renderAdmin={
                <FilterContainer>
                  <FilterItem>
                    <DateRangeFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <ModalTriggerButton
                      onClick={() => this.props.openModal(EVENT_MODAL)}
                      content="Create Event"
                    />
                  </FilterItem>
                </FilterContainer>
              }
            />
          </Grid>
        </TopPanel>
      </React.Fragment>
    )
  }
}

const mstp = ({ events, filters, clubs }) => ({
  events,
  filters,
  clubs,
  club: clubs.segments[filters.club],
})

const mdtp = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  updateFilters: bindActionCreators(updateFilters, dispatch),
  fetchEvents: bindActionCreators(fetchEvents, dispatch),
  modifyEventsWithOverridenTablePrices: bindActionCreators(
    modifyEventsWithOverridenTablePrices,
    dispatch
  ),
})

export default connect(
  mstp,
  mdtp
)(Filters)

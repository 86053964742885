import React from "react"
import { useSelector, useDispatch } from "react-redux"

import Loading from "components/Loading"
import TableSection from "components/TableSection/"
import rowsCreator from "utils/rowsCreator"
import Filters from "./Filters"
import segmentToRow from "./SegmentToRow"
import { openModal } from "data/Modals/actions"
import { DEAL_MODAL } from "Constants"

import { deleteDealAndReload, selectClub, selectCategory, selectDeal } from "data/Deals/actions"
import { setActiveUpdatingForm, unsetActiveUpdatingForm } from "data/Forms/actions"

import styles from "./styles.module.css"

export const headers = [
  { component: <span>Picture</span> },
  { component: <span>Name of the deal</span> },
  { component: <span>Category of deal</span> },
  { component: <span>Description</span> },
  { component: <span>Deal price</span> },
  { component: <span /> },
]

function pairClubsWithDeals(clubs, deals) {
  if (!clubs) return deals
  if (!deals) return null

  const preparedData = []
  Object.keys(clubs).forEach(clubKey => {
    const club = clubs[clubKey]
    const filteredEvents = []

    Object.keys(deals).forEach(eventKey => {
      const event = deals[eventKey]
      if (event.club_id === clubKey) filteredEvents.push(event)
    })

    if (filteredEvents.length)
      preparedData.push({
        name: club.name,
        events: filteredEvents,
      })
  })
  return preparedData
}

const Deals = ({ tables }) => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state.filters)

  const handleSelectDeal = id => {
    dispatch(selectDeal(id))
  }
  const handleSelectClub = id => {
    dispatch(selectClub(id))
  }
  const handleSelectCategory = id => {
    dispatch(selectCategory(id))
  }
  const handleSetActiveUpdatingForm = id => {
    dispatch(setActiveUpdatingForm(id))
  }

  const handleOpenModal = () => {
    dispatch(openModal(DEAL_MODAL))
  }

  const deleteDeal = (id, filters) => {
    dispatch(deleteDealAndReload(id, filters))
  }
  const modifyEventsWithOverridenTablePrices = ({ id, value }) => {}

  const readLoading = useSelector(state => state.deals.readLoading)
  const deals = useSelector(state => state.deals.deals)
  const clubsSegments = useSelector(state => state.clubs.segments)
  const clubsReadSuccess = useSelector(state => state.clubs.readSuccess)
  const dealsSegments = useSelector(state => state.deals.segments)

  const dealsForRender = pairClubsWithDeals(clubsSegments, dealsSegments)

  return (
    <div className={styles.masterContainer}>
      <Filters />
      <Loading isLoading={readLoading}>
        {dealsForRender &&
          dealsForRender.map((club, c) => (
            <TableSection
              key={`deal-${club.name}-${c}`}
              headingComponent={club.name}
              headers={headers}
              rows={rowsCreator(segmentToRow, club.events, {
                openModal: handleOpenModal,
                clubs: clubsSegments,
                clubsLoaded: clubsReadSuccess,
                setActiveUpdatingForm: handleSetActiveUpdatingForm,
                deleteDeal: id => deleteDeal(id, filters),
                modifyEventsWithOverridenTablePrices,
                tables: tables.segments,
                selectClub: handleSelectClub,
                selectCategory: handleSelectCategory,
                selectDeal: handleSelectDeal,
              })}
            />
          ))}
      </Loading>
    </div>
  )
}

export default Deals

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateFilters } from "data/Filters/actions"
import moment from "moment"

import DateRangePicker from "components/DateRangePicker"
import { transactionStatusOptions } from "components/TransactionStatus/Options"

class DateRangeFilter extends React.Component {
  state = {
    date_from: this.props.filters.date_from,
    date_to: this.props.filters.date_to,
  }

  transactionStatusOptions = [
    {
      key: "transaction_status",
      value: "all",
      text: "All Transaction Statuses",
    },
    ...transactionStatusOptions("filter"),
  ]

  onFocusChange = focusedInput => {
    const { filters } = this.props
    const { date_from: dateFrom, date_to: dateTo } = filters
    if (!focusedInput && (!dateFrom || !dateTo)) {
      const { date_from, date_to } = this.state
      this.props.updateFilters({
        ...this.props.filters,
        date_from,
        date_to,
      })
    }
  }

  onChangeDate = (dateFrom, dateTo, focusedInput) => {
    const { filters: currentFilters } = this.props

    // fix for dateFrom & dateTo on first load
    if (currentFilters.date_from && currentFilters.date_to) {
      const { date_from, date_to } = currentFilters
      this.setState({ date_from, date_to })
    }

    const date_from = dateFrom ? dateFrom.format("YYYY-MM-DD") : null
    const date_to =
      dateTo && focusedInput === "endDate" ? dateTo.format("YYYY-MM-DD") : null

    const filters = {
      ...currentFilters,
      date_from,
      date_to,
    }
    this.props.updateFilters(filters)
    if (date_from && date_to) {
      this.props.applyFilters(filters)
    }
  }

  render() {
    const { date_from, date_to } = this.props.filters
    return (
      <DateRangePicker
        onFocusChange={this.onFocusChange}
        onChangeDate={this.onChangeDate}
        dateFrom={date_from && moment(date_from)}
        dateTo={date_to && moment(date_to)}
      />
    )
  }
}

const mdtp = dispatch => ({
  updateFilters: bindActionCreators(updateFilters, dispatch),
})

export default connect(
  ({ filters = {} }) => ({ filters }),
  mdtp
)(DateRangeFilter)

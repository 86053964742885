// @flow
import React from "react"
import { Icon } from "semantic-ui-react"

import Button from "components/Button"

type PropTypes = {
  onClick: Function,
  content: string,
}

const ModalTriggerButton = ({ onClick, content }: PropTypes) => (
  <Button onClick={onClick}>
    {
      <React.Fragment>
        <span className="no-mobile">{content}</span>
        <Icon className="only-mobile" name="plus" />
      </React.Fragment>
    }
  </Button>
)

export default ModalTriggerButton

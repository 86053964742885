// @flow

import React from "react"
import { Field, reduxForm } from "redux-form"
import { Grid } from "semantic-ui-react"
import { InputField } from "react-semantic-redux-form"
import { EMAIL_CHANGE_FORM } from "Constants"
import { required, isEmail } from "wrappers/FormValidation/validations"

type PropTypes = {
  onSubmit: Function,
  handleSubmit: Function,
  pristine: ?boolean,
  submitting: ?boolean,
  valid: ?boolean,
}

const EmailChange = ({
  handleSubmit,
  onSubmit,
  pristine,
  submitting,
  valid,
}: PropTypes) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Grid columns={4} stackable>
      <Grid.Row>
        <Grid.Column>
          <label>E-mail</label>
          <Field
            name="email"
            component={InputField}
            type="email"
            labelPosition="left"
            placeholder="Enter new email"
            validate={[required, isEmail]}
            fluid
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <button
            className="link labelless"
            type="submit"
            disabled={pristine || submitting || !valid}
          >
            Change E-mail address
          </button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </form>
)

export default reduxForm({
  form: EMAIL_CHANGE_FORM,
})(EmailChange)

import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"

import Modal from "components/Modal"
import DealForm from "views/Deals/DealForm"
import { DEAL_FORM, DEAL_MODAL } from "Constants"
import Form from "components/Form"
import {
  createDealWithImagesAndReload,
  updateDealWithImagesAndReload,
  modifyDealsWithOverridenTablePrices,
} from "data/Deals/actions"
import dropdownize from "utils/dropdownize"
import dropdownizeArray from "utils/dropdownizeArray"

class EventModal extends React.Component {
  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(DEAL_MODAL, activeModal)
  }

  createDeal = table => {
    const { createDealWithImagesAndReload, activeModal, filters } = this.props

    const nextTable = { ...table }

    this.convertOverridePrices(nextTable, true)

    createDealWithImagesAndReload(
      {
        params: nextTable,
        modalId: DEAL_MODAL,
        formId: activeModal,
      },
      filters
    )
  }

  updateDeal = table => {
    const { updateDealWithImagesAndReload, activeModal, filters, initialValues } = this.props
    const nextTable = { ...table }

    this.convertOverridePrices(nextTable)

    updateDealWithImagesAndReload(
      {
        params: { storedImages: initialValues.images, ...nextTable },
        modalId: DEAL_MODAL,
        formId: activeModal,
      },
      filters,
      this.props.user.role
    )
  }

  convertOverridePrices = (nextTable, removeFields) => {
    if (!nextTable.showOverridenPrices) {
      delete nextTable.showOverridenPrices
      if (removeFields) {
        delete nextTable.override_prices
        return
      }
      nextTable.override_prices = []
      return
    }
    nextTable.override_prices.map(price => {
      price.table_id = price.table_id || price._id
      delete price._id
      delete price.name
      return price
    })
  }

  getClubTablePrices = () => {
    const { initialValues, tables } = this.props

    const clubTablePrices = Object.values(tables.segments).reduce((result, table) => {
      const clubId = table.club_id

      if (clubId in result) {
        result[clubId][table._id] = table
        return result
      }

      if (clubId === initialValues.club_id && initialValues.override_prices.length > 0) {
        // if club table prices are overriden for this event, use the override prices
        const overridenTablePrices = initialValues.override_prices.find(
          overridenPrice => overridenPrice.table_id === table._id
        )

        if (overridenTablePrices) {
          table.minimum_spend = overridenTablePrices.price
        }
      }

      result[clubId] = {}
      result[clubId][table._id] = table

      return result
    }, {})

    return clubTablePrices
  }

  render() {
    const { clubs, categories, showTablePrices } = this.props
    const initialValues = JSON.parse(JSON.stringify(this.props.initialValues))
    const { club_id } = initialValues
    const clubTablePrices = this.getClubTablePrices()

    // if there is no club_id - create new event is selected, and it doesn't have init values
    if (club_id && clubTablePrices[club_id]) {
      let override = false
      initialValues.override_prices = Object.values(clubTablePrices[club_id]).map(table => {
        const overridenPrice = initialValues.override_prices.find(price => price.table_id === table._id)
        override = override || !!(overridenPrice && overridenPrice.price)
        return {
          table_id: table._id,
          name: table.name,
          price: (overridenPrice && overridenPrice.price) || table.minimum_spend,
        }
      })
      initialValues.showOverridenPrices = override
    }

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={DEAL_FORM}
          header={club_id ? "Update deal" : "Create new deal"}
          submitButtonText={club_id ? "Update deal" : "Create deal"}
          initialValues={initialValues}
          onCancel={this.closeModal}
          onSubmit={club_id ? this.updateDeal : this.createDeal}
        >
          <DealForm
            data={{
              clubsOptions: dropdownize(clubs.segments),
              categoriesOptions: dropdownizeArray(categories.segments),
            }}
            showTablePrices={showTablePrices}
            clubTablePrices={clubTablePrices}
          />
        </Form>
      </Modal>
    )
  }
}
const actions = {
  modifyDealsWithOverridenTablePrices,
  closeModalAndUnsetActiveUpdatingForm,
  createDealWithImagesAndReload,
  updateDealWithImagesAndReload,
}

export default connect(
  // mstp,
  ({ user, deals, clubs, categories, forms, tables, filters }) => {
    const { activeUpdatingForm } = forms
    return {
      user,
      deals,
      clubs,
      categories,
      tables,
      activeModal: activeUpdatingForm,
      initialValues: deals.segments[activeUpdatingForm] || {
        override_prices: [],
      },
      filters,
    }
  },
  actions
)(EventModal)

// @flow

import React from "react"
import { Field } from "redux-form"
import { Grid } from "semantic-ui-react"
import { InputField } from "react-semantic-redux-form"
import {
  required,
  isEmail,
  minLength8,
  maxLength256,
  matchPasswordField,
  includesNumericAndLetters,
} from "wrappers/FormValidation/validations"

const CustomerForm = ({
  data,
  disabled,
  initialValues,
}: {
  data: ?Object,
  disabled: any,
}) => (
  <React.Fragment>
    <Grid.Row>
      <Grid.Column>
        <label>Full Name</label>
        <Field
          name="name"
          component={InputField}
          type="text"
          placeholder="Enter full name"
          validate={[required]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <label>E-mail</label>
        <Field
          name="email"
          component={InputField}
          type="email"
          placeholder="Enter email"
          validate={[required, isEmail]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <label>Password</label>
        <Field
          name="password"
          component={InputField}
          type="password"
          placeholder="Enter new password"
          validate={[
            required,
            minLength8,
            maxLength256,
            includesNumericAndLetters,
          ]}
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <label>Confirm Password</label>
        <Field
          name="passwordConfirm"
          component={InputField}
          type="password"
          placeholder="Confirm new password"
          validate={[required, minLength8, matchPasswordField]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
  </React.Fragment>
)

export default CustomerForm

// @flow
import * as React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { fetchClubs } from "data/Clubs/actions"
import { fetchLocations } from "data/Locations/actions"
import { fetchCategories } from "data/Categories/actions"
import { fetchTables } from "data/Tables/actions"
import { fetchUsers } from "data/Users/actions"

import { SUPERADMIN } from "instances/User/constants"

type PropTypes = {
  user: {
    role: string,
  },
  clubs: {
    readSuccess: boolean,
  },
  locations: {
    readSuccess: boolean,
  },
  tables: {
    readSuccess: boolean,
  },

  fetchClubs: Function,
  fetchTables: Function,
  fetchCategories: Function,
  fetchUsers: Function,
}

const withNecessaryCommonData = (which: [string]) => (Component: React.ComponentType<*>) => {
  class NecessaryCommonData extends React.Component<PropTypes> {
    componentDidMount() {
      const ROLE = this.props.user.role
      const isSuperAdmin = ROLE === SUPERADMIN

      if (!this.props.locations.readSuccess && which.includes("locations")) {
        this.props.fetchLocations()
      }
      if (!this.props.clubs.readSuccess && which.includes("clubs")) {
        this.props.fetchClubs()
      }
      if (!this.props.tables.readSuccess && which.includes("tables")) {
        this.props.fetchTables()
      }
      if (!this.props.users.readSuccess && isSuperAdmin && which.includes("users")) {
        this.props.fetchUsers()
      }
      if (!this.props.users.readSuccess && which.includes("categories")) {
        this.props.fetchCategories()
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }

  const mstp = ({ user, locations, clubs, tables, users }) => ({
    user,
    locations,
    clubs,
    tables,
    users,
  })

  const mdtp = (dispatch) => ({
    fetchClubs: bindActionCreators(fetchClubs, dispatch),
    fetchLocations: bindActionCreators(fetchLocations, dispatch),
    fetchTables: bindActionCreators(fetchTables, dispatch),
    fetchUsers: bindActionCreators(fetchUsers, dispatch),
    fetchCategories: bindActionCreators(fetchCategories, dispatch),
  })

  return connect(mstp, mdtp)(NecessaryCommonData)
}

export default withNecessaryCommonData

// @flow

import React from "react"
import { Field } from "redux-form"
import { Grid } from "semantic-ui-react"
import { InputField, SelectField } from "react-semantic-redux-form"
import {
  required,
  isEmail,
  isPhoneNumber,
} from "wrappers/FormValidation/validations"

const UserForm = ({
  data,
  disabled,
  initialValues,
}: {
  data: ?Object,
  disabled: any,
}) => (
  <React.Fragment>
    <Grid.Row>
      <Grid.Column width={8}>
        <label>Full Name</label>
        <Field
          name="name"
          component={InputField}
          type="text"
          placeholder="Enter full name"
          validate={[required]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={8}>
        <label>E-mail</label>
        <Field
          name="email"
          component={InputField}
          type="email"
          placeholder="Enter email"
          validate={[required, isEmail]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <label>Phone</label>
        <Field
          name="phone_number"
          component={InputField}
          type="text"
          placeholder="Enter phone"
          validate={[isPhoneNumber]}
          fluid
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <label>Club admin</label>
        <Field
          name="admin"
          component={SelectField}
          options={data.clubsOptions}
          placeholder="Choose club"
          disabled={
            initialValues.role &&
            Array.isArray(initialValues.role) &&
            initialValues.role.includes("admin")
          }
        />
      </Grid.Column>
    </Grid.Row>
  </React.Fragment>
)

export default UserForm

// @flow
import React from "react"
import { Image } from "semantic-ui-react"
import styles from "./styles.module.css"

type DataType = { url: string, name: ?string, className: string }

function getSufix(str) {
  return str.slice(str.lastIndexOf(".") + 1, str.length)
}

export const FilePlaceholder = ({ url, name, className }: DataType) => {
  const sufix = name ? getSufix(name) : !url ? "" : getSufix(url)

  if (sufix === "png" || sufix === "jpeg" || sufix === "jpg")
    return className !== "small" ? (
      <Image src={url} size={className} centered style={{ borderRadius: 5 }} />
    ) : (
      <img
        src={url}
        alt="img"
        className={`${styles.image} ${styles[className]}`}
      />
    )

  return (
    <div className={styles.wrapper}>
      <div className={styles[className]}>{sufix}</div>
    </div>
  )
}

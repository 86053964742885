import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import "semantic-ui-css/semantic.min.css"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

import "./index.css"
import App from "./App"
import registerServiceWorker from "./registerServiceWorker"
import store from "wrappers/Store/store"

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
)
registerServiceWorker()

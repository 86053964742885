// @flow
import * as React from "react"
import styles from "./styles.module.css"

type PropTypes = {
  className: string,
  leftPanelComponent: any,
  rightPanelComponent: any,
}

const FollowingBar = ({
  className,
  leftPanelComponent,
  rightPanelComponent,
}: PropTypes) => (
  <div className={`${className} || ${styles.masterContainer}`}>
    <div className={styles.leftPanel}>{leftPanelComponent}</div>
    <div className={styles.rightPanel}>{rightPanelComponent}</div>
  </div>
)

export default FollowingBar

// @flow
import React from "react"
import { Label } from "semantic-ui-react"

import styles from "./styles.module.css"

const TableLabel = ({ children }: { children: string }) => (
  <Label className={`${styles.label}`}>{children}</Label>
)

export default TableLabel

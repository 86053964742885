// @flow
import React from "react"
import { DateRangePicker } from "react-dates"
import chevronLeft from "assets/Icons/chevron-left.svg"
import chevronRight from "assets/Icons/chevron-right.svg"

import "./styles.css"

type PropTypes = {
  dateFrom: string,
  dateTo: string,
  onChangeDate: Function,
}

type StateTypes = {
  focusedInput: ?string,
}

export default class DateRangerPicker extends React.Component<
  PropTypes,
  StateTypes
> {
  state = {
    focusedInput: null,
  }

  render() {
    const { focusedInput } = this.state
    const { dateFrom, dateTo, onChangeDate, onFocusChange } = this.props
    return (
      <DateRangePicker
        startDate={dateFrom} // momentPropTypes.momentObj or null,
        startDateId="start-range-picker" // PropTypes.string.isRequired,
        endDate={dateTo} // momentPropTypes.momentObj or null,
        endDateId="end-range-picker" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) =>
          onChangeDate(startDate, endDate, focusedInput)
        } // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => {
          if (onFocusChange) onFocusChange(focusedInput)
          this.setState({ focusedInput })
        }} // PropTypes.func.isRequired
        isOutsideRange={() => false}
        minimumNights={0}
        firstDayOfWeek={1}
        hideKeyboardShortcutsPanel
        showDefaultInputIcon
        inputIconPosition="after"
        numberOfMonths={1}
        displayFormat="MMM D"
        customArrowIcon="-"
        verticalSpacing={30}
        startDatePlaceholderText="From"
        endDatePlaceholderText="Until"
        navPrev={
          <i className="DateRangePicker_prev">
            <img src={chevronLeft} alt="<" />
          </i>
        }
        navNext={
          <i className="DateRangePicker_next">
            <img src={chevronRight} alt=">" />
          </i>
        }
        small
        readOnly
      />
    )
  }
}

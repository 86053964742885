// @flow
import React from "react"
import { Grid } from "semantic-ui-react"
import { compose } from "redux"
import { connect } from "react-redux"
import { reduxForm } from "redux-form"

import Button from "components/Button"
import FormHeader from "./FormHeader"

// import { formFields } from "./FormFields"

type PropTypes = {
  name: string,
  data: ?Object,
  handleSubmit: Function,
  onSubmit: Function,
  onCancel: Function,
  header: string,
  submitButtonText: string,
  pristine: ?boolean,
  submitting: ?boolean,
  valid: ?boolean,
  columns: ?string,
}

const Form = (props: PropTypes) => {
  const {
    onSubmit,
    onCancel,
    header,
    submitButtonText,
    handleSubmit,
    pristine,
    submitting,
    valid,
    children,
    columns,
  } = props

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { ...props })
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {header && <FormHeader className="modal-header" header={header} />}
      <Grid padded stackable columns={columns || "equal"}>
        {childrenWithProps}
        <Grid padded textAlign="left">
          <Grid.Row>
            {submitButtonText && (
              <Button
                style={{ marginRight: "1em" }}
                type="submit"
                disabled={pristine || submitting || !valid}
              >
                {submitButtonText}
              </Button>
            )}
            {onCancel && (
              <span onClick={() => onCancel()} className="link">
                Cancel
              </span>
            )}
          </Grid.Row>
        </Grid>
      </Grid>
    </form>
  )
}

const mstp = (state, { name }) => {
  return {
    form: name,
    enableReinitialize: true,
  }
}

export default compose(
  connect(mstp),
  reduxForm()
)(Form)

// @flow
import qs from "qs"
import {
  instance as axios,
  authifyInstance,
  unauthifyInstance,
} from "instances/Axios"
import { store } from "wrappers/Store"
import { SET as SET_USER, UNSET as UNSET_USER } from "instances/User/constants"
import { _oldPushNotification } from "wrappers/Notifications/actions"
import { RESET } from "utils/commonReducerUtils"

const loginUrl = "/token"
const refreshUrl = "/token"
const userProfileUrl = "/users/me"
const clientCredentials = process.env.REACT_APP_CLIENT_CREDENTIALS // eslint-disable-line no-undef

type CredentailsType = {
  email: string,
  password: string,
}

class AuthService {
  setToken(which: string, tokenValue: string) {
    window.localStorage.setItem(which, tokenValue)
  }

  getToken(which: string) {
    return window.localStorage.getItem(which)
  }

  async obtainTokens({ email, password }: CredentailsType) {
    try {
      const response = await axios({
        method: "post",
        url: loginUrl,
        headers: {
          Authorization: `Basic ${window.btoa(clientCredentials)}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          email,
          password,
          grant_type: "password",
        }),
      })
      if (!response || !response.data || !response.data.data) {
        return store.dispatch(
          store.dispatch(
            _oldPushNotification({ content: "Obtaining tokens failed" })
          )
        )
      }
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
      } = response.data.data
      this.setToken("access_token", accessToken)
      this.setToken("refresh_token", refreshToken)
      authifyInstance(accessToken)
      return { accessToken, refreshToken }
    } catch (e) {
      if (e.response && e.response.status >= 400) {
        store.dispatch(
          _oldPushNotification({ content: "Obtaining tokens failed" })
        )
      }
      this.logout()
      throw e
    }
  }

  async refreshTokens() {
    const oldRefreshToken = this.getToken("refresh_token")
    try {
      const {
        data: { refresh_token: refreshToken, access_token: accessToken },
      } = await axios({
        method: "post",
        url: refreshUrl,
        data: qs.stringify({
          refresh_token: oldRefreshToken,
          grant_type: "refresh_token",
        }),
        headers: {
          Authorization: `Basic ${window.btoa(clientCredentials)}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      this.setToken("access_token", accessToken)
      this.setToken("refresh_token", refreshToken)
      authifyInstance(accessToken)
      return { refreshToken, accessToken }
    } catch (e) {
      this.logout()
      throw e
    }
  }

  async fetchUser() {
    try {
      const response = await axios({
        url: userProfileUrl,
        method: "get",
      })
      if (!response) {
        return console.log(
          "fetchUser - response is undefined?",
          response,
          userProfileUrl
        )
      }
      const { _id: id, email, role } = response.data.data
      store.dispatch({
        type: SET_USER,
        payload: { ...response.data.data, id, email, role },
      })
    } catch (e) {
      if (e.response)
        return console.log(
          "authService.fetchUser threw",
          e.response.statu,
          e.response.datax
        )
      throw e
    }
  }

  async login({ email, password }: CredentailsType) {
    try {
      const { accessToken } = await this.obtainTokens({ email, password })
      authifyInstance(accessToken)
      const user = await this.fetchUser()
      return user
    } catch (e) {
      throw e
    }
  }

  async logout() {
    try {
      // should make API request for token revocation
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      store.dispatch({
        type: UNSET_USER,
      })
      store.dispatch({
        type: RESET,
      })
      unauthifyInstance()
      return true
    } catch (e) {
      throw e
    }
  }
}

export default new AuthService() // authService

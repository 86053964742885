// @flow
import * as React from 'react'

const withLayout = ({ Header, }: { Header: React.ComponentType<any> }) => (
  Component: React.ComponentType<any>
) =>
  class Layout extends React.Component<{}> {
    render() {
      return (
        <div>
          <Header {...this.props} />
          <Component {...this.props} />
        </div>
      )
    }
  }

export default withLayout

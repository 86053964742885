import React from "react"
import { Dropdown } from "semantic-ui-react"
import { debounce } from "lodash"
import { transactionStatusOptions } from "./Options"
import "./styles.module.css"

export default class TransactionStatus extends React.Component {
  state = {
    selected: this.props.transactionStatus || "no_payment",
    fetching: false,
  }

  confirmBooking = debounce(async (oldStatus, newStatus, id) => {
    const confirmBooking = await this.props.updateBooking({
      _id: id,
      transaction_status: newStatus,
    })
    if (confirmBooking && confirmBooking.type.includes("FAILURE")) {
      this.setState({ selected: oldStatus })
    }
    this.setState({ fetching: false })
  })

  onChange = (e, { value }) => {
    this.setState(state => {
      const { selected, fetching } = state
      if (!fetching && selected !== value) {
        this.confirmBooking(selected, value, this.props.id)
        return { selected: value, fetching: true }
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          value={this.state.selected}
          options={transactionStatusOptions(this.props.id)}
          onChange={this.onChange}
        />
      </React.Fragment>
    )
  }
}

// @flow
import React from "react"
import { compose } from "redux"

import Router from "wrappers/Router"
import withSession from "wrappers/Session"
import Nofitications from "wrappers/Notifications"
import ModalManager from "containers/ModalManager"

const App = () => (
  <React.Fragment>
    <Nofitications />
    <ModalManager />
    <Router />
  </React.Fragment>
)

export default compose(withSession)(App)

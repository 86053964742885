import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { fetchTables } from "data/Tables/actions"
import { openModal } from "data/Modals/actions"
import { updateFilters } from "data/Filters/actions"

import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import AccessControl from "components/AccessControl"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import ClubFilter from "components/ClubFilter"
import { TABLE_MODAL } from "Constants"

import { Grid } from "semantic-ui-react"

class Filters extends React.Component {
  componentDidMount() {
    const filters = {
      club: "",
    }
    this.props.updateFilters(filters)
    this.applyFilters(filters)
  }

  componentWillUnmount() {
    this.props.updateFilters()
  }

  applyFilters = currentFilters => {
    const filters = currentFilters || this.props.filters
    this.props.fetchTables({
      params: filters,
    })
  }

  render() {
    return (
      <React.Fragment>
        <TopPanel title="Tables">
          <Grid columns={5} stackable>
            <AccessControl
              renderSuperAdmin={
                <FilterContainer>
                  <FilterItem>
                    <ClubFilter applyFilters={this.applyFilters} />
                  </FilterItem>
                  <FilterItem>
                    <ModalTriggerButton
                      onClick={() => this.props.openModal(TABLE_MODAL)}
                      content="Create table"
                    />
                  </FilterItem>
                </FilterContainer>
              }
              renderAdmin={
                <FilterContainer>
                  <FilterContainer>
                    <ModalTriggerButton
                      onClick={() => this.props.openModal(TABLE_MODAL)}
                      content="Create table"
                    />
                  </FilterContainer>
                </FilterContainer>
              }
            />
          </Grid>
        </TopPanel>
      </React.Fragment>
    )
  }
}

const mdtp = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  updateFilters: bindActionCreators(updateFilters, dispatch),
  fetchTables: bindActionCreators(fetchTables, dispatch),
})

export default connect(
  ({ filters }) => ({ filters }),
  mdtp
)(Filters)

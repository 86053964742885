import { UPDATE } from "./constants"

const DealsFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE:
      return (() => {
        return action.payload || state
      })()
    default:
      return state
  }
}

export default DealsFiltersReducer

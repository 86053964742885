// @flow
import React from "react"
import type { ComponentType } from "react"
import styles from "./styles.module.css"

type PropTypes = {
  children: ComponentType,
  className: string,
}

export const FilterItem = ({ children, className }: PropTypes) => (
  <div className={`${styles.filterItem} ${styles[className] || ""}`}>
    {children}
  </div>
)

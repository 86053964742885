export default [
  { name: "nav-overview", text: "Overview", uri: "/overview" },
  { name: "nav-tables", text: "Tables", uri: "/tables" },
  { name: "nav-clubs", text: "Venues", uri: "/clubs" },
  { name: "nav-events", text: "Events", uri: "/events" },
  { name: "nav-events", text: "Booked Deals", uri: "/booked-deals" },
  { name: "nav-deals", text: "Deals", uri: "/deals" },
  { name: "nav-users", text: "Users", uri: "/users" },
  { name: "nav-customers", text: "Customers", uri: "/customers" },
  { name: "nav-locations", text: "Locations", uri: "/locations" },
  { name: "nav-promotions", text: "Promotions", uri: "/promotions" },
  { name: "nav-settings", text: "Settings", uri: "/settings" },
]

// @flow
import React from "react"
import moment from "moment"
import { Popup } from "semantic-ui-react"

import TableOptions from "components/TableOptions"
import ColumnImage from "components/ColumnImage"
import TableRowLabel from "components/TableRowLabel"
import HoverableDescription from "components/HoverableDescription"

import placeholder from "assets/Images/placeholder.jpg"

export default function segmentToRow(
  {
    _id,
    club_id,
    name,
    date,
    type,
    images,
    description,
    spotlight,
    specific_prices,
    music_type,
    dress_code,
    override_prices = [],
  },
  {
    clubsLoaded,
    clubs,
    setActiveUpdatingForm,
    openModal,
    deleteEvent,
    tables,
    selectClub,
    selectEvent,
  }
) {
  const image = (images.length && images[0].path) || placeholder

  return {
    columns: [
      {
        component: (
          <span>
            <ColumnImage src={image} alt="" />
          </span>
        ),
      },
      { component: <HoverableDescription>{name}</HoverableDescription> },
      { component: <span>{moment(date).format("D MMMM YYYY")}</span> },
      { component: <span>{type}</span> },
      { component: <HoverableDescription>{description}</HoverableDescription> },
      {
        component: override_prices.length ? (
          <Popup trigger={<span>Yes</span>}>
            {(() => {
              const buffer = []
              override_prices.forEach(e => {
                if (tables[e.table_id] && e.price)
                  buffer.push(`${tables[e.table_id].name}: $${e.price}`)
              })
              return buffer.map((segment, i) => <p key={i}>{segment}</p>)
            })()}
          </Popup>
        ) : (
          "-"
        ),
      },
      {
        component: (
          <TableOptions
            onEditOptionClick={() => {
              selectClub(club_id)
              selectEvent(_id)
              setActiveUpdatingForm(_id)
              openModal()
            }}
            onDeleteOptionClick={() => {
              deleteEvent(_id)
            }}
            editOptionText="Edit event"
            deleteOptionText="Delete event"
          />
        ),
      },
    ],
    label: spotlight
      ? {
          component: <TableRowLabel icon="star" />,
        }
      : undefined,
  }
}

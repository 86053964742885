import { BOOKED_DEALS } from "./constants"
import { commonReducerState, commonReducerActionHandlers } from "utils/commonReducerUtils"

const BookedDealsReducer = (
  state = {
    ...commonReducerState,
  },
  { type, payload }
) =>
  commonReducerActionHandlers(
    {
      state,
      type,
      payload,
    },
    BOOKED_DEALS,
    () => {
      return state
    }
  )

export default BookedDealsReducer

import React from "react"
import { connect } from "react-redux"
import { closeModalAndUnsetActiveUpdatingForm } from "data/Modals/actions"
import {
  createBookingAndReload,
  updateBookingAndReload,
} from "data/Overview/actions"

import Modal from "components/Modal"
import ReservationForm from "views/Overview/ReservationForm"
import { RESERVATION_FORM, RESERVATION_MODAL } from "Constants"
import Form from "components/Form"
import dropdownize from "utils/dropdownize"

class ReservationModal extends React.Component {
  closeModal = () => {
    const { closeModalAndUnsetActiveUpdatingForm, activeModal } = this.props

    closeModalAndUnsetActiveUpdatingForm(RESERVATION_MODAL, activeModal)
  }

  createReservation = reservation => {
    const { createBookingAndReload, activeModal, filters } = this.props

    createBookingAndReload(
      {
        params: reservation,
        modalId: RESERVATION_MODAL,
        formId: activeModal,
      },
      filters
    )
  }

  updateReservation = reservation => {
    const { updateBookingAndReload, activeModal, filters } = this.props

    updateBookingAndReload(
      {
        params: reservation,
        modalId: RESERVATION_MODAL,
        formId: activeModal,
      },
      filters
    )
  }

  render() {
    const { initialValues, clubs, tables } = this.props
    const { _id } = initialValues

    return (
      <Modal isOpen onClose={this.closeModal}>
        <Form
          name={RESERVATION_FORM}
          header={_id ? "Update reservation" : "Make a new reservation"}
          submitButtonText={_id ? "Update reservation" : "Create reservation"}
          initialValues={initialValues}
          onCancel={this.closeModal}
          onSubmit={_id ? this.updateReservation : this.createReservation}
          columns={3}
        >
          <ReservationForm
            data={{
              clubsOptions: dropdownize(clubs.segments),
              tablesOptions: dropdownize(tables.segments, "club_id"),
              tables: tables.segments,
            }}
          />
        </Form>
      </Modal>
    )
  }
}

const mstp = state => {
  const { overview, tables, clubs, forms, filters } = state
  const { activeUpdatingForm } = forms
  let initialValues = {}

  Object.values(overview.segments).forEach(club => {
    club.dates.forEach(date => {
      const tables = date.tables.filter(table => table.booking)
      const table = tables.find(
        table => table.booking && table.booking._id === activeUpdatingForm
      )
      if (table) {
        const { booking, _id, name, ...rest } = table
        initialValues = {
          ...rest,
          ...booking,
          table_id: _id,
          table_name: name,
          date: date.date,
        }
        return
      }
    })
  })

  return {
    clubs,
    tables,
    activeModal: activeUpdatingForm,
    initialValues,
    filters,
  }
}

const actions = {
  closeModalAndUnsetActiveUpdatingForm,
  createBookingAndReload,
  updateBookingAndReload,
}

export default connect(
  mstp,
  actions
)(ReservationModal)

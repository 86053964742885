// @flow

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  updateUser,
  changePassword as changeUserPassword,
} from "data/Users/actions"
import { Divider, Segment } from "semantic-ui-react"

import TopPanel from "components/TopPanel"
import PasswordChange from "components/PasswordChange"
import FooterInfo from "components/FooterInfo"

import styles from "./styles.module.css"
import EmailChange from "components/EmailChange"
import { EMAIL_CHANGE_FORM, PASSWORD_CHANGE_FORM } from "Constants"

class Settings extends React.Component {
  changeEmail = values => {
    const { email } = values
    this.props.updateUser({ params: { email }, form: EMAIL_CHANGE_FORM })
  }

  changePassword = values => {
    const { oldPassword, password } = values
    this.props.changePassword(
      {
        old_password: oldPassword,
        new_password: password,
      },
      PASSWORD_CHANGE_FORM
    )
  }

  render() {
    return (
      <div className={styles.masterContainer}>
        <TopPanel title="Settings" />
        <Segment padded>
          <EmailChange onSubmit={this.changeEmail} />
          <Divider hidden />
          <PasswordChange changePassword={this.changePassword} />
        </Segment>
        <Divider hidden />
        <FooterInfo />
      </div>
    )
  }
}

const mstp = ({ user }) => ({ user })
const mdtp = dispatch => ({
  updateUser: bindActionCreators(updateUser, dispatch),
  changePassword: bindActionCreators(changeUserPassword, dispatch),
})

export default connect(
  mstp,
  mdtp
)(Settings)

import React, { useEffect } from "react"
import moment from "moment"
import { connect, useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"

import { openModal } from "data/Modals/actions"
import { updateDealsFilters } from "data/DealsFilters/actions"
import { fetchDealsBookings } from "data/BookedDeals/actions"

import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import AccessControl from "components/AccessControl"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import ClubFilter from "components/ClubFilter"
import TransactionStatusFilter from "components/TransactionStatusFilter"
import CategoryFilter from "components/CategoryFilter"
import { DEAL_MODAL } from "Constants"

import { Grid } from "semantic-ui-react"
import styles from "./styles.module.css"

const Filters = () => {
  const dispatch = useDispatch()

  const filters = useSelector((state) => state.dealsFilters)

  useEffect(() => {
    // updateDealsBookingFilters(filters)
    dispatch(
      fetchDealsBookings({
        params: filters,
      })
    )
  }, [])

  const createDeal = (modal) => {
    dispatch(openModal(DEAL_MODAL))
  }

  const applyFilters = (currentFilters) => {
    const filters = currentFilters || filters
    dispatch(
      fetchDealsBookings({
        params: filters,
      })
    )
  }

  return (
    <React.Fragment>
      <TopPanel title="Deals">
        <Grid columns={5} stackable>
          <AccessControl
            renderSuperAdmin={
              <FilterContainer>
                <FilterItem>
                  <ClubFilter applyFilters={applyFilters} />
                </FilterItem>
                <FilterItem>
                  <TransactionStatusFilter applyFilters={applyFilters} />
                </FilterItem>
              </FilterContainer>
            }
            renderAdmin={
              <FilterContainer>
                <FilterItem>
                  <TransactionStatusFilter applyFilters={applyFilters} />
                </FilterItem>
              </FilterContainer>
            }
          />
        </Grid>
      </TopPanel>
    </React.Fragment>
  )
}

export default Filters

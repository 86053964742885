import React from "react"

export const headers = [
  { component: <span>Name</span> },
  { component: <span>Birth</span> },
  { component: <span>Phone</span> },
  { component: <span>Email</span> },
  { component: <span>Since</span> },
  { component: <span>Last booked</span> },
  { component: <span /> },
]

// @flow
import React from "react"
import ErrorText from "components/ErrorText"

export const required = (value: string) => (value ? undefined : <ErrorText content={["This Field is required"]} />)

export const minLength = (n: number) => (value: string) =>
  value && value.length <= n && <ErrorText content={[`Minimum length is ${n}`]} />

export const minLength2 = minLength(2)

export const minLength8 = minLength(8)

export const maxLength = (n: number) => (value: string) =>
  value && value.length > n && <ErrorText content={[`Maximum length is ${n}`]} />

export const maxLength256 = maxLength(256)

export const matchField = (field: string) => (value: string, allValues) =>
  value !== allValues[field] && <ErrorText content={["Passwords must match"]} />

export const matchPasswordField = matchField("password")

export function containsValue(input: string): boolean {
  return Boolean(input)
}
export function onlyLettersAndSpaces(input: string): boolean {
  return Boolean(input.match(/^[A-Za-z][a-zA-Z\s]*$/))
}
export const onlyNumericAndSpaces = (value: string) => {
  if (value) {
    return `${value}`.match(/^[0-9\s]*$/) ? null : <ErrorText content={["Only numbers are allowed"]} />
  } else {
    return null
  }
}

export function minChars(input: string, n: number): boolean {
  return input.length >= n
}
export function maxChars(input: string, n: number): boolean {
  return input.length <= n
}
export function isNotOnlyWhitespace(input: string): boolean {
  return Boolean(input.replace(/\s/g, "").length)
}
export const isEmail = (value: string) =>
  /[^\s@]+@[^\s@]+\.[^\s@]+/.test(value) ? null : <ErrorText content={["Email format is invalid"]} />

export function isName(input: string): boolean {
  return Boolean(input) && input.length >= 1 && Boolean(input.match(/^[A-Za-z][a-zA-Z\s]*$/))
}

export const isPhoneNumber = (value: string) =>
  !value || (value && `${value}`.match(/^[+?0-9\s]*$/)) ? null : (
    <ErrorText content={["Please enter valid phone number"]} />
  )

export const noWhitespace = (value: string) =>
  !value || (value && `${value}`.replace(/\s/g, "").length === value.length) ? null : (
    <ErrorText content={["White space it not allowed"]} />
  )

export function mustMatch(input: string, subject: string): boolean {
  return input === subject
}

export const onlyNumericAndLetters = (value: string) =>
  value && value.match(/^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/) ? null : (
    <ErrorText content={["Field must contain both numbers and letters"]} />
  )

export const includesNumericAndLetters = (value: string) => {
  let containsNumeric = false
  let containsLetters = false
  value &&
    value.split().forEach((char) => {
      if (char.match(/[a-zA-Z]/)) containsLetters = true
      if (char.match(/[0-9]/)) containsNumeric = true
    })
  return containsLetters && containsNumeric ? null : (
    <ErrorText content={["Field must contain both numbers and letters"]} />
  )
}

/* Image Upload Validation */

export const maxImages = (n: number) => (value: Array) => {
  if (!value) return
  value.filter((item) => item._id || item.name)
  return value.length > n ? <ErrorText content={[`Maximum number of images is ${n}`]} /> : null
}

export const maxImages10 = maxImages(10)

export const maxSize1MB = (value: Array) =>
  value && value.find((item) => item.size > Math.pow(10, 6)) ? (
    <ErrorText content={["Maximum size of images must be 1MB"]} />
  ) : null

// import objectifyArray from "utils/objectifyArray";

export const FAKE_CALL_API = "Fake Call API"

function validateCallAPI(types, promise) {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.")
  }

  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.")
  }

  return true
}
/*
  CALL_API: {
    types: (required) [REQUEST_TYPE, SUCCESS_TYPE, ERROR_TYPE]
    promise: (required) function returning a promise
    schema: (optional) response data will be mapped to scheme
    notifyOnError: (optional) if false, error wont be forwarded to notifyCenter
    successMessage: (optional)  if set, message will be forwarded to notifyCenter
    unauthorizedMessage: (optional) if set, message will be forwarded to notifyCenter
  }
  ... all other fields will be forwarded to reducer
 */
export default store => next => async action => {
  const callAPI = action[FAKE_CALL_API]

  if (typeof callAPI === "undefined") {
    return next(action)
  }

  const {
    types,
    // promise,
    // notifyOnError,
    // successMessage,
    // unauthorizedMessage
  } = callAPI

  validateCallAPI(types)

  const [requestType, successType] = types // failureType
  next({ type: requestType, ...action })

  // const response = await delay(2000)

  return next({
    type: successType,
    payload: [],
    ...action,
  })
}

// function delay(timeout = 2000) {
//   return new Promise((resolve, reject) => {
//     setTimeout(resolve, timeout)
//   })
// }

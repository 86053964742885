import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Dropdown } from "semantic-ui-react"

import { updateDealsFilters } from "data/DealsFilters/actions"
import dropdownizeArray from "utils/dropdownizeArray"

class CategoryFilter extends React.Component {
  onChange = (e, { name, value }) => {
    const { filters, updateFilters, applyFilters } = this.props
    if (filters[name] === value || (!filters[name] && value === "all")) return
    filters[name] = value === "all" ? "" : value
    updateDealsFilters(filters)
    applyFilters(filters)
  }

  render() {
    const { categories, filters, category } = this.props
    return <Dropdown name="category" value={category || "all"} options={categories} onChange={this.onChange} />
  }
}

const mstp = ({ categories, dealsFilters = {} }) => ({
  categories: [
    {
      key: "all_categories",
      value: "all",
      text: "All Categories",
    },
    ...dropdownizeArray(categories.segments),
  ],
  filters: dealsFilters,
  category: dealsFilters.category,
})

const mdtp = dispatch => ({
  updateDealsFilters: bindActionCreators(updateDealsFilters, dispatch),
})

export default connect(mstp, mdtp)(CategoryFilter)

// @flow
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { Grid } from "semantic-ui-react"

import TableSection from "components/TableSection/"
import TopPanel from "components/TopPanel"
import ModalTriggerButton from "components/ModalTriggerButton"
import FilterContainer from "components/FilterContainer"
import FilterItem from "components/FilterItem"
import Loading from "components/Loading"
import rowsCreator from "utils/rowsCreator"
import { openModal } from "data/Modals/actions"
import { fetchLocations, updateLocation, deleteLocationAndReload } from "data/Locations/actions"
import { setActiveUpdatingForm } from "data/Forms/actions"
import { headers } from "./LocationTableHeaders"
import segmentToRow from "./SegmentToRow"

import styles from "./styles.module.css"
import { LOCATION_MODAL } from "Constants"

type PropTypes = {
  fetchLocations: Function,
  updateLocation: Function,
  deleteLocationAndReload: Function,
  closeModal: Function,
  openModal: Function,
  locations: {},
  modals: {
    CREATE_LOCATION: string,
    UPDATE_LOCATION: string,
  },
}
type StateTypes = {
  isModalOpen: boolean,
  activeModal: ?string,
}

class Locations extends React.Component<PropTypes, StateTypes> {
  componentDidMount() {
    this.props.fetchLocations()
  }

  render() {
    return (
      <div className={styles.masterContainer}>
        <TopPanel title="Locations">
          <Grid columns={5} stackable>
            <FilterContainer>
              <FilterItem>
                <ModalTriggerButton
                  onClick={() => this.props.openModal(LOCATION_MODAL)}
                  content="Create location"
                />
              </FilterItem>
            </FilterContainer>
          </Grid>
        </TopPanel>
        <Loading isLoading={this.props.locations.readLoading}>
          <TableSection
            headers={headers}
            rows={rowsCreator(segmentToRow, this.props.locations.segments, {
              openModal: () => this.props.openModal(LOCATION_MODAL),
              setActiveUpdatingForm: (id) => this.props.setActiveUpdatingForm(id),
              deleteLocation: (id) => this.props.deleteLocationAndReload(id),
            })}
          />
        </Loading>
      </div>
    )
  }
}

const mstp = ({ locations, modals, forms }) => ({
  locations,
  modals,
  forms,
})

const mdtp = (dispatch) => ({
  fetchLocations: bindActionCreators(fetchLocations, dispatch),
  updateLocation: bindActionCreators(updateLocation, dispatch),
  deleteLocationAndReload: bindActionCreators(deleteLocationAndReload, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  setActiveUpdatingForm: bindActionCreators(setActiveUpdatingForm, dispatch),
})

export default connect(mstp, mdtp)(Locations)

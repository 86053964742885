// @flow
import React from "react"
import { SingleDatePicker } from "react-dates"
import moment from "moment"
import chevronLeft from "assets/Icons/chevron-left.svg"
import chevronRight from "assets/Icons/chevron-right.svg"

import "./styles.css"

class DateField extends React.Component {
  state = {
    focused: null,
  }

  render() {
    const { label, value, onChange } = this.props
    return (
      <React.Fragment>
        <label>{label}</label>
        <SingleDatePicker
          date={!value ? null : moment(value)}
          onDateChange={(date) => {
            onChange(date ? moment(date).format("YYYY-MM-DD") : null)
          }}
          readOnly
          focused={this.state.focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          id="your_unique_id"
          firstDayOfWeek={1}
          showClearDate
          hideKeyboardShortcutsPanel
          showDefaultInputIcon
          inputIconPosition="after"
          numberOfMonths={1}
          navPrev={
            <i className="DateRangePicker_prev">
              <img src={chevronLeft} alt="<" />
            </i>
          }
          navNext={
            <i className="DateRangePicker_next">
              <img src={chevronRight} alt=">" />
            </i>
          }
        />
      </React.Fragment>
    )
  }
}

export default DateField

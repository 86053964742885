// @flow
import React from "react"

import { Field, reduxForm } from "redux-form"
import { InputField } from "react-semantic-redux-form"
import Button from "components/Button"
import {
  isEmail,
  minLength8,
  maxLength256,
  includesNumericAndLetters,
  matchPasswordField,
} from "wrappers/FormValidation/validations"
import { minLength2 } from "wrappers/FormValidation/validations"
import { AUTHORIZATION_FORM } from "Constants"
import styles from "./styles.module.css"

type FieldType = {
  name?: string,
  label: string,
  type: string,
  placeholder?: string,
  readOnly?: boolean,
  value?: string | number,
}

type PropTypes = {
  title: string,
  subtitle: string,
  handleSubmit: Function,
  onSubmit: Function,
  submitButtonText: string,
  underneathComponent?: any,
  fields: Array<FieldType>,
  schema: any,
  onChange: Function,
  showName: ?boolean,
  confirmPassword: ?boolean,
  disableEmail: ?boolean,
  pristine: ?boolean,
  valid: ?boolean,
  submitting: ?boolean,
}

class AuthorizationForm extends React.Component<PropTypes> {
  render() {
    const {
      title,
      subtitle,
      handleSubmit,
      onSubmit,
      submitButtonText,
      underneathComponent,
      showName,
      confirmPassword,
      disableEmail,
      initialValues,
      pristine,
      valid,
      submitting,
      password,
    } = this.props
    return (
      <div className={styles.masterContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.headingContainer}>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
          <div className={styles.formContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {showName && (
                <div className={styles.formField}>
                  <label>Full name</label>
                  <Field
                    name="name"
                    component={InputField}
                    type="text"
                    placeholder="Enter name"
                    validate={[minLength2]}
                    fluid
                  />
                </div>
              )}
              <div className={styles.formField}>
                <label>Email</label>
                <Field
                  name="email"
                  component={InputField}
                  type="email"
                  placeholder={
                    initialValues ? initialValues.email : "yourname@example.com"
                  }
                  validate={disableEmail ? null : isEmail}
                  readOnly={disableEmail}
                  disabled={disableEmail}
                  fluid
                />
              </div>
              {password && (
                <div className={styles.formField}>
                  <label>Password</label>
                  <Field
                    name="password"
                    component={InputField}
                    type="password"
                    placeholder="Enter password"
                    validate={[
                      minLength8,
                      maxLength256,
                      includesNumericAndLetters,
                    ]}
                    fluid
                  />
                </div>
              )}
              {confirmPassword && (
                <div className={styles.formField}>
                  <label>Confirm password</label>
                  <Field
                    name="passwordConfirm"
                    component={InputField}
                    type="password"
                    placeholder="Confirm password"
                    validate={[minLength8, matchPasswordField]}
                    fluid
                  />
                </div>
              )}
              <Button
                disabled={pristine || submitting || !valid}
                fluid
                name="login-button"
              >
                {submitButtonText}
              </Button>
            </form>
          </div>
          {underneathComponent}
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: AUTHORIZATION_FORM,
})(AuthorizationForm)
